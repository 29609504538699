import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import { Box } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FireTruckIcon from "@mui/icons-material/FireTruck";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "examples/Footer";
import DashboardCalendar from "layouts/dashboard/components/DashboardCalendar";
import DashboardWeatherCard from "layouts/dashboard/components/DashboardWeatherCard";
import { getOrders } from "api/apiService";
import moment from "moment-timezone";
import "moment/locale/nb"; // Import Norwegian locale
moment.locale("nb");

// Status mapping
const statusMapping = {
  "Venter på betaling": "Klar for fakturering",
  "Behandler": "Betalt",
  "Avventer leveranse": "Klar for levering",
};

// Get status icon and background color dynamically
const getStatusIcon = (status) => {
  switch (status) {
    case "Betalt":
      return {
        icon: <MonetizationOnIcon sx={{ color: "#2E7D32", fontSize: { xs: 22, sm: 26, md: 28 } }} />,
        bgColor: "#E8F5E9",
      };
    case "Klar for levering":
      return {
        icon: <FireTruckIcon sx={{ color: "#F57C00", fontSize: { xs: 22, sm: 26, md: 28 } }} />,
        bgColor: "#FFF3E0",
      };
    case "Klar for fakturering":
      return {
        icon: <ReceiptIcon sx={{ color: "#0288D1", fontSize: { xs: 22, sm: 26, md: 28 } }} />,
        bgColor: "#E3F2FD",
      };
    default:
      return { icon: null, bgColor: "transparent" };
  }
};

const Dashboard = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  console.log("orders:", orders);

  const getAllOrders = async () => {
    setIsLoading(true);
    try {
      const data = await getOrders({ limit: 79, page_number: 1 });
      console.log("Fetched All Orders:", data);

      if (data?.data && Array.isArray(data.data)) {
        const filteredOrders = data.data
          .filter((order) => statusMapping[order.status])
          .map((order) => ({
            id: order.order_id,
            status: statusMapping[order.status],
            date: moment(order.date, "DD.MM.YYYY").format("YYYY-MM-DD"),
          }))
          .sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf())
          .slice(0, 5); // Show latest 5

        setOrders(filteredOrders);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllOrders();
    const interval = setInterval(() => {
      console.log(`🔄 Refreshing orders... [Time: ${new Date().toLocaleTimeString()}]`);
      getAllOrders();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <DashboardLayout>
      <SoftBox>
        <Grid container spacing={2}>
          {/* Orders List */}
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: { xs: "370px", sm: "340px", md: "370px", xxl:"460px" },
                background: "#fff",
                padding: "15px 20px",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
              }}
            >
              <SoftBox display="flex" alignItems="center" mb={2}>
                <SoftTypography
                  variant="h3"
                  fontWeight="bold"
                  color="info"
                  sx={{ fontSize: { xs: "18px", sm: "22px", md: "26px", xxl:"28px" } }}
                >
                  Siste Ordre
                </SoftTypography>
              </SoftBox>

              <SoftBox>
                {isLoading && orders.length === 0 ? (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(#fff, 0, 0, 0.3)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 1000,
                    }}
                  >
                    <CircularProgress size={40} color="info" />
                  </Box>
                ) : orders.length === 0 ? (
                  <SoftTypography
                    variant="body2"
                    textAlign="center"
                    color="textSecondary"
                    sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", xxl:"16px" } }}
                  >
                    Ingen bestillinger funnet
                  </SoftTypography>
                ) : (
                  orders.map((order) => {
                    const { icon, bgColor } = getStatusIcon(order.status);
                    return (
                      <SoftBox key={order.id} display="flex" alignItems="center" mt={1.9}>
                        <SoftBox
                          sx={{
                            backgroundColor: bgColor,
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: { xs: 30, sm: 35, md: 40, xxl:50 },
                            height: { xs: 30, sm: 35, md: 40, xxl:50 },
                            mr: 1.5,
                          }}
                        >
                          {icon}
                        </SoftBox>

                        <SoftBox>
                          <SoftTypography
                            variant="body2"
                            fontWeight="bold"
                            mb={-1}
                            sx={{ fontSize: { xs: "10px", sm: "10px", md: "12px", xxl:"16px" } }}
                          >
                            <SoftTypography
                              variant="body2"
                              fontWeight="bold"
                              sx={{
                                fontSize: { xs: "10px", sm: "10px", md: "12px", xxl: "16px" },
                                color: "#2c3e50",
                                transition: "color 0.2s ease-in-out",
                                "&:hover": {
                                  textDecoration: "underline !important",
                                  color: "#006bb7",
                                },
                              }}
                              target="_blank"
                              component="a"
                              href={`/projects/order-details/${order.id}`}
                            >  
                              Bestilling #{order.id}                        
                            </SoftTypography>
                            
                          </SoftTypography>
                          <SoftTypography
                            variant="caption"
                            color="textSecondary"
                            sx={{ fontSize: { xs: "12px", sm: "12px", md: "8px", xxl:"16px" } }}
                          >
                            {order.status} • {moment(order.date, "YYYY-MM-DD").format("DD.MM.YYYY")}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    );
                  })
                )}
              </SoftBox>
            </Card>
          </Grid>

          {/* Calendar */}
          <Grid item xs={12} md={8}>
            <DashboardCalendar />
          </Grid>

          {/* Time Display */}
          <Grid item xs={12} md={3}>
            <SoftBox textAlign="center" mt={-2}>
              <SoftTypography 
                variant="h2" 
                color="info" 
                sx={{ fontSize: { xs: "6rem", sm:"6rem", md: "4.5rem", xxl:"6rem" } }}
              >
                {moment().format("HH:mm")}
              </SoftTypography>
              <SoftTypography 
                variant="body2" 
                fontWeight="medium"
                mt={-1}
                sx={{ fontSize: { xs: "18px", sm:"18px", md: "16px", xxl:"18px" } }}
              >
                {capitalizeFirstLetter(moment().locale("nb").format("dddd"))} {/* Day of the week */}
                {" "}{moment().format("D.")} {/* Day with period */}
                {" "}{capitalizeFirstLetter(moment().locale("nb").format("MMMM"))} {/* Month */}
                {" "}{moment().format("YYYY")} {/* Year */}
              </SoftTypography>
            </SoftBox>
          </Grid>


          {/* Weather Card */}
          <Grid item xs={12} md={9} mb={3}>
            <SoftBox display="flex" justifyContent="flex-end">
              <DashboardWeatherCard />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Dashboard;
