// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import React from 'react';
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Card from "@mui/material/Card";
// Soft UI Dashboard React examples
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "examples/Footer";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

function DefaultLayout({ top, children, extraContent }) {
  return (
    <DashboardLayout className="background-image-container">
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{
            height: "auto",
            minHeight: "100vh !important",
           
          }}
        >
          <Grid item xs={11} sm={11} md={7} xl={7} mb={4}>
            <Card sx={{ height: "100%" }}>
              <SoftBox mt={2} >
                
                <SoftBox>{children}</SoftBox>
              </SoftBox>
            </Card>
          </Grid>

          <Grid item xs={11} md={5} xl={5} mb={4}>
            <Card sx={{ height: "100%", background: "linear-gradient(310deg, #141727, #3a416f)" }}>
              <SoftBox
                
                
                
              
                // mr={-16}
                // sx={{
                //   transform: "skewX(-10deg)",
                //   overflow: "hidden",
                //   backgroundColor: "#006bb7",
                //   borderBottomLeftRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                // }}
              >
               {extraContent}
                
              </SoftBox>
            </Card>
          </Grid>
          <Footer />
        </Grid>
      
    </DashboardLayout>
  );
}

// Setting default values for the props of DefaultLayout
DefaultLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  color: "info",
  top: 20,
  extraContent: null,
};

// Typechecking props for the DefaultLayout
DefaultLayout.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
  extraContent: PropTypes.node, // Add this line to validate extraContent
};

export default DefaultLayout;
