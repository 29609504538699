import React, { useState, useEffect } from "react";
import {
  TextField, Checkbox, FormControlLabel, Button, Typography, Box, Grid, List, ListItem, ListItemText, IconButton, Avatar,CardContent,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import SoftTypography from "components/SoftTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import DefaultLayout from "layouts/authentication/components/DefaultLayout/DefaultLayout";
import SoftButton from "components/SoftButton";
import { useGlobalState } from "globalState/globalState";
import CircularProgress from "@mui/material/CircularProgress";
import { createOrder, fetchUploadAPI, deleteFileAPI } from './weatherUtils'; // Adjust import as necessary
import WeatherConditionsCard from "./components/WeatherConditionsCard";
import { fetchVendorDetails, profile } from 'api/apiService';  // Import the API function

const Summary = () => {
  const location = useLocation();
  const {
    address,
    dateTimeFields = [],
    weatherData = [],
    showMap = false,
    showDateTime = false,
    totalArea = 0,
    personName = "",
    castWithDrop = false,
    delay = false,
    steelFiber = false,
    accelerator = false,
    uploadingTypeChecked = false,
    selectedItem = null,
    vehicleType = "40",
    selectedProjectType = "",
    showAdditionalOptions = false,  // ✅ Added to prevent undefined error
    addAmountChecked = false,
    pumpMixerWashChecked = false,
    addHoseChecked = false,
    hoseAmount = 0,
    concreteConfig = null,
    concreteConfigData = null,
    displayValue = "",
    offerOnCasting: initialOfferOnCasting = false,
    offerOnFloorSanding: initialOfferOnFloorSanding = false,
    responsibility: initialResponsibility = "selfBuilder",
    acceptedTerms: initialAcceptedTerms = false,
    uploadedFiles: initialUploadedFiles = [],
    fileIDs: initialFileIDs = [],
    selectedFile: initialSelectedFile = null,
    vendorData: initialVendorData = null,
    projectDescription: initialprojectDescription = "",
  } = location.state || {}; 

  const { state } = useGlobalState(); // Access global state
  const [offerOnCasting, setOfferOnCasting] = useState(initialOfferOnCasting);
  const [offerOnFloorSanding, setOfferOnFloorSanding] = useState(initialOfferOnFloorSanding);
  const [responsibility, setResponsibility] = useState(initialResponsibility);
  const [acceptedTerms, setAcceptedTerms] = useState(initialAcceptedTerms);
  const [uploadedFiles, setUploadedFiles] = useState(initialUploadedFiles);
  const [fileIDs, setFileIDs] = useState(initialFileIDs); // Store uploaded file IDs
  const [selectedFile, setSelectedFile] = useState(initialSelectedFile);
  const [vendorData, setVendorData] = useState(initialVendorData); // Store vendor data dynamically
  const [projectDescription, setProjectDescription] = useState(initialprojectDescription);
  const { concrete_quality, concrete_stone, concrete_reduction, concrete_consistency } = concreteConfigData || {
    concrete_quality: [],
    concrete_stone: [],
    concrete_reduction: [],
    concrete_consistency: []
  };

  console.log('totalArea:', totalArea);
  console.log('vehicleType:', vehicleType);
  console.log('selectedProjectType:', selectedProjectType);
  console.log('concreteConfig:', concreteConfig);
  console.log('concreteConfigData:', concreteConfigData);
  console.log('pumpMixerWashChecked:', pumpMixerWashChecked);
  console.log('addAmountChecked:', addAmountChecked);
  console.log('addHoseChecked:', addHoseChecked);
  console.log('hoseAmount:', hoseAmount);
  // Get the role and user info from local storage
  const role = localStorage.getItem('role');  // 'vendor', 'admin', 'customer', etc.
  const loggedIn = localStorage.getItem('loggedIn');
  // Get vendor_id from user object if role is 'vendor'
  const vendor_id = role === 'vendor' ? localStorage.getItem('vendor_id') : null;

  // Now, you can pass vendorId to your API or use it in your logic
  console.log('Vendor ID:', vendor_id);
  console.log('vendorData:', vendorData);



  const step1Data = {
    address,
    dateTimeFields,
    weatherData,
    showMap,
    showDateTime,
    totalArea,
    personName,
    castWithDrop,
    delay,
    steelFiber,
    accelerator,
    uploadingTypeChecked,
    selectedItem,
    vehicleType,
    selectedProjectType,
    showAdditionalOptions,
    addAmountChecked,
    pumpMixerWashChecked,
    addHoseChecked,
    hoseAmount,
    concreteConfig,
    concreteConfigData,
    displayValue,
    offerOnCasting,
    offerOnFloorSanding,
    responsibility,
    acceptedTerms,
    uploadedFiles,
    fileIDs,
    selectedFile,
    vendorData,
    projectDescription,
  };

  const handleBackClick = () => {

    console.log('step3Data back',step1Data);
    navigate("/calculate-area", { state: step1Data });
  };

  useEffect(() => {
    const getVendorInfo = async () => {
      
      const data = await fetchVendorDetails(vendor_id, address); 
      setVendorData(data);
      
    };

    getVendorInfo();
  }, [vendor_id, address]);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (loggedIn && JSON.parse(loggedIn) === true) {
        const user = JSON.parse(localStorage.getItem('user')); // Retrieve user details
        const userId = user?.id; // Safely access user ID
        console.log('userId:', userId);
        if (userId) {
          try {
            const profileData = await profile('profile', {}, userId);
            console.log('profileData:', profileData);
            if (profileData) {
              // const data_info = profileData?.data_info || {};
              const profileInfo = profileData?.data_info || {}; 
              console.log('Fetched profileInfo Data:', profileInfo);

              setFormData({
                firstName: profileInfo.first_name || '',
                lastName: profileInfo.last_name || '',
                email: profileInfo.email || '',
                phoneNumber: profileInfo.phoneNumber || '',
                companyName: profileInfo.companyName || '',
                organizationNumber: '',
                projectNumber: '',
              });

              console.log('Updated Form Data:', {
                firstName: profileInfo.first_name || '',
                lastName: profileInfo.last_name || '',
                email: profileInfo.email || '',
                phoneNumber: profileInfo.phoneNumber || '',
                companyName: profileInfo.companyName || '',
              });
            } else {
              console.warn('No profile data found.');
            }
          } catch (error) {
            console.error('Error fetching profile data:', error);
          }
        } else {
          console.warn('No user ID found in localStorage.');
        }
      } else {
        console.log('User is not logged in.');
      }
    };

    fetchProfileData();
  }, []); // Empty dependency array ensures this runs once on component mount



  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    companyName: "",
    surname: "",
    phoneOnCastingDay: "",
    email: "",
    organizationNumber: "",
    projectNumber: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleDescriptionChange = (event) => {
    setProjectDescription(event.target.value);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validate = () => {
    const newErrors = {};

    if (responsibility === "selfBuilder") {
      if (!formData.firstName.trim()) newErrors.firstName = "Fornavn er påkrevd";
      if (!formData.lastName.trim()) newErrors.lastName = "Etternavn er påkrevd";
      if (!formData.phoneNumber.trim()) newErrors.phoneNumber = "Telefonnummer er påkrevd";
      if (!validateEmail(formData.email)) newErrors.email = "En gyldig e-post er nødvendig";
    }

    if (responsibility === "company") {
      if (!formData.companyName.trim()) newErrors.companyName = "Firmanavn er påkrevd";
      if (!formData.firstName.trim()) newErrors.firstName = "Fornavn er påkrevd";
      if (!formData.lastName.trim()) newErrors.lastName = "Etternavn er påkrevd";
      if (!formData.phoneNumber.trim()) newErrors.phoneNumber = "Telefonnummer er påkrevd";
      if (!validateEmail(formData.email)) newErrors.email = "En gyldig e-post er nødvendig";
      if (!formData.organizationNumber.trim()) newErrors.organizationNumber = "Organisasjonsnummer er påkrevd";
      if (!formData.projectNumber.trim()) newErrors.projectNumber = "Prosjektnummer er påkrevd";
    }


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleResponsibilityChange = (type) => {
    setResponsibility(type);
    setErrors({});
  };

  const handleSubmit = async () => {
    console.log('Form Data:', formData);
    if (!acceptedTerms) {
        setMessage("Vennligst godta vilkårene for å fortsette.");
        return;
    }

    if (!validate()) {
        setMessage("Vennligst fyll inn de obligatoriske feltene.");
        return;
    }

    setLoading(true);
    setMessage('');

    const orderData = {
      step1State: {
        selectTimeLater: 0,
        numDeliveryDates: dateTimeFields.length,
        currentLocation: address,
        savedLocation: address,
        savedLat: vendorData.results.formatted_address.latitude,  // Example latitude, replace with actual data
        savedLng: vendorData.results.formatted_address.longitude,  // Example longitude, replace with actual data
        savedZoom: "15",
        times: dateTimeFields.map((field, index) => ({
          itemId: index + 1,
          time: field.time,
          date: field.date
        })),
        address_1: vendorData.results.formatted_address.address_1,
        country: vendorData.results.formatted_address.country, // Replace with actual data if available
        postcode: vendorData.results.formatted_address.postcode,  // Replace with actual data if available
        town: vendorData.results.formatted_address.town,  // Replace with actual data if available
        vendor_id: vendorData?.results?.vendor_id,  // Replace with actual data if available
        distance: vendorData?.results?.distance,  // Example distance, replace with actual data
        vendor_address: vendorData?.results?.vendor_address, // Replace with actual data
        vendor_email: vendorData?.results?.vendor_email // Replace with actual data
      },
      step2State: {
        deliveries: [
          {
            deliveryId: 1,
            calculatorAreas: [],
            selectAreaLater: false,
            totalArea: totalArea.toString(),
            selectVehicleLater: false,
            vehicleType: vehicleType,  // Example vehicle type, replace with actual data
            concreteIsCustom: false,
            customConcreteType: concreteConfig ? 10 : (selectedProjectType || '1'),  // Example custom concrete type, replace with actual data
            withDeclineOption: castWithDrop,
            withRetardationOption: delay,
            withSteelfiberOption: steelFiber,
            withAcceleratorOption: accelerator,
            pumpWash: pumpMixerWashChecked,
            addSmallVolume: addAmountChecked,
            addHose: addHoseChecked,
            hoseAmount: hoseAmount ? hoseAmount.toString() : "0"
          }
        ],
        numCustomConcretes: concreteConfig ? 1 : 0,
        customConcretes: concreteConfig
      },
      step3State: {
        contactType: responsibility === "company" ? "company" : "selfBuilder",
        professionalLabor: offerOnCasting,
        professionalLevel: offerOnFloorSanding,
        termsAccepted: acceptedTerms,
        privacyAccepted: false,
        otherInformation: projectDescription,
        companyName: formData.companyName,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        emailAddress: formData.email,
        vatNumber: formData.organizationNumber,
        projectNumber: formData.projectNumber,
        attachmentID: fileIDs,
      }
    };

    console.log("Order Data:", orderData); // Log data to console

    try {
      const result = await createOrder(orderData);
      console.log("Bestilling opprettet vellykket:", result);
      setMessage("Bestilling opprettet vellykket");
      // Redirect to the same page to refresh and clear data
      if (loggedIn && JSON.parse(loggedIn) === true) {
        setTimeout(() => navigate(`/projects/order-details/${result.order_id}`), 2000);
      }else{
        setTimeout(() => navigate("/search-address"), 2000);
      }
      
    } catch (error) {
      console.error("Feil ved oppretting av bestilling:", error);
      setMessage(`Kunne ikke opprette bestilling: ${error.message || 'En feil oppstod'}`);
    } finally {
      setLoading(false);
    }
  };

  const ALLOWED_FILE_TYPES = ["application/pdf", "image/png", "image/jpeg"];

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (!files.length) return;

    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user?.id || "guest"; // Fallback to "guest" if no userId

    for (let file of files) {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        alert(`Ugyldig filtype: Kun PDF, PNG og JPEG er tillatt.`);
        continue; // Skip invalid file
      }
      setIsLoading(true);
      try {
        const response = await fetchUploadAPI(file, userId);
        console.log("fetchUploadAPI:", response);
        
        if (response && response.id) { 
          setIsLoading(false);
          const fileId = response.id;
          const fileName = response.name;
          setFileIDs(prev => [...prev, fileId]);
          setUploadedFiles(prev => [...prev, { id: fileId, name: fileName }]);
          alert(`Fil lastet opp vellykket`);
        } else {
          setIsLoading(false);
          alert(`Kunne ikke laste opp fil`);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("File upload failed:", error);
        alert(`Feil ved opplasting av fil`);
      }
    }
  };

  // 📌 Handle File Deletion
  const handleFileRemove = async (fileId, index) => {
    const confirmDelete = window.confirm("Er du sikker på at du vil slette denne filen?");
    if (!confirmDelete) return;
    setIsLoading(true);
    try {
      await deleteFileAPI(fileId);
      setIsLoading(false);
      setFileIDs(prev => prev.filter(id => id !== fileId));
      setUploadedFiles((prev) => prev.filter((_, i) => i !== index));
      alert(`Fil slettet vellykket.`);
    } catch (error) {
      setIsLoading(false);
      console.error("File delete failed:", error);
      alert(`Feil ved sletting av fil.`);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const isDayTime = (time) => {
    const hour = parseInt(time.split(":")[0], 10);
    return hour >= 6 && hour < 18;
  };

  const extraContent = (
    <Box
      sx={{

        height: "60% !important",
        minHeight: "200px !important",
        backgroundColor: "#006bb7",
        paddingLeft: "20px",
        marginTop: "180px",
      }}
    >
      <Grid container direction="column" gap={2}>
        <Typography color={"#fff"} variant="body2">
          <span style={{ fontWeight: "900", fontSize: "20px" }}>Steg 1</span> Velg adresse
        </Typography>
        <Box
          p={2}
          sx={{
            border: "1px solid #fff",
            width: "75%",
            backgroundColor: "#0b97ef",
            borderRadius: "10px",
          }}
        >
          <Typography color={"#fff"} variant="body2">
            <span style={{ fontWeight: "800" }}>Lever til:</span> {address}
          </Typography>
          {dateTimeFields &&
            dateTimeFields.map((field, index) => {
              const customDateFormat = `${field.date.split("-")[2]}.${field.date.split("-")[1]}.${field.date.split("-")[0]}`;
              return (
                <>
                  {field.date && field.time ? (
                    // <Box key={index} color="#fff" mt={2} p={2} bgcolor="#333" borderRadius={4}>
                    //   <Typography variant="body2">
                    //     On {field.date}, {field.time}
                    //   </Typography>
                    //   <Grid container spacing={2} alignItems="center">
                    //     <Grid item>
                    //       {weatherData[index]?.weather?.icon && (
                    //         <Avatar
                    //           src={`https://api.met.no/images/weathericons/svg/${weatherData[index].weather.icon}.svg`}
                    //           alt="Weather Icon"
                    //           sx={{ width: 24, height: 24 }}
                    //         />
                    //       )}
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Temperature: {weatherData[index]?.weather?.temperature}°C
                    //       </Typography>
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Air Pressure: {weatherData[index]?.weather?.air_pressure} hPa
                    //       </Typography>
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Cloud Area Fraction: {weatherData[index]?.weather?.cloud_area_fraction}%
                    //       </Typography>
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Relative Humidity: {weatherData[index]?.weather?.relative_humidity}%
                    //       </Typography>
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Wind Direction: {weatherData[index]?.weather?.wind_from_direction}°
                    //       </Typography>
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Wind Speed: {weatherData[index]?.weather?.wind_speed} m/s
                    //       </Typography>
                    //     </Grid>
                    //   </Grid>
                    // </Box>
                    <WeatherConditionsCard
                      key={index}
                      index={index}
                      date={customDateFormat}
                      time={field.time}
                      weatherForDate={weatherData[index].weather}
                    />
                  ) : null}
                </>
              );
            })}
        </Box>
        <Typography color={"#fff"} variant="body2">
          <span style={{ fontWeight: "900", fontSize: "20px" }}>Steg 2</span> Leverings konfigurasjoner
        </Typography>
        <Box
          p={2}
          sx={{
            border: "1px solid #fff",
            width: "75%",
            backgroundColor: "#0b97ef",
            borderRadius: "10px",
          }}
        >
          <Typography color={"#fff"} variant="body2">
            <span style={{ fontWeight: "800" }}>Totalt Areal:</span> {`${totalArea.toLocaleString("nb-NO", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} m³`}
          </Typography>
          <Typography color={"#fff"} variant="body2">
            <span style={{ fontWeight: "800" }}>Prosjekt type:</span> {personName}
          </Typography>
          <Typography color={"#fff"} variant="body2">
            <span style={{ fontWeight: "800" }}>Tilpasset betong:</span> {concreteConfig?.[0]?.name || "N/A"}
          </Typography>
          {concreteConfig?.[0] && (
            <Box ml={2}>
              <Typography color={"#fff"} variant="body2">
                <span style={{ fontWeight: "800" }}>Kvalitet:</span> {concrete_quality.find(q => q.id === parseInt(concreteConfig[0].quality))?.name || 'N/A'}
              </Typography>
              <Typography color={"#fff"} variant="body2">
                <span style={{ fontWeight: "800" }}>Stentype:</span> {concrete_stone.find(s => s.id === parseInt(concreteConfig[0].stone))?.name || 'N/A'}
              </Typography>
              <Typography color={"#fff"} variant="body2">
                <span style={{ fontWeight: "800" }}>Steinreduksjon:</span> {concrete_reduction.find(r => r.id === parseInt(concreteConfig[0].reduction))?.name || 'N/A'}
              </Typography>
              <Typography color={"#fff"} variant="body2">
                <span style={{ fontWeight: "800" }}>Konsistens:</span> {concrete_consistency.find(c => c.id === parseInt(concreteConfig[0].consistency))?.name || 'N/A'}
              </Typography>
              <Typography color={"#fff"} variant="body2">
                <span style={{ fontWeight: "800" }}>Ekstra valg:</span>
              </Typography>
              <Box ml={2}>
                {concreteConfig[0].lowCarbon && (
                  <Typography color={"#fff"} variant="body2">
                    <span style={{ fontWeight: "800" }}>Lavkarbon:</span> Yes
                  </Typography>
                )}
                {concreteConfig[0].curbstone && (
                  <Typography color={"#fff"} variant="body2">
                    <span style={{ fontWeight: "800" }}>Jordfuktig:</span> Yes
                  </Typography>
                )}
                {concreteConfig[0].frostProof && (
                  <Typography color={"#fff"} variant="body2">
                    <span style={{ fontWeight: "800" }}>Frostsikker:</span> Yes
                  </Typography>
                )}
                {concreteConfig[0].farming && (
                  <Typography color={"#fff"} variant="body2">
                    <span style={{ fontWeight: "800" }}>Husdyrhold:</span> Yes
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          {selectedItem === "trackcar" && (
            <Typography color={"#fff"} variant="body2">
              <span style={{ fontWeight: "800" }}>16m Båndbil:</span> Yes
            </Typography>
          )}
          {selectedItem === "directTip" && (
            <Typography color={"#fff"} variant="body2">
              <span style={{ fontWeight: "800" }}>1m Direkte tipp:</span> Yes
            </Typography>
          )}
          {selectedItem === "pumpMixer" && (
            <Box>
              <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>24m Pumpemixer:</span> Yes</Typography>
              {addAmountChecked && (
                <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>Pumpemikser lagt til: </span> Yes</Typography>
              )}
              {pumpMixerWashChecked && (
                <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>Pumpevask: </span> Yes</Typography>
              )}
              {addHoseChecked && (
                <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>Slangelengde i meter: </span> {hoseAmount ? hoseAmount : "0"} meters</Typography>
              )}
            </Box>
          )}
          {selectedItem === "slideCar" && (
            <Typography color={"#fff"} variant="body2">
              <span style={{ fontWeight: "800" }}>9m Rennebil:</span> Yes
            </Typography>
          )}
          
          <Typography color={"#fff"} variant="body2">
            <span style={{ fontWeight: "800" }}>Velg lastetype:</span> {uploadingTypeChecked ? "Yes" : "No"}
          </Typography>
          {uploadingTypeChecked && (
            <> 
              {castWithDrop && (
                <Typography color={"#fff"} variant="body2">
                  <span style={{ fontWeight: "800" }}>Støp med fall:</span> Yes
                </Typography>
              )}
              {delay && (
                <Typography color={"#fff"} variant="body2">
                  <span style={{ fontWeight: "800" }}>Forsinkelse:</span> Yes
                </Typography>
              )}
              {steelFiber && (
                <Typography color={"#fff"} variant="body2">
                  <span style={{ fontWeight: "800" }}>Stålfiber:</span> Yes
                </Typography>
              )}
              {accelerator && (
                <Typography color={"#fff"} variant="body2">
                  <span style={{ fontWeight: "800" }}>Akselerator:</span> Yes
                </Typography>
              )}
            </>
          )}
        </Box>
        <Typography color={"#fff"} variant="body2">
          <span style={{ fontWeight: "900", fontSize: "20px" }}>Steg 3</span> Sammendrag
        </Typography>
        <Box
          p={2}
          sx={{
            border: "1px solid #fff",
            width: "75%",
            backgroundColor: "#0b97ef",
            borderRadius: "10px",
            marginBottom: "70px",
          }}
        >
          <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>Tilbud på utstøping:</span> {offerOnCasting ? "Yes" : "No"}</Typography>
          <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>Tilbud på gulvsliping:</span> {offerOnFloorSanding ? "Yes" : "No"}</Typography>
          <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>Ansvar:</span> {responsibility === "selfBuilder" ? "Self Builder" : "Company"}</Typography>
          <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>Godkjente vilkår:</span> {acceptedTerms ? "Yes" : "No"}</Typography>
          <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>Opplastede filer:</span></Typography>
          {uploadedFiles.length > 0 ? (
            <Box mt={2}> {/* Wrapped inside Box */}
              <Grid container spacing={2} mb={2}>
                {uploadedFiles.map((file, index) => (
                  <Grid item xs={12} key={index}> {/* Full row width */}
                    <Card 
                      sx={{ 
                        boxShadow: 3, 
                        borderRadius: 2, 
                        padding: 1
                      }}
                    >
                      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: 3, paddingRight: 3 }}>
                        <Typography variant="body2" fontWeight="bold">
                          {file.name}
                        </Typography>
                        
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box> 
          ) : (
            <Typography color={"#fff"} variant="body2">Ingen filer lastet opp</Typography>
          )}
        </Box>
      </Grid>
    </Box>
  );

  return (
    <DefaultLayout extraContent={extraContent}>
      <Grid container spacing={2} gap={3} mt={5} mb={10} px={{ xs: 2, md: 20 }}>
        <Grid item xs={12}>
          <Typography variant="h3" color="#000" sx={{ mb: 2, textAlign: "center" }}>
            Annen informasjon?
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Beskriv ditt prosjekt/sted."
            multiline
            rows={4}
            value={projectDescription} 
            onChange={handleDescriptionChange} 
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                padding: "10px", 
              },
              "& textarea": {
                width: "100% !important", 
              }
            }}
          />
          <Box
            sx={{
              border: "1px solid black",
              backgroundColor: "#fff",
              borderRadius: "7px",
              mb: 2,
            }}
          > 
            {isLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}
              >
                <CircularProgress size={60} color="info" />
              </Box>
            )}

            <Box display="flex" justifyContent="flex-end" mx={3} my={1}>
              <input type="file" hidden multiple onChange={handleFileChange} />
              <SoftButton variant="gradient" color="info" component="label">
                Velg filer du vil laste opp
                <input type="file" hidden multiple onChange={handleFileChange} />
              </SoftButton>
            </Box>
            {uploadedFiles.length > 0 && (
              <Box mx={2} mt={2}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Opplastede filer:
                </Typography>

                <Grid container spacing={2} mb={2}>
                  {uploadedFiles.map((file, index) => (
                    <Grid item xs={12} key={index}> {/* Full row width */}
                      <Card 
                        sx={{ 
                          boxShadow: 3, 
                          borderRadius: 2, 
                          padding: 1
                        }}
                      >
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: 3, paddingRight: 3 }}>
                          <Typography variant="body2" fontWeight="bold">
                            {file.name}
                          </Typography>
                          <IconButton edge="end" onClick={() => handleFileRemove(file.id, index)}>
                            <DeleteIcon sx={{ color: "red" }} />
                          </IconButton>
                        </Box>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </Box>

          <Box display="flex" flexWrap="wrap" gap={2} px={2}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    border: "1px solid black",
                  }}
                  checked={offerOnCasting}
                  onChange={() => setOfferOnCasting(!offerOnCasting)}
                />
              }
              label="Tilbud på utstøping!"
              sx={{
                color: "#000",
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    border: "1px solid black",
                  }}
                  checked={offerOnFloorSanding}
                  onChange={() => setOfferOnFloorSanding(!offerOnFloorSanding)}
                />
              }
              label="Tilbud på gulvsliping!"
              sx={{
                color: "#000",
              }}
            />
          </Box>
         
          
          <Grid container spacing={2}>
          <Grid item xs={12} mb={0} mt={2} sx={{display: "flex", justifyItems: "start" }}>
                <SoftTypography
                  variant="h4"
                  sx={{ display: "flex", justifyContent: "center", color: "#000000", fontWeight:"bold"}}
                >
                  <span style={{ color: "#006bb7", marginRight: "8px" }}>HVEM</span> har ansvaret{" "}
                  <span style={{ color: "#006bb7"}}>?</span>
                </SoftTypography>
              </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                sx={{
                  color: responsibility === "selfBuilder" ? "#fff" : "#006bb7",
                  bgcolor: responsibility === "selfBuilder" ? "#006bb7" : "006bb7",
                  "&:hover": {
                    bgcolor:
                      responsibility === "selfBuilder" ? "#006bb7" : "rgba(23, 193, 232, 0.1)",
                    borderColor: responsibility === "selfBuilder" ? "#006bb7" : "#006bb7",
                  },
                  borderColor: responsibility === "selfBuilder" ? "#006bb7" : "#006bb7",
                }}
                variant={responsibility === "selfBuilder" ? "gradient" : "outlined"}
                fullWidth
                onClick={() => handleResponsibilityChange("selfBuilder")}
              >
                SELVBYGGER
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                sx={{
                  color: responsibility === "company" ? "#fff" : "#006bb7",
                  bgcolor: responsibility === "company" ? "#006bb7" : "006bb7",
                  "&:hover": {
                    bgcolor: responsibility === "company" ? "#006bb7" : "rgba(23, 193, 232, 0.1)",
                    borderColor: responsibility === "company" ? "#006bb7" : "#006bb7",
                  },
                  borderColor: "#006bb7",
                }}
                variant={responsibility === "company" ? "gradient" : "outlined"}
                fullWidth
                onClick={() => handleResponsibilityChange("company")}
              >
                BEDRIFT
              </Button>
            </Grid>
          </Grid>

          {responsibility === "selfBuilder" && (
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Fornavn"
                    name="firstName"
                    value={formData.firstName} // Bind value
                    onChange={handleInputChange}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    InputProps={{
                      sx: {
                        padding: '10px 14px !important', // Adjust padding to ensure text visibility
                        '& input': {
                          width: 'calc(100% - 0px) !important', // Adjust input width to prevent text cutoff
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Etternavn"
                    name="lastName"
                    value={formData.lastName} // Bind value
                    onChange={handleInputChange}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    InputProps={{
                      sx: {
                        padding: '10px 14px !important', // Adjust padding to ensure text visibility
                        '& input': {
                          width: 'calc(100% - 0px) !important', // Adjust input width to prevent text cutoff
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Telefonnummer"
                    name="phoneNumber"
                    value={formData.phoneNumber} // Bind value
                    onChange={handleInputChange}
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber}
                    InputProps={{
                      sx: {
                        padding: '10px 14px !important', // Adjust padding to ensure text visibility
                        '& input': {
                          width: 'calc(100% - 0px) !important', // Adjust input width to prevent text cutoff
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="E-post"
                    name="email"
                    value={formData.email} // Bind value
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    InputProps={{
                      sx: {
                        padding: '10px 14px !important', // Adjust padding to ensure text visibility
                        '& input': {
                          width: 'calc(100% - 0px) !important', // Adjust input width to prevent text cutoff
                        }
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {responsibility === "company" && (
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Firmanavn"
                    name="companyName"
                    value={formData.companyName} // Bind value
                    onChange={handleInputChange}
                    error={!!errors.companyName}
                    helperText={errors.companyName}
                    InputProps={{
                      sx: {
                        padding: '10px 14px !important', // Adjust padding to ensure text visibility
                        '& input': {
                          width: 'calc(100% - 0px) !important', // Adjust input width to prevent text cutoff
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Fornavn"
                    name="firstName"
                    value={formData.firstName} // Bind value
                    onChange={handleInputChange}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    InputProps={{
                      sx: {
                        padding: '10px 14px !important', // Adjust padding to ensure text visibility
                        '& input': {
                          width: 'calc(100% - 0px) !important', // Adjust input width to prevent text cutoff
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Etternavn"
                    name="lastName"
                    value={formData.lastName} // Bind value
                    onChange={handleInputChange}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    InputProps={{
                      sx: {
                        padding: '10px 14px !important', // Adjust padding to ensure text visibility
                        '& input': {
                          width: 'calc(100% - 0px) !important', // Adjust input width to prevent text cutoff
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Telefonnummer"
                    name="phoneNumber"
                    value={formData.phoneNumber} // Bind value
                    onChange={handleInputChange}
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber}
                    InputProps={{
                      sx: {
                        padding: '10px 14px !important', // Adjust padding to ensure text visibility
                        '& input': {
                          width: 'calc(100% - 0px) !important', // Adjust input width to prevent text cutoff
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="E-post"
                    name="email"
                    value={formData.email} // Bind value
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    InputProps={{
                      sx: {
                        padding: '10px 14px !important', // Adjust padding to ensure text visibility
                        '& input': {
                          width: 'calc(100% - 0px) !important', // Adjust input width to prevent text cutoff
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Organisasjonsnummer"
                    name="organizationNumber"
                    value={formData.organizationNumber} // Bind value
                    onChange={handleInputChange}
                    error={!!errors.organizationNumber}
                    helperText={errors.organizationNumber}
                    InputProps={{
                      sx: {
                        padding: '10px 14px !important', // Adjust padding to ensure text visibility
                        '& input': {
                          width: 'calc(100% - 0px) !important', // Adjust input width to prevent text cutoff
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Prosjektnummer"
                    name="projectNumber"
                    value={formData.projectNumber} // Bind value
                    onChange={handleInputChange}
                    error={!!errors.projectNumber}
                    helperText={errors.projectNumber}
                    InputProps={{
                      sx: {
                        padding: '10px 14px !important', // Adjust padding to ensure text visibility
                        '& input': {
                          width: 'calc(100% - 0px) !important', // Adjust input width to prevent text cutoff
                        }
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}



          <Typography variant="body1" color="error" sx={{ mt: 2, mb: 2 }}>
            OPPMERKSOMHET! Som kunde er du selv ansvarlig for opplysningene som legges inn, inkludert betongmengde, bruksområde, og lossemetode.
          </Typography>
          <FormControlLabel
            sx={{ marginX: "5px" }}
            control={
              <Checkbox
                sx={{ border: "1px solid black" }}
                checked={acceptedTerms}
                onChange={() => setAcceptedTerms(!acceptedTerms)}
              />
            }
            label="Jeg godtar vilkårene og betingelsene, samt personvernreglene."
          />

          <Box gap={5} sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
            <SoftButton 
              variant="contain"
              color="info"
              sx={{
                height: "45px !important",
                borderRadius: "7px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#0c97ee",
                border: "1px solid #0c97ee",
              }} 
              onClick={handleBackClick}
            >
              TILBAKE
            </SoftButton>
            <SoftButton variant="gradient" color="info" disabled={loading} onClick={handleSubmit}>
              {loading ? "Behandler..." : "BESTILL"}
            </SoftButton>
          </Box>

          {message && (
            <Typography variant="body1" display={"flex"} justifyContent={"center"} color={message.startsWith("Bestilling opprettet vellykket") ? "green" : "error"} sx={{ mt: 2, mb: 2 }}>
              {message}
            </Typography>
          )}
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};

export default Summary;
