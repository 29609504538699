/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================
  
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/ 

import { useState, useEffect, useRef } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar"; 
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, OutlinedInput, Typography } from "@mui/material";
import SoftInput from "components/SoftInput";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// Soft UI Dashboard React examples
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import VendorInfoCard from "layouts/dashboard/components/ForProjects/OrderDetails/Cards/VendorInfoCard";
import Badge from "@mui/material/Badge";


//mui icons
import FireTruckIcon from "@mui/icons-material/FireTruck";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import CloudIcon from "@mui/icons-material/Cloud";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TitleIcon from "@mui/icons-material/Title";
import DescriptionIcon from "@mui/icons-material/Description";
import BusinessIcon from "@mui/icons-material/Business";
import DistanceIcon from "@mui/icons-material/Explore"
import WorkIcon from "@mui/icons-material/Work";
import EventIcon from "@mui/icons-material/Event";
import VerifiedIcon from "@mui/icons-material/Verified"; // Status Icon
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import PhoneIcon from "@mui/icons-material/Phone";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import TodayIcon from "@mui/icons-material/Today";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

//pictogrammers icons

// Soft UI Dashboard React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { Modal, FormControl, InputLabel, Select } from "@mui/material";

// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import home from "assets/images/home.jpg";
import homeimage from "assets/images/h2.jpg";
import curved0 from "assets/images/curved-images/curved0.jpg";
import MyGoogleMap from "layouts/dashboard/components/ForProjects/OrderDetails/GoogleMap";
import { object } from "prop-types";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ProjectAccordion from "layouts/profile/components/ProjectAccordion/ProjectAccordion";
import borders from "assets/theme/base/borders";
import boxShadow from "assets/theme/functions/boxShadow";
import typography from "assets/theme/base/typography";
import SoftButton from "components/SoftButton";
import EditIcon from "@mui/icons-material/Edit";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CloseIcon from "@mui/icons-material/Close";
import { getOrderById, approveProject, changeRequestByVendor, cancelRequestByVendor, fetchStatusList, fetchVendorList, changeVendor, changeOrderStatus, updateOrderTotal } from "api/apiService";
import CircularProgress from "@mui/material/CircularProgress"; // Importing CircularProgress for loader
import { NavLink } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";

function OrderDetail() {
  const location = useLocation();
  const { orderId } = useParams();
  // const orderId = location.state?.orderId;
  console.log("orderDetail page-----", orderId,);
  const [orderData, setOrderData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
   const navigate = useNavigate(); // Use the navigate hook
  
  const { size, fontWeightBold } = typography;
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [changeDate, setChangeDate] = useState('');
  const [changeTime, setChangeTime] = useState('');
  const [changeDetail, setChangeDetail] = useState('');
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [orderIdToCancel, setOrderIdToCancel] = useState(null); // Store orderId for cancellation

  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [openVendorModal, setOpenVendorModal] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const selectRef = useRef(null);
  const [open3, setOpen3] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [orderTotal, setOrderTotal] = useState(orderData?.order_total || 0);
  const [tempOrderTotal, setTempOrderTotal] = useState(orderData?.order_total || 0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditClick = () => {
    const numericOrderTotal = parseFloat(orderData?.order_meta?._order_total[0] || 0);
    setTempOrderTotal(numericOrderTotal);
    setIsModalOpen(true);
  };


  // Save the updated order total
const handleSaveClick = async () => {
  setIsLoading(true); // Show loader
  console.log("Saved Order Total: ", tempOrderTotal); // Log the input value when saving

  // Call the API function to update the order total
  try {
    const response = await updateOrderTotal(orderId, tempOrderTotal);
    if (response.status === "success") {
      console.log("Order total updated successfully:", response.message);

      // Update the order data with the new total
      setOrderData((prevData) => ({
        ...prevData,
        new_total: tempOrderTotal,
      }));

      alert("Bestillingens totalbeløp oppdatert vellykket!");
      setIsModalOpen(false); // Close modal
      setIsEditing(false); // Exit edit mode
      setIsLoading(false); // Stop loader

      // Optionally, reload the page after 2 seconds to reflect changes
      setTimeout(() => {
        window.location.reload(); // Reload the page after success message
      }, 500);
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    setIsLoading(false); // Stop loader
    alert("Feil ved oppdatering av bestillingens totalbeløp.");
    console.error("Error updating order total:", error);
  }
};

// Cancel editing 
const handleCancelClick = () => {
  setTempOrderTotal(orderData?.new_total); // Reset tempOrderTotal
  setIsEditing(false); // Exit edit mode
  setIsModalOpen(false); // Close modal
};


  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  // const dataSet = { weather_temprature: 4, weather_icon: "url", address: "address" };
  const isAlreadyApproved = orderData?.order_meta?._project_approved?.[0] === 'yes'; 
  const isDateApproved = orderData?.order_meta?._approved_request?.[0] === 'yes'; 
  const isDateDeny = orderData?.order_meta?._approved_request?.[0] === 'no'; 
  
  const createdBy = orderData?.order_meta?._customer_user?.[0];

  const role = localStorage.getItem("role");

  const loggedInUserId = JSON.parse(localStorage.getItem("user"))?.id;  // Get the logged-in user's ID

  // Check if the logged-in vendor is trying to approve their own order
  const isVendorOwnOrder = role === "vendor" && parseInt(createdBy) === parseInt(loggedInUserId);

 

  const fetchOrderDetails = async () => {
    const data = await getOrderById({ order_id: orderId });

    if (data.status) {
      setOrderData(data.data);
      setIsLoading(false);

      console.log("set data", orderData, data.data);
    }

    console.log("fetch order by id: ", data);
  };

  const fetchVendors = async () => {
    try {
      const response = await fetchVendorList();
      console.log(" fetching Vendors", response);
      if (response.status) {
        setVendorList(response.vendor_list);
      }
    } catch (error) {
      console.error("Error fetching Vendores", error);
    }
  };

  const handleVendorChange = (event) => {
    setSelectedVendor(event.target.value);
  };


  const handleInputClick1 = () => {
    setOpen1((prevOpen) => !prevOpen);
  };

  const handleInputClick2 = () => {
    setOpen2((prevOpen) => !prevOpen);
  };

  const timeOptions = Array.from({ length: 10 }, (_, i) => {
    const hour = (i + 6).toString().padStart(2, "0"); // Generates "06" to "15"
    return `${hour}:00`;
  });

  const handleInputClick3 = () => {
    setOpen3((prevOpen) => !prevOpen);
  };

  const handleClose3 = () => {
    setOpen3(true);
  };

  const handleConfirmVendorChange = async () => {
    if (!selectedVendor) {
      alert("Vennligst velg en leverandør");
      return;
    }
    setIsLoading(true);
    try {
      const orderAddress = orderData?.address;
      // API call to change status
      const response = await changeVendor(orderId, selectedVendor, orderAddress); // Change this to the actual API call
      console.log("changeVendor response", response);
      if (response.status) {
        setOpenVendorModal(false);
        alert("Leverandør endret vellykket!");
        setIsLoading(false);
        window.location.reload();
      } else {
        alert("Kunne ikke endre leverandør. Vennligst prøv igjen.");
      }
    } catch (error) {
      console.error("Error changing vendor", error);
      alert("Det oppstod en feil under endring av leverandør.");
      setIsLoading(false);
    }
  };

  const handleOpenVendorModal = () => {
    setOpenVendorModal(true);
  };

  const handleCloseVendorModal = () => {
    setOpenVendorModal(false);
  };


  const fetchStatuses = async () => {
    try {
      const response = await fetchStatusList();
      console.log(" fetching statuses", response);
      if (response.status) {
        setStatusList(response.status_list);
      }
    } catch (error) {
      console.error("Error fetching statuses", error);
    }
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleConfirmStatusChange = async () => {
    if (!selectedStatus) {
      alert("Please select a status");
      return;
    }
    setIsLoading(true);
    
    try {
      // API call to change status
      const response = await changeOrderStatus(orderId, selectedStatus); // Change this to the actual API call
      console.log("selectedStatus response", response);
      if (response.status) {
        setOpenStatusModal(false);
        alert("Status endret vellykket!");
        setIsLoading(false);
        window.location.reload();
      } else {
        alert("Kunne ikke endre status. Vennligst prøv igjen.");
      }
    } catch (error) {
      console.error("Error changing status", error);
       alert("Det oppstod en feil under endring av status.");
      setIsLoading(false);
    }
  };

  const handleOpenStatusModal = () => {
    setOpenStatusModal(true);
  };

  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
  };

  // Function to open the confirmation modal
  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };

  // Function to close the confirmation modal
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  // Function to handle the actual approval after confirmation
  const confirmApproval = () => {
    handleApproval(orderData?.order_number); // Call the actual approval function
    closeConfirmModal(); // Close the modal
  };

  // Handle Approval
  const handleApproval = async (orderId) => {
    setIsLoading(true);
    try {
      const vendor_id = localStorage.getItem("vendor_id"); // Get vendor's ID from localStorage
      
      // Call the API to approve the request
      const response = await approveProject(orderId, vendor_id);
      console.log("approving response:", response);
      setIsLoading(false);
      if (response.status) {
        // If the approval was successful, update the status text or any other state
        alert("Bestilling godkjent vellykket!");
        // Optionally reload the page or fetch updated data

        window.location.reload();
      } else {
        alert("Kunne ikke godkjenne bestillingen. Vennligst prøv igjen.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error approving order:", error);
      alert("Det oppstod en feil under godkjenning av bestillingen.");
    }
  };

  const openCancelDialog = (orderId) => {
    setOrderIdToCancel(orderId); // Set the orderId to be canceled
    setCancelDialogOpen(true); // Open the cancel confirmation dialog
  };

  // Function to close the cancel confirmation dialog
  const closeCancelDialog = () => {
      setCancelDialogOpen(false); // Close the dialog
  };

  // Function to confirm and send the cancel request
  const confirmCancelRequest = async () => {
    
    setIsLoading(true); // Show loading state while the request is being processed
    try {
        // Make the API call to cancel the request
        const response = await cancelRequestByVendor(orderIdToCancel); // Pass the orderId
        console.error("response sending cancel request:", response);
        // Close the dialog once the request is sent
        setCancelDialogOpen(false);
        setIsLoading(false);

        if (response.status) {
            alert("Bestilling kansellert vellykket!");
            // Optionally reload or update the state
            navigate("/dashboard");
        } else {
            alert("Kunne ikke kansellere bestillingen. Vennligst prøv igjen.");
        }
    } catch (error) {
        setIsLoading(false);
        console.error("Failed to order cancel. Please try again.:", error);
        alert("Det oppstod en feil under sending av kanselleringsforespørselen.");
    }
  };

  // Handle Change Request

  const handleChangeRequest = async (orderId) => {
    if (!changeDate || !changeTime || !changeDetail) {
      alert("Vennligst fyll inn alle feltene.");
      return;
    }
    setIsLoading(true);
    try {
      // Call the API to send the change request
      const response = await changeRequestByVendor(orderId, changeDate, changeTime, changeDetail);
      setOpen(false);
       setIsLoading(false);
      if (response.status) {
        alert("Endringsforespørsel sendt vellykket!");
        // window.location.reload();
      } else {
        alert("Kunne ikke sende endringsforespørselen. Vennligst prøv igjen.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error sending change request:", error);
      alert("Det oppstod en feil under sending av endringsforespørselen.");
    }
  };

  
  const openChangeRequestModal = () => {
    setOpen(true); // Opens the modal
  };

  // Close Change Request Modal
  const closeChangeRequestModal = () => {
    setOpen(false); 
  };




  useEffect(() => {
    fetchOrderDetails();
    fetchStatuses();
    fetchVendors();
  }, []);

  return (
    <DashboardLayout>
      <Box>
        {isLoading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              zIndex: 1000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={100} color="info" />
          </div>
        )}


        {!isLoading && (
          <>
            {/* <SoftBox mt={4}>
                <Box sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                  <Box component={NavLink} to={`/projects/order-details/${orderId}`}>
                    {role === "admin" && (
                      <SoftButton
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        variant="outlined"
                        color="info"
                        size="small"
                      >
                        <EditIcon fontSize="medium" />
                        <span style={{ padding: "0 6px" }}>Edit</span>
                      </SoftButton>
                    )}
                  </Box>
                  <Box component={NavLink} to={`/projects/order-details/${orderId}`}>
                    {["admin", "vendor"].includes(role) && (
                      <SoftButton
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        variant="outlined"
                        color="info"
                        size="small"
                        onClick={handleChangeRequest}
                      >
                        <AutorenewIcon fontSize="medium" />
                        <span style={{ padding: "0 6px" }}>Change Request</span>
                      </SoftButton>
                    )}
                  </Box>
                  <Box component={NavLink} to={`/projects/order-details/${orderId}`}>
                    {["admin", "vendor", "customer"].includes(role) && (
                      <SoftButton
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        variant="outlined"
                        color="info"
                        size="small"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyItems: "center",
                          alignItems: "center",
                        }}
                      >
                        <CloseIcon fontSize="medium" />
                        <span style={{ padding: "0 6px" }}>Cancel Request</span>
                      </SoftButton>
                    )}
                  </Box>

                   <SoftButton variant="outlined" color="secondary" size="small">
                <EditIcon />
                <span style={{ padding: "0 6px" }}>Edit</span>
              </SoftButton> 
                </Box>
              </SoftBox>
            */}

            <SoftBox position="relative" mt={4}>
              {/* <DashboardNavbar absolute dark /> */}
            
              <SoftBox
                display="flex"
                alignItems="center"
                position="relative"
                minHeight="18.75rem"
                borderRadius="xl"
                sx={{
                  // backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                  //   `${linearGradient(
                  //     rgba(gradients.info.main, 0.6),
                  //     rgba(gradients.info.state, 0.6)
                  //   )},

                  //   url(${curved0})`,
                  backgroundSize: "cover",
                  backgroundPosition: "50%",
                  overflow: "hidden",
                  boxShadow:
                    " rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <MyGoogleMap lng={+orderData?.lng} lat={+orderData?.lat} />
              </SoftBox>
              <Card
                sx={{
                  width: "93%",

                  backdropFilter: `saturate(200%) blur(30px)`,
                  background: "rgba(255, 255, 255, 0.6)",

                  boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                  position: "relative",
                  mt: -8,
                  mx: "auto",
                  py: 2,
                  px: 2,
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <SoftAvatar
                      src={homeimage}
                      alt="profile-image"
                      variant="rounded"
                      sx={{
                        width: { xs: 50, sm: 50, md: 50, lg: 50, xl: 53, xxl: 70 },
                        height: { xs: 50, sm: 50, md: 50, lg: 50, xl: 53, xxl: 70 }
                      }}
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <SoftBox height="100%" mt={0.5} lineHeight={1}>
                      <SoftTypography variant="h5" fontWeight="medium" color="rgba(0, 0, 0, 0.87)" sx={{ fontSize: { xs: "18px", sm: "18px", md: "18px", xxl:"26px" } }}>
                        Leveringsadresse
                      </SoftTypography>
                      <SoftTypography variant="button" fontWeight="medium" color="rgba(0, 0, 0, 0.87)" sx={{ fontSize: { xs: "16px", sm: "16px", md: "10px", xxl:"16px" } }}>
                        {orderData.address}
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={7.5} lg={7.6} xxl={7} sx={{ display: "inline-grid", justifyContent: "right !important", }}>
                    <AppBar position="static">
                      <Box sx={{ display: "flex",
                        flexWrap: "wrap",
                        gap: "20px", }}>
                        <SoftBox
                          sx={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            padding: { xs: "8px", sm: "8px", md: "6px", xxl:"8px" },
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            borderRadius: "5px",
                            

                            // minWidth: { xs: "auto", sm: "200px" }, width: {
                            //   xs: "auto", sm: "200px"
                            // }
                          }}
                        >
                          <FireTruckIcon sx={{ fontSize: { xs: "26px", sm: "26px", md: "22px", xxl:"26px" }, color: "#FFC312" }} />
                          <SoftTypography color="rgba(0, 0, 0, 0.87)"
                          fontWeight="medium"
                          sx={{ fontSize: { xs: "12px", sm: "12px", md: "10px", xxl:"12px" } }}>
                            Mengde {orderData?.total_volume ? `${orderData.total_volume}m³` : "0m³"}
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox
                          sx={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            padding: { xs: "8px", sm: "8px", md: "6px", xxl:"" },
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            borderRadius: "5px",
                            p: 1,

                            // minWidth: { xs: "auto", sm: "200px" }, width: {
                            //   xs: "auto", sm: "200px"
                            // }
                          }}
                        >
                          <EventIcon sx={{ fontSize: { xs: "26px", sm: "26px", md: "22px", xxl:"26px" }, color: "grey" }} />
                          <SoftTypography 
                            color="rgba(0, 0, 0, 0.87)"
                            fontWeight="medium"
                            sx={{ fontSize: { xs: "12px", sm: "12px", md: "10px", xxl:"12px" } }}
                          >
                            {role === "customer" && 
                                ["Kvalitetssikring", "Venter på betaling", "Behandler", "Venter-leverandør", "På vent"]
                                .includes(orderData?.order_status?.status_text) ? (
                                  "Dato som planlegges"
                              ) : orderData?.delivery_date?.[0]?.date && orderData?.delivery_date?.[0]?.time ? (
                                  `Leveringstid ${orderData.delivery_date[0].date} | Kl. ${orderData.delivery_date[0].time}`
                              ) : (
                                  "Dato som planlegges"
                              )}
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox
                          sx={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                            padding: { xs: "8px", sm: "8px", md: "6px", xxl:"" },
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            p: 1,
                            // minWidth: { xs: "auto", sm: "130px" }, width: {
                            //   xs: "auto", sm: "130px"
                            // }
                          }}
                        >
                          <CloudIcon sx={{ fontSize: { xs: "26px", sm: "26px", md: "22px", xxl:"26px" }, color: "#4B7BEC" }} />
                          <SoftTypography color="rgba(0, 0, 0, 0.87)"
                            fontWeight="medium"
                            sx={{ fontSize: { xs: "12px", sm: "12px", md: "10px", xxl:"12px" } }}>
                             Støpeværet {orderData?.weather_temprature ? `${orderData.weather_temprature}` : "0°C"}
                          </SoftTypography>
                        </SoftBox>
                      </Box>
                    </AppBar>
                  </Grid>
                </Grid>
              </Card>
            </SoftBox>
            {["admin"].includes(role) && (
              <SoftBox mt={4} mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} xl={12}>
                    <Card
                      sx={{
                        borderRadius: "12px",
                        padding: 3,
                        background: "linear-gradient(135deg,#fff, #fff)", // Gradient background
                        color: "white",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                        flexDirection: "column",
                        height: "100%", // Force equal height
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} xl={6}>
                          <SoftButton
                            variant="gradient"
                            color="success"
                            fullWidth
                            size="small"
                            onClick={handleOpenStatusModal}
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              padding: "8px",
                            }}
                          >
                            Endre status
                          </SoftButton>
                        </Grid>
                    
                        
                          
                          <Grid item xs={12} md={6} xl={6}>
                            <SoftButton
                              variant="gradient"
                              color="info"
                              fullWidth
                              size="small"
                              onClick={handleOpenVendorModal}
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              Endre leverandør
                            </SoftButton>
                          </Grid>
                      
                      
                          {/*
                            <Grid item xs={12} md={4} xl={4}>
                              <SoftButton
                                variant="gradient"
                                color="error"
                                fullWidth
                                size="small"
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                  padding: "8px",
                                }}
                              >
                                Kanseller bestilling
                              </SoftButton>
                            </Grid>
                          */}

                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </SoftBox>
            )}

            {["customer"].includes(role) && (
              <SoftBox mt={4} mb={3}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} xl={12}>
                    <Card
                      sx={{
                        borderRadius: "12px",
                        padding: 3,
                        background: "linear-gradient(135deg,#fff, #fff)",
                        color: "white",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Grid container spacing={1}>
                        {/* Status */}
                        <Grid item xs={12} md={3} xl={3}>
                          <SoftTypography
                            variant="h4"
                            fontWeight="bold"
                            sx={{
                              fontSize: "22px",
                              fontWeight: "medium",
                              padding: "5px 15px",
                              color: "rgba(0, 0, 0, 0.70)",
                              borderRadius: "20px",
                              textAlign: "center",
                              display: "inline-block",
                              position: "relative",
                              "&::before": {
                                content: '"-> "',
                                position: "absolute",
                                left: "-10px",
                                color: "rgba(0, 0, 0, 0.70)",
                                fontWeight: "bold",
                              },
                              "&::after": {
                                content: '" <-"',
                                position: "absolute",
                                right: "-10px",
                                color: "rgba(0, 0, 0, 0.70)",
                                fontWeight: "bold",
                              },
                            }}
                          >
                            {orderData?.order_status?.status_text}
                          </SoftTypography>
                        </Grid>

                        {/* Amount */}
                        <Grid item xs={12} md={2} xl={2}>
                          <SoftTypography
                            variant="h4"
                            fontWeight="bold"
                            sx={{
                              fontSize: "22px",
                              fontWeight: "medium",
                              padding: "5px 15px",
                              color: "rgba(0, 0, 0, 0.70)",
                              borderRadius: "20px",
                              textAlign: "center",
                              display: "inline-block",
                              position: "relative",
                            }}
                          >
                            {orderData?.order_status?.status_value}
                          </SoftTypography>
                        </Grid>

                        {/* VAT Information */}
                        <Grid item xs={12} md={4} xl={4}>
                          <SoftTypography
                            variant="h4"
                            fontWeight="bold"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              padding: "5px 15px",
                              color: "rgba(0, 0, 0, 0.70)",
                              borderRadius: "20px",
                              textAlign: "center",
                              display: "inline-block",
                              position: "relative",
                            }}
                          >
                            {orderData?.order_status?.status_vat}
                          </SoftTypography>
                        </Grid>

                        {/* Action Button */}
                        <Grid item xs={12} md={3} xl={3}>

                          {orderData?.order_status?.status_text === "Venter på betaling" && (
                            <a
                              href={orderData?.order_status?.status_pay_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              <SoftButton
                                variant="gradient"
                                color="info"
                                fullWidth
                                size="small"
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                  padding: "8px",
                                }}
                              >
                                {orderData?.order_status?.status_pay_text}
                                
                              </SoftButton>
                            </a>
                          )}

                          {orderData?.order_status?.status_text === "Avventer leveranse" && (
                            <SoftButton
                              variant="gradient"
                              color="success"
                              fullWidth
                              size="small"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              {orderData?.order_status?.status_pay_text}
                            </SoftButton>
                          )}

                          {orderData?.order_status?.status_text === "Kvalitetssikring" && (
                            <SoftButton
                              variant="outlined"
                              color="dark"
                              fullWidth
                              size="small"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              {orderData?.order_status?.status_pay_text}
                            </SoftButton>
                          )}

                          {orderData?.order_status?.status_text === "Kansellert" && (
                            <SoftButton
                              variant="outlined"
                              color="error"
                              fullWidth
                              size="small"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              {orderData?.order_status?.status_pay_text}
                            </SoftButton>
                          )}

                          {orderData?.order_status?.status_text === "Mislyktes" && (
                            <SoftButton
                              variant="outlined"
                              color="error"
                              fullWidth
                              size="small"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              {orderData?.order_status?.status_pay_text}
                            </SoftButton>
                          )}

                          {orderData?.order_status?.status_text === "Utkast" && (
                            <SoftButton
                              variant="outlined"
                              color="warning"
                              fullWidth
                              size="small"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              {orderData?.order_status?.status_pay_text}
                            </SoftButton>
                          )}


                          {orderData?.order_status?.status_text === "På vent" && (
                            <SoftButton
                              variant="outlined"
                              color="info"
                              fullWidth
                              size="small"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              {orderData?.order_status?.status_pay_text}
                            </SoftButton>
                          )}

                          {["Venter-leverandør", "Behandler", "Levert", "Behandles"].includes(orderData?.order_status?.status_text) &&
                            orderData?.order_status?.status_invoice && (
                            
                              <a
                                href={orderData?.order_status?.status_invoice}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                              >
                                <SoftButton
                                  variant="gradient"
                                  color="info"
                                  fullWidth
                                  size="small"
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    padding: "8px",
                                  }}
                                >
                                  Kvittering
                                </SoftButton>
                              </a>
                            
                          )}

                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </SoftBox>
            )}


            <Dialog open={openStatusModal} onClose={handleCloseStatusModal} maxWidth="sm" fullWidth >
              <DialogTitle>Velg status</DialogTitle>
              <DialogContent>
                {/* Add a label above the dropdown */}
                
               
                <Select
                  value={selectedStatus || ''} // Default to empty string if vehicle_id is undefined
                  onChange={handleStatusChange}
                  fullWidth
                  displayEmpty 
                  input={<OutlinedInput onClick={handleInputClick1} />}
                  sx={{
                    height: "40px !important",
                    cursor: "pointer",
                  }}
                  
                  ref={selectRef}
                  open={open1}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen1(!open1);
                  }}
                  
                >
                  {/* Placeholder Option */}
                  <MenuItem value="" disabled>
                    Velg statustype
                  </MenuItem>
                  {/* Vehicle Type Options */}
                  {statusList && statusList.length > 0 ? (
                    statusList.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.label}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">Ingen statuser tilgjengelige</MenuItem>  // Fallback when no status is available
                  )}
                </Select>
            
              </DialogContent>
              <DialogActions sx={{ margin: 1 }}>
                <SoftButton onClick={handleCloseStatusModal} variant="outlined" color="error">Lukk</SoftButton>
                <SoftButton onClick={handleConfirmStatusChange} variant="gradient" color="info">Lagre</SoftButton>
              </DialogActions>
            </Dialog>

            <Dialog open={openVendorModal} onClose={handleCloseVendorModal} maxWidth="sm" fullWidth>
              <DialogTitle>Velg leverandør</DialogTitle>
              <DialogContent>
                {/* Add a label above the dropdown */}
                
               
                <Select
                  value={selectedVendor || ''} 
                  onChange={handleVendorChange}
                  fullWidth
                  displayEmpty
                  input={<OutlinedInput onClick={handleInputClick2} />}
                  sx={{
                    height: "40px !important",
                    cursor: "pointer",
                  }}
                  
                  ref={selectRef}
                  open={open2}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen2(!open2);
                  }} 
                  
                >
                  {/* Placeholder Option */}
                  <MenuItem value="" disabled>
                    Velg leverandørtype
                  </MenuItem>
                  {/* Vehicle Type Options */}
                  {vendorList && vendorList.length > 0 ? (
                    vendorList.map((vendor) => (
                      <MenuItem key={vendor.id} value={vendor.id}>
                        {vendor.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">Ingen leverandører tilgjengelige</MenuItem>  // Fallback when no vendor is available
                  )}
                </Select>
            
              </DialogContent>

              <DialogActions sx={{ margin: 1 }}>
                <SoftButton onClick={handleCloseVendorModal} variant="outlined" color="error">Lukk</SoftButton>
                <SoftButton onClick={handleConfirmVendorChange} variant="gradient" color="info">Lagre</SoftButton>
              </DialogActions>
          
            </Dialog>



            

            <SoftBox mt={4} mb={3}>
              <Grid container spacing={3}>
                
                <Grid item xs={12} md={12} xl={4}>
                  <Card
                    sx={{
                      borderRadius: "12px",
                      padding: 3,
                      background: "linear-gradient(135deg,#bbd2c5, #536976)", // Gradient background
                      color: "white",
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                      flexDirection: "column",
                      height: "100%", // Force equal height
                    }}
                  >
                    {/* Title */}
                    <SoftTypography
                      variant="h5"
                      fontWeight="bold"
                      mb={3}
                      color="white"
                      textAlign="left"
                    >
                      Kundeinformasjon
                    </SoftTypography>

                    {/* Content */}
                    <Grid container spacing={1} alignItems="center">
                      {/* Name */}
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <AccountCircleIcon sx={{ fontSize: 24, color: "#fff" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <SoftTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{ fontSize: "14px", color: "#fff" }}
                        >
                          {orderData?.fullname}
                        </SoftTypography>
                      </Grid>

                      {/* Phone */}
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <PhoneIcon sx={{ fontSize: 24, color: "#fff" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <SoftTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{ fontSize: "14px", color: "#fff" }}
                        >
                          {orderData?.phone ? orderData.phone : "N/A"}
                        </SoftTypography>
                      </Grid>

                      {/* Email */}
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <EmailIcon sx={{ fontSize: 24, color: "#fff" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <SoftTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{ fontSize: "14px", color: "#fff" }}
                        >
                          {orderData?.email ? orderData.email : "N/A"}
                        </SoftTypography>
                      </Grid>

                      

                      {/* Address */}
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <LocationOnIcon sx={{ fontSize: 24, color: "#fff" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <SoftTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{ fontSize: "14px", color: "#fff" }}
                        >
                          {orderData?.address}
                        </SoftTypography>
                      </Grid>
                      
                    </Grid>
                  </Card>
                </Grid>
                {/* 2nd Box: Prosecutor Information */}
                {["admin", "customer"].includes(role) && (
                  <Grid item xs={12} md={12} xl={4}>
                    <Card
                      sx={{
                        borderRadius: "12px",
                        padding: 3,
                        background: "linear-gradient(135deg, #4cb8c4, #3cd3ad)", // Gradient Background
                        color: "white",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                        flexDirection: "column",
                        height: "100%", // Force equal height
                      }}
                    >
                      {/* Title */}
                      <SoftTypography
                        variant="h5"
                        fontWeight="bold"
                        mb={3}
                        color="white"
                        textAlign="left"
                      >
                        Aktorinformasjon
                      </SoftTypography>

                      {/* Content */}
                      <Grid container spacing={1} alignItems="center">
                        {/* Name */}
                        <Grid item xs={2} display="flex" justifyContent="center">
                          <AccountCircleIcon sx={{ fontSize: 24, color: "#fff" }} />
                        </Grid>
                        <Grid item xs={10}>
                          <SoftTypography
                            variant="body2"
                            fontWeight="medium"
                            sx={{ fontSize: "14px", color: "#fff" }}
                          >
                            {orderData?.vendor_title}
                          </SoftTypography>
                        </Grid>

                        {/* Email */}
                        <Grid item xs={2} display="flex" justifyContent="center">
                          <EmailIcon sx={{ fontSize: 24, color: "#fff" }} />
                        </Grid>
                        <Grid item xs={10}>
                          <SoftTypography
                            variant="body2"
                            fontWeight="medium"
                            sx={{ fontSize: "14px", color: "#fff" }}
                          >
                            {orderData?.vendor_email ? orderData.vendor_email : "N/A"}
                          </SoftTypography>
                        </Grid>

                        {/* Location */}
                        <Grid item xs={2} display="flex" justifyContent="center">
                          <LocationOnIcon sx={{ fontSize: 24, color: "#fff" }} />
                        </Grid>
                        <Grid item xs={10}>
                          <SoftTypography
                            variant="body2"
                            fontWeight="medium"
                            sx={{ fontSize: "14px", color: "#fff" }}
                          >
                            {orderData?.vendor_address}
                          </SoftTypography>
                        </Grid>

                        {/* Distance */}
                        <Grid item xs={2} display="flex" justifyContent="center">
                          <DistanceIcon sx={{ fontSize: 24, color: "#fff" }} />
                        </Grid>
                        <Grid item xs={10}>
                          <SoftTypography
                            variant="body2"
                            fontWeight="medium"
                            sx={{ fontSize: "14px", color: "#fff" }}
                          >
                            {orderData?.distance} km
                          </SoftTypography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                )}
                {/* 3rd Box: Project Details */}
                <Grid item xs={12} md={12} xl={4}>
                  <Card
                    sx={{
                      borderRadius: "12px",
                      padding: 3,
                      background: "linear-gradient(135deg, #B0C4DE, #6A85B6)", // Gradient Background
                      color: "white",
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                      flexDirection: "column",
                      height: "100%", // Force equal height
                    }}
                  >
                    {/* Title */}
                    <SoftTypography
                      variant="h5"
                      fontWeight="bold"
                      mb={3}
                      color="white"
                      textAlign="left"
                    >
                      Prosjektdetaljer
                    </SoftTypography>

                    {/* Content */}
                    <Grid container spacing={1} alignItems="center">
                      {/* Order Title */}
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <WorkIcon sx={{ fontSize: 24, color: "#fff" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <SoftTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{ fontSize: "14px", color: "#fff" }}
                        >
                          {orderData?.order_title}
                        </SoftTypography>
                      </Grid>

                      {/* Created Date */}
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <EventIcon sx={{ fontSize: 24, color: "#fff" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <SoftTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{ fontSize: "14px", color: "#fff" }}
                        >
                          {orderData?.created_date?.date
                            ? new Date(orderData.created_date.date).toLocaleDateString("nb-NO", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })
                            : "N/A"}
                        </SoftTypography>
                      </Grid>

                      {/* Status */}
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <VerifiedIcon sx={{ fontSize: 20, color: "#fff" }} />
                      </Grid>
                      <Grid item xs={10} display="flex" alignItems="center">
                        <SoftTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{
                            fontSize: "12px",            
                            backgroundColor: "#4CAF50",  
                            color: "#fff",             
                            fontWeight: "medium",
                            padding: "2px 8px",          
                            borderRadius: "10px",        
                            textAlign: "center",
                            display: "inline-block",  
                          }}
                        >
                          {orderData?.order_status?.status_text}
                        </SoftTypography>
                      </Grid>

                      {/* Description */}
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <DescriptionIcon sx={{ fontSize: 24, color: "#fff" }} />
                      </Grid>
                      <Grid item xs={10}>
                        <SoftTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{ fontSize: "14px", color: "#fff" }}
                        >
                          {orderData?.order_status?.status_description}
                        </SoftTypography>
                      </Grid>

                      {/* Order Total */}
                      {role === "admin" && (
                        <>
                          {/* Order Total */}
                          <Grid item xs={2} display="flex" justifyContent="center">
                            <LocalAtmIcon sx={{ fontSize: 24, color: "#fff" }} /> {/* Money Icon */}
                          </Grid>
                          <Grid item xs={10}>
                            {!isEditing ? (
                              <>
                                <SoftBox display="flex" justifyContent="start" alignItems="center" gap="10px">
                                  <SoftTypography variant="body2" fontWeight="medium" sx={{ fontSize: "14px", color: "#fff" }}>
                                    kr {orderData?.order_total || 0}
                                  </SoftTypography>
                                  <SoftButton
                                    onClick={handleEditClick}
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                      backgroundColor: "#fff", // White background
                                      color: "rgba(0, 0, 0, 0.87)", // Black text color
                                      padding: "0px 0px",
                                      minWidth: "45px", // Corrected camelCase for min-width
                                      minHeight: "25px", // Corrected camelCase for min-width
                                      "&:hover": {
                                        backgroundColor: "#fff", // Slightly darker shade on hover
                                      },
                                    }}
                                  >
                                    <EditIcon sx={{ fontSize: "24px" }} /> {/* Corrected icon font size */}
                                  </SoftButton>

                                </SoftBox>

                                {/* Modal for editing the order total */}
                                <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                                  <DialogTitle>Oppdater bestillingens totalbelø</DialogTitle>
                                  <DialogContent>
                                    <TextField
                                      type="text"
                                      value={tempOrderTotal}
                                      onChange={(e) => setTempOrderTotal(e.target.value)}
                                      fullWidth
                                    />
                                  </DialogContent>
                                  <DialogActions sx={{ justifyContent: "flex-end", gap: "5px", paddingRight: "20px" }}>
                                    <SoftButton
                                      onClick={() => {
                                        setTempOrderTotal(orderData?.order_total || 0); // Reset the value
                                        setIsModalOpen(false); // Close the modal
                                      }}
                                      variant="outlined"
                                      color="error"
                                      size="small"
                                      sx={{ padding: "0px", fontSize: "10px" }}
                                    >
                                      Avbryt
                                    </SoftButton>
                                    <SoftButton
                                      onClick={() => {
                                        handleSaveClick(); // Save the updated total
                                        setIsModalOpen(false); // Close the modal
                                      }}
                                      variant="gradient"
                                      color="info"
                                      size="small"
                                      sx={{ padding: "0px", fontSize: "10px" }}
                                    >
                                      Lagre
                                    </SoftButton>
                                  </DialogActions>
                                </Dialog>
                              </>
                            ) : (
                              <SoftTypography variant="body2" fontWeight="medium" sx={{ fontSize: "14px", color: "#fff" }}>
                                kr {orderData?.order_total || 0}
                              </SoftTypography>
                            )}
                          </Grid>
                        </>
                      )}


                    </Grid>
                  </Card>
                </Grid>
                {["vendor"].includes(role) && (
                  <Grid item xs={12} md={12} xl={4}>
                    <Card
                      sx={{
                        borderRadius: "12px",
                        padding: 3,
                        background: "linear-gradient(135deg, #4cb8c4, #3cd3ad)", // Gradient Background
                        color: "white",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                        textAlign: "center",
                        flexDirection: "column",
                        height: "100%", // Force equal height
                      }}
                    >
                      {/* Title */}
                      <Grid item xs={12}>
                        <SoftTypography
                          variant="h5"
                          fontWeight="bold"
                          mb={1}
                          color="white"
                        >
                          {orderData?.order_title}
                        </SoftTypography>
                      </Grid>

                      {/* Status */}
                      <Grid item xs={12} mb={1}>
                        <SoftTypography
                          variant="h4"
                          fontWeight="bold"
                          sx={{
                            fontSize: "22px",
                            fontWeight: "medium",
                            padding: "5px 15px",
                            backgroundColor: "white",
                            color: "rgba(0, 0, 0, 0.87)",
                            borderRadius: "20px",
                            textAlign: "center",
                            display: "inline-block",
                            position: "relative",
                            "&::before": {
                              content: '"-> "',
                              position: "absolute",
                              left: "-10px",
                              color: "rgba(0, 0, 0, 0.87)",
                              fontWeight: "bold",
                            },
                            "&::after": {
                              content: '" <-"',
                              position: "absolute",
                              right: "-10px",
                              color: "rgba(0, 0, 0, 0.87)",
                              fontWeight: "bold",
                            },
                          }}
                        >
                          {orderData?.order_status?.status_text}
                        </SoftTypography>
                      </Grid>

                      {/* Action Buttons */}
                      <Grid container spacing={1}>
                      {isVendorOwnOrder ? (
                        <Grid item xs={12}>
                          <SoftButton
                            variant="gradient"
                            color="success"
                            fullWidth
                            size="small"
                            disabled
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              padding: "8px",
                            }}
                          >
                            Allerede godkjent
                          </SoftButton>
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <SoftButton
                            variant="gradient"
                            color="success"
                            fullWidth
                            size="small"
                            onClick={openConfirmModal} // Open modal instead of directly approving
                            disabled={isAlreadyApproved}
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              padding: "8px",
                            }}
                          >
                            {isAlreadyApproved ? "Allerede godkjent" : "Godkjenn"}
                          </SoftButton>
                        </Grid>
                      )}
                        <Grid item xs={12}>
                          {!isDateApproved && !isDateDeny && (
                            <SoftButton
                              variant="gradient"
                              color="info"
                              fullWidth
                              size="small"
                              onClick={openChangeRequestModal} // Function to handle change request
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              Endringsforespørsel
                            </SoftButton>
                          )}

                          {isDateApproved && (
                            <SoftButton
                              variant="gradient"
                              color="info"
                              fullWidth
                              size="small"
                              disabled
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              Dato godkjent
                            </SoftButton>
                          )}

                          {isDateDeny && (
                            <SoftButton
                              variant="gradient"
                              color="info"
                              fullWidth
                              size="small"
                              disabled
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                padding: "8px",
                              }}
                            >
                              Dato er avvist
                            </SoftButton>
                            
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <SoftButton
                            variant="gradient"
                            color="error"
                            fullWidth
                            size="small"
                            onClick={() => openCancelDialog(orderData?.order_number)} // Function to handle cancel request
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              padding: "8px",
                            }}
                          >
                            Kanseller forespørsel
                          </SoftButton>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                )}


                <Dialog open={confirmModalOpen} onClose={closeConfirmModal}>
                  <DialogTitle>Bekreft Godkjenning</DialogTitle>
                  <DialogContent>
                    Er du sikker på at du vil godkjenne denne bestillingen?
                  </DialogContent>
                  <DialogActions sx={{ margin: 1 }}>
                    <SoftButton onClick={closeConfirmModal} variant="outlined" color="error">
                      Avbryt
                    </SoftButton>
                    <SoftButton onClick={confirmApproval} variant="gradient" color="info">
                      Bekreft Godkjenning
                    </SoftButton>
                  </DialogActions>
                </Dialog>

                <Dialog open={cancelDialogOpen} onClose={closeCancelDialog}>
                  <DialogTitle>Bekreft kansellering av forespørsel</DialogTitle>
                  <DialogContent>
                      Er du sikker på at du vil kansellere denne forespørselen?
                  </DialogContent>
                  <DialogActions sx={{ margin: 1 }}>
                    <SoftButton onClick={closeCancelDialog} variant="outlined" color="error">Lukk</SoftButton>
                    <SoftButton onClick={confirmCancelRequest} variant="gradient" color="info">Bekreft</SoftButton>
                  </DialogActions>
                </Dialog>

            
                <Dialog 
                  open={open} 
                  maxWidth="md" // Increase modal width
                  fullWidth
                  sx={{
                    "& .MuiDialog-paper": {
                      padding: "25px", // Adds more spacing inside the modal
                      borderRadius: "10px", // Soft rounded corners
                      maxWidth: "500px", // Sets max width
                    },
                  }}
                >
                  <DialogTitle mb={1}>Oppgi endringsdetaljer</DialogTitle>
                  <DialogContent  sx={{ paddingTop: "12px!important" }} >
                    {/* Date Field */}
                     <SoftInput      
                      label="Select Date"
                      size="large"
                      type="date"
                      value={changeDate}
                      onChange={(e) => setChangeDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      icon={{
                          component: (
                              <Box sx={{ cursor: "pointer" }}>
                                  <TodayIcon sx={{ color: "#006bb7" }} />
                              </Box>
                          ),
                          direction: "right",
                          
                      }}
                    />

                    {/* Time Field */}
                    
                    <Select
                      value={changeTime}
                      onChange={(e) => setChangeTime(e.target.value)}
                      displayEmpty
                      input={<OutlinedInput />}
                      ref={selectRef}
                      open={open3}
                      onOpen={() => {
                        if (!changeDate || [0, 6].includes(new Date(changeDate).getDay())) return; // Prevent opening if date is invalid
                        setOpen3(true);
                      }}
                      onClose={() => setOpen3(false)}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!changeDate || [0, 6].includes(new Date(changeDate).getDay())) return; // Prevent click toggle
                        setOpen3(!open3);
                      }}
                      disabled={!changeDate || [0, 6].includes(new Date(changeDate).getDay())} // Fully disable Select
                      sx={{
                        width: "100%",
                        cursor: "pointer",
                        borderRadius: "6px",
                        bgcolor: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mt: 2, // Margin-bottom added
                        mb: 2, // Margin-bottom added
                        "& .MuiSelect-select": {
                          display: "flex",
                          alignItems: "center",
                          padding: "12px 16px",
                          fontSize: "16px",
                        },
                      }}
                      renderValue={(selected) =>
                        selected ? (
                            <Typography sx={{ color: "#495057", fontSize: "16px", display: "flex", alignItems: "center", padding: "2px 0px" }}>
                              {selected}
                            </Typography>
                          
                        ) : (
                          <Typography sx={{ color: "#495057", fontSize: "16px", display: "flex", alignItems: "center", padding: "2px 0px" }}>
                            Velg Tid
                          </Typography>
                        )
                      }
                      IconComponent={() => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            right: 10,
                            width: 28,
                            height: 28,
                            fontSize: "21px",
                          }}
                        >
                          <AccessTimeFilledIcon
                            sx={{ color: "#006bb7", cursor: changeDate && ![0, 6].includes(new Date(changeDate).getDay()) ? "pointer" : "not-allowed" }}
                            onClick={() => {
                              if (!changeDate || [0, 6].includes(new Date(changeDate).getDay())) return;
                              setOpen3(!open3);
                            }}
                          />
                        </Box>
                      )}
                    >
                      {timeOptions.map((time) => (
                        <MenuItem
                          key={time}
                          value={time}
                          sx={{
                            fontSize: "16px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          {time}
                        </MenuItem>
                      ))}
                    </Select>

                    {/* Change Detail Field */}
                    
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Endringsdetalj"
                      multiline
                      rows={4}
                      value={changeDetail}
                      onChange={(e) => setChangeDetail(e.target.value)} 
                      sx={{
                        mb: 2,
                        "& .MuiOutlinedInput-root": {
                          padding: "10px", 
                        },
                        "& textarea": {
                          width: "100% !important", 
                        }
                      }}
                    />

                  </DialogContent>
                  <DialogActions sx={{ margin: 1 }}>
                    <SoftButton onClick={closeChangeRequestModal} variant="outlined" color="error">Lukk</SoftButton>
                    <SoftButton onClick={() => handleChangeRequest(orderId)} variant="gradient" color="info">Send</SoftButton>
                  </DialogActions>
                  
                </Dialog>
 


              </Grid>
            </SoftBox>
            {orderData?.deliveries?.length > 0 && (
              <ProjectAccordion 
                data={orderData?.deliveries} 
                statusText={orderData?.order_status?.status_text}
              />
            )}

            {orderData?.attached_images?.length > 0 && (
              <SoftBox mt={4} mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} xl={12}>
                    <Card
                      sx={{
                        borderRadius: "12px",
                        padding: 3,
                        background: "#fff",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <SoftTypography variant="h6" mb={1} px={1}>
                        Uploaded Files
                      </SoftTypography>

                      {orderData.attached_images.map((file, index) => (
                        <Box
                          key={file.ID}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            background: "#fff",
                            padding: "10px 16px",
                            borderRadius: "8px",
                            boxShadow: 4,
                            mb: 1,
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            <FileIcon sx={{ fontSize: 24, color: "#1976D2",  mr: 1 ,ml: 1}} />
                            <SoftTypography variant="body1" sx={{ fontSize: 14, color: "#344767"}}>{file.post_title}</SoftTypography>
                          </Box>

                          <IconButton component="a" target="_blank" href={file.guid} download sx={{ color: "green" }}>
                            <DownloadIcon />
                          </IconButton>
                        </Box>
                      ))}
                    </Card>
                  </Grid>
                </Grid>
              </SoftBox>
            )}

          </>
        )}
      </Box>
    </DashboardLayout>
  );
}

export default OrderDetail;
