import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function Footer() {
  return (
    <SoftBox
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ color: "#000000", fontSize: { xs: "14px", sm: "14px", md: "14px", xxl: "20px" } }}
    >
      <SoftTypography variant="button" fontWeight="medium" sx={{ color: "#000000", fontSize: { xs: "14px", sm: "14px", md: "14px", xxl: "20px" } }}>
        Vernet etter Åndsverkloven © {new Date().getFullYear()}
      </SoftTypography>
      <SoftTypography variant="button" fontWeight="medium" mx={0.5} sx={{ color: "#000000", fontSize: { xs: "14px", sm: "14px", md: "14px", xxl: "20px" } }}>
        | Betongsentrum AS - 921743432 MVA
      </SoftTypography>
      <SoftTypography variant="button" fontWeight="medium" mx={0.5} sx={{ color: "#000000", fontSize: { xs: "14px", sm: "14px", md: "14px", xxl: "20px" } }}>
        | BMAX™ Ordresystem
      </SoftTypography>
      
    </SoftBox>
  );
}

Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
