import React, { useState, useRef } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SoftTypography from "components/SoftTypography";
import InputAdornment from '@mui/material/InputAdornment';
import SoftButton from "components/SoftButton";
import CircularProgress from "@mui/material/CircularProgress"; // Importing CircularProgress for loader
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Select,
  MenuItem,
  Grid,
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle,
  Typography,
  OutlinedInput,
} from "@mui/material";
import SoftBox from "components/SoftBox";

import { getVehicleTypes, getConcretePresets, updateOrder, deleteDelivery  } from "api/apiService"; // Import the new API

const EditProjectAccordion = ({ data, orderNumber, onSave }) => {
  const selectRef = useRef(null);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [concretePresets, setConcretePresets] = useState([]);
  const [editableData, setEditableData] = useState(data);
  const [loading, setLoading] = useState(false);  // Loader state
  const [successMessage, setSuccessMessage] = useState("");  // Success message state
  const [openModal, setOpenModal] = useState(false);
  const [deliveryToDelete, setDeliveryToDelete] = useState(null);  // Store the delivery ID to delete
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [dateErrors, setDateErrors] = useState({});
  const [timeErrors, setTimeErrors] = useState({});
  const [openIndex, setOpenIndex] = useState(null);
  console.log("editableData response:", editableData);
  console.log("concretePresets response:", concretePresets);

  // Trigger cancel button to open confirmation modal
  const handleCancelClick = async  (index) => {
    setDeliveryToDelete(index + 1);  // Set delivery ID to be deleted
    setOpenModal(true);  // Open confirmation modal
  };

  // Call API to delete the delivery on confirmation
  const handleConfirmDelete = async () => {
    setLoading(true); // Start loader
    setOpenModal(false);  // Close the modal
    try {
      const response = await deleteDelivery(orderNumber, deliveryToDelete);  // Pass orderId and deliveryId to the API
      console.log("deleting delivery response:", response);
      setLoading(false);
      if (response.status === 'success') {
        alert("Levering slettet vellykket!");
        
        setLoading(false); // Start loader
        window.location.reload();  // Optionally, reload the page after successful deletion
      }
    } catch (error) {
      setLoading(false); // Start loader
      console.error("Error deleting delivery:", error);
      alert("Feil ved sletting av levering.");
    }
  };

  // Close the modal if user cancels the deletion
  const handleCancelDelete = () => {
    setOpenModal(false);  // Close the modal without deleting
  };


  useEffect(() => {
    const getVehicleData = async () => {
      const vehicleTypesData = await getVehicleTypes(); // Fetch vehicle types from API
      setVehicleTypes(vehicleTypesData.results || []); // Assuming the vehicle types are in 'results'
    };

    getVehicleData();
  }, []);

  useEffect(() => {
  const fetchConcreteData = async () => {
    const concreteData = await getConcretePresets(); // Fetch concrete presets
    console.log('Initial concrete presets:', concreteData.results);
    let presets = concreteData.results || [];

    // Iterate over editableData to find entries with concrete_id 10
    const itemsWithId10 = editableData.filter(item => item.concrete_id === 10);

    itemsWithId10.forEach(item => {
      const foundPreset = presets.find(preset => preset.id === '10');
      if (foundPreset) {
        // If found, modify the existing preset
        presets = presets.map(preset => {
          if (preset.id === '10') {
            console.log('Before modification:', preset);
            return {
              ...preset,
              id: '10',
              full_name: item.concrete_type  // Set full name from item
            };
          }
          return preset;
        });
      } else {
        // If not found, add a new preset based on item details
        const newPreset = {
          id: '10',
          name: '', // Add appropriate default name if needed
          full_name: item.concrete_type
        };
        presets.push(newPreset);
        console.log('Added new preset:', newPreset);
      }
    });

    setConcretePresets(presets);
    console.log('Updated concrete presets:', presets);
  };

  fetchConcreteData();
}, [editableData]); // React on editableData changes


  const formatDateForInput = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
    const handleAddDelivery = () => {
    const newDelivery = {
      isNew: true, // Flag for new delivery
      arrival: "",
      time: "",
      vehicle_id: "",
      display_volume: 1,
      concrete_id: "",
      total_price: 0,
      decline: "",
      steel_fiber: "",
      retardation: "",
      accelerator: "",
      pump_wash: "",
      extra_volume: "",
      add_hose: "",
    };
    const newEditableData = [...editableData, newDelivery];
    setEditableData(newEditableData);
    setExpandedIndex(newEditableData.length - 1); // Expand the newly added delivery
  };
  const handleToggleAccordion = (index) => {
    setExpandedIndex((prev) => (prev === index ? null : index)); // Toggle expanded state
  };

  const handleRemoveNewDelivery = (index) => {
    const updatedData = [...editableData];
    updatedData.splice(index, 1); // Remove the delivery at the given index
    setEditableData(updatedData);
  };


  const currentDate = new Date().toISOString().split("T")[0];
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 180);
  const formattedMaxDate = maxDate.toISOString().split("T")[0];

  const handleChangeDate = (index, dateValue) => {
    handleDateValidation(dateValue, index);
    const updatedData = [...editableData];
    updatedData[index].date = Math.floor(new Date(dateValue).getTime() / 1000);
    setEditableData(updatedData);
  };

  const handleDateValidation = (selectedDate, index) => {
    const selectedDay = new Date(selectedDate).getDay();
    let errorMessage = "";
    if (selectedDate < currentDate || selectedDate > formattedMaxDate) {
      errorMessage = `Vennligst velg en dato mellom ${currentDate} og ${formattedMaxDate}.`;
    }
    if (selectedDay === 0 || selectedDay === 6) {
      errorMessage = "Helger (lørdag og søndag) er ikke tillatt.";
    }
    setDateErrors((prev) => ({ ...prev, [index]: errorMessage }));
  };

  // const handleChangeTime = (index, timeValue) => {
  //   handleTimeValidation(timeValue, index, editableData[index].date);
  //   const updatedData = [...editableData];
  //   updatedData[index].time = timeValue;
  //   setEditableData(updatedData);
  // };

  const handleChangeTime = (index, timeValue) => {
    handleTimeValidation(timeValue, index, editableData[index].date);
    const updatedData = [...editableData];
    updatedData[index].time = timeValue;
    setEditableData(updatedData);
    setOpenIndex(null); // Close dropdown after selection
  };

  const timeOptions = Array.from({ length: 10 }, (_, i) => {
    const hour = (i + 6).toString().padStart(2, "0"); // Generates "06" to "15"
    return `${hour}:00`;
  });

  const handleTimeValidation = (timeValue, index, selectedDate) => {
    const [hour, minutes] = timeValue.split(":" ).map(Number);
    let error = "";
    const now = new Date();
    const today = new Date().toISOString().split("T")[0];
    const selectedDateString = new Date(selectedDate * 1000).toISOString().split("T")[0];
    if (hour < 6 || hour > 15) {
      error = "Tid må være mellom 06:00AM og 3:00PM.";
    } else if (minutes !== 0) {
      error = "Tid må være i hele timer (f.eks. 08:00).";
    }
    if (selectedDateString === today) {
      const currentTime = now.getHours() * 60 + now.getMinutes();
      const selectedTime = hour * 60;
      if (selectedTime <= currentTime) {
        error = "Du kan ikke velge en tidligere tid for i dag.";
      }
    }
    setTimeErrors((prev) => ({ ...prev, [index]: error }));
  };

  const isFormValid = () => {
    return !Object.values(dateErrors).some(Boolean) && !Object.values(timeErrors).some(Boolean);
  };

  const handleVehicleTypeChange = (index, vehicleId) => {
    const updatedData = [...editableData];

    // Update the vehicle type
    updatedData[index].vehicle_id = vehicleId;

    // Find the selected vehicle's max load and set it as the maximum volume
    const selectedVehicle = vehicleTypes.find((vehicle) => vehicle.id === vehicleId);
    if (selectedVehicle) {
      updatedData[index].max_load = selectedVehicle.max_load;  // Store max load in the current item

      // If the volume is greater than max load, reset it to max load
      if (updatedData[index].display_volume > selectedVehicle.max_load) {
        updatedData[index].display_volume = selectedVehicle.max_load;
      }
    } else {
      updatedData[index].display_volume = 1; // Clear volume if no vehicle is selected
    }

    setEditableData(updatedData);  // Update the state
  };

  // Ensure the volume value is set correctly based on max_load on first render
  useEffect(() => {
    const updatedData = [...editableData];
    updatedData.forEach((item, index) => {
      const selectedVehicle = vehicleTypes.find((vehicle) => vehicle.id === item.vehicle_id);
      if (selectedVehicle) {
        item.max_load = selectedVehicle.max_load;
        // Ensure the volume doesn't exceed the max load
        if (item.display_volume > item.max_load) {
          item.display_volume = item.max_load;
        }
      }
    });
    setEditableData(updatedData);
  }, [vehicleTypes]);  // Trigger this effect when vehicleTypes are fetched or updated


  const handleChange = (index, field, value) => {
    const updatedData = [...editableData];
    updatedData[index][field] = value;
    setEditableData(updatedData);
  };

  const handleSave = async  (index) => {
    // Mapping concrete quality names to IDs
    setLoading(true); // Start loader
    const item = editableData[index]; // Get the data for the current index

    const addHose = item.add_hose === "checked";
    const hoseLength = item.hose_length;

    // Map selected vehicle type name to the vehicle ID using the vehicleTypes array
    const vehicle = vehicleTypes.find(v => v.id === item.vehicle_id);
    const vehicleTypeId = vehicle ? vehicle.id : 0;  // Default to 0 if not found

    // const concrete = concretePresets.find((c) => c.id === item.concrete_id);
    const concreteId = item.concrete_id ? item.concrete_id : 0; // Default to 0 if not found

    // Convert the date to UNIX timestamp (in seconds)
    const unixTimestamp = item.date; 

    // Prepare the data to be saved
    const savedData = {
      order_id: orderNumber, 
      deliveryId: index + 1,
      date: unixTimestamp, // Convert to readable date
      time: item.time,
      volume: parseFloat(item.display_volume),
      selectAreaLater: false,
      selectVehicleLater: false,
      vehicleType: vehicleTypeId, // Use the vehicle ID from the dropdown
      concreteIsCustom: false,
      customConcreteType: item.concrete_type === "custom" ? item.custom_concrete : null,
      concrete: concreteId,
      decline: item.decline === "checked",
      retardation: item.retardation === "checked",
      steelFiber: item.steel_fiber === "checked",
      accelerator: item.accelerator === "checked",
      pumpWash: item.pump_wash === "checked",
      pumploss: false, // Default value if not present
      extraVolume: item.extra_volume === "checked",
      addHose: addHose,
      hoseLength: addHose ? item.hose_length : 0, // Increment hoseLength if add_hose is checked
      price: 0,
      origianl_price: 0,
      isNew: undefined, // Remove the isNew flag
    };

    console.log("Saved Data:", savedData);
    // Call the onSave callback to pass the saved data
    // onSave(savedData);

    try {
      const response = await updateOrder(savedData);
      console.log("Order updated:", response);
      setLoading(false); // Stop loader

      if (response.status === 'success') {
        // Show success message in an alert
        alert("Bestillingen ble oppdatert vellykket!");

        // Optionally, you can reload the page after 2 seconds to reflect changes
        setTimeout(() => {
          window.location.reload(); // Reload the page after success message
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating order:", error);
      setLoading(false); // Stop loader

      // Show error message in an alert
      alert("Feil ved oppdatering av bestillingen.");
    }

  };


  return (
    <SoftBox mt={5}>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={100} color="info" />
        </div>
      )}
      <Dialog open={openModal} onClose={handleCancelDelete}>
        <DialogTitle>Bekreft sletting</DialogTitle>
        <DialogContent>
          Er du sikker på at du vil slette denne leveringen?
        </DialogContent>
        <DialogActions sx={{ margin: 1 }}>
          <SoftButton onClick={handleCancelDelete} variant="outlined" color="error">Avbryt</SoftButton>
          <SoftButton onClick={handleConfirmDelete} variant="gradient" color="info">Bekreft</SoftButton>
        </DialogActions>
      </Dialog>
      <SoftTypography variant="h2" mb={2} px={1}>
        Leveringsdetaljer
      </SoftTypography>
      {editableData.length > 0 ? (
        editableData.map((item, index) => (
          <Accordion
            key={index}
            expanded={expandedIndex === index} // Expand the accordion if the index matches
            onChange={() => handleToggleAccordion(index)} // Handle toggle
            sx={{
              borderRadius: "16px !important",
              "&::before": { content: "none" },
              mb: 2,
              py: 2,
              px: 1,
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <SoftTypography variant="h6">Levering # {index + 1}</SoftTypography>
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ padding: "0.75rem 0.45rem !important"}}>
                      <SoftTypography variant="caption" fontWeight="bold" > Ankomst</SoftTypography>
                      <TextField
                        type="date"
                        value={formatDateForInput(item.date)}
                        onChange={(e) => handleChangeDate(index, e.target.value)}
                        fullWidth 
                        error={!!dateErrors[index]}
                        helperText={dateErrors[index]}
                      />
                    </TableCell>
                    {/*
                    <TableCell sx={{ padding: "0.75rem 0.45rem !important"}}>
                      <SoftTypography variant="caption" fontWeight="bold"> Tid</SoftTypography>
                      <TextField
                        type="time"
                        value={item.time}
                        onChange={(e) => handleChangeTime(index, e.target.value)}
                        fullWidth
                        error={!!timeErrors[index]}
                        helperText={timeErrors[index]}
                      />
                    </TableCell>
                    */} 

                    <TableCell sx={{ padding: "0.75rem 0.45rem !important"}}>
                      <SoftTypography variant="caption" fontWeight="bold"> Tid </SoftTypography>
                      <Select
                        value={item.time}
                        onChange={(e) => handleChangeTime(index, e.target.value)}
                        displayEmpty
                        ref={selectRef}
                        input={<OutlinedInput />}
                        open={openIndex === index} // Controls dropdown open state
                        onOpen={() => !dateErrors[index] && item.date && setOpenIndex(index)} // Open only if date is valid
                        onClose={() => setOpenIndex(null)}
                        sx={{
                          width: "80%",
                          borderRadius: "6px",
                          bgcolor: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          "& .MuiSelect-select": {
                            display: "flex",
                            alignItems: "center",
                            padding: "0px!important",
                            fontSize: "14px",
                            textAlign: "center",
                          },
                        }}
                        disabled={dateErrors[index] || !item.date} // Disable dropdown if date is invalid
                        renderValue={(selected) =>
                          selected ? (
                            <Typography sx={{ color: "#495057", fontSize: "14px", display: "flex", alignItems: "center", padding: "0px!important",}}>
                              {selected}
                            </Typography>
                          ) : (
                            <Typography sx={{ color: "#495057", fontSize: "14px", display: "flex", alignItems: "center", padding: "0px!important",}}>
                              Velg Tid
                            </Typography>
                          )
                        }
                      >
                        {timeOptions.map((time) => (
                          <MenuItem
                            key={time}
                            value={time}
                            sx={{
                              fontSize: "14px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                            onClick={() => setOpenIndex(null)} // Close dropdown after selection
                          >
                            {time}
                          </MenuItem>
                        ))}
                      </Select>

                      {timeErrors[index] && (
                        <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
                          {timeErrors[index]}
                        </Typography>
                      )}
                    </TableCell>


                    <TableCell sx={{ padding: "0.75rem 0.45rem !important"}}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Kjøretøytype
                      </SoftTypography>
                      <Select
                        value={item.vehicle_id || ''} 
                        onChange={(e) => handleVehicleTypeChange(index, e.target.value)}
                        fullWidth
                        displayEmpty
                      >
                        {/* Placeholder Option */}
                        <MenuItem value="" disabled>
                          Velg kjøretøytype
                        </MenuItem>
                        {/* Vehicle Type Options */}
                        {vehicleTypes.map((vehicle) => (
                          <MenuItem key={vehicle.id} value={vehicle.id}>
                            {vehicle.name}
                          </MenuItem>
                        ))}
                      </Select>

                    </TableCell>
                    <TableCell style={{ width: "88px" }} sx={{ padding: "0.75rem 0.45rem !important"}}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Volum (m³)
                      </SoftTypography>
                      <TextField
                        type="number"
                        value={
                          item.display_volume
                            ? Number.isInteger(parseFloat(String(item.display_volume).replace(',', '.'))) 
                              ? parseInt(String(item.display_volume).replace(',', '.')) 
                              : parseFloat(String(item.display_volume).replace(',', '.'))
                            : 1
                        }
                        onChange={(e) => {
                          const newVolume = parseFloat(e.target.value);
                          if (newVolume > 0 && newVolume <= item.max_load) {
                            handleChange(index, "display_volume", newVolume);
                          } else {
                            handleChange(index, "display_volume", item.max_load);
                          }
                        }}
                        style={{ width: "100px !important"}}  // Right padding to input area
                        inputProps={{
                          min: 1,
                          max: item.max_load,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" style={{ marginRight: "-24px" }}>
                              <span style={{ display: "flex", alignItems: "center", paddingRight: "5px" }}>
                                {/* Optionally, you can place any additional elements here */}
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </TableCell>


                    
                    <TableCell sx={{ padding: "0.75rem 0.45rem !important"}}>
                      <SoftTypography variant="caption" fontWeight="bold"> Betongkvalitet</SoftTypography>
                      <Select
                        value={item.concrete_id || ""} // Default to empty string if concrete_id is undefined
                        onChange={(e) => handleChange(index, "concrete_id", e.target.value)}
                        fullWidth
                        displayEmpty
                      >
                        {/* Placeholder Option */}
                        <MenuItem value="" disabled>
                          Velg betongtype
                        </MenuItem>
                        {/* Concrete Options */}
                        {concretePresets.map((concrete) => (
                          <MenuItem key={concrete.id} value={concrete.id}>
                            {concrete.full_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell sx={{ padding: "0.75rem 0.45rem !important", width: "100px",}}>
                      <SoftTypography variant="caption" fontWeight="bold"> Totalpris</SoftTypography>
                      <TextField
                        value={item.total_price || 0} // Default to 0 if undefined
                        onChange={(e) => handleChange(index, "total_price", e.target.value)}
                        InputProps={{
                          readOnly: true, // Makes the field non-editable (disabled)
                        }}
                        sx={{
                          width: "130px", // Adjust width as needed
                        }}
                      />
                    </TableCell>

                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={6}>
                      <SoftTypography fontSize=".8rem" variant="subtitle" color="secondary" sx={{ mt: 2, mb: 1 }}>
                        Modellalternativer
                      </SoftTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.decline === "checked"}
                                onChange={(e) => handleChange(index, "decline", e.target.checked ? "checked" : "")}
                              />
                            }
                            label="Støp med fall"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.steel_fiber === "checked"}
                                onChange={(e) => handleChange(index, "steel_fiber", e.target.checked ? "checked" : "")}
                              />
                            }
                            label="Legg til stålfiber"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.retardation === "checked"}
                                onChange={(e) => handleChange(index, "retardation", e.target.checked ? "checked" : "")}
                              />
                            }
                            label="Forsinkelse"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.accelerator === "checked"}
                                onChange={(e) => handleChange(index, "accelerator", e.target.checked ? "checked" : "")}
                              />
                            }
                            label="Akselerator"
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={6}>
                      <SoftTypography fontSize=".8rem" variant="subtitle" color="secondary" sx={{ mt: 2, mb: 1 }}>
                        Hvis pumpeblander
                      </SoftTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.pump_wash === "checked"}
                                onChange={(e) => handleChange(index, "pump_wash", e.target.checked ? "checked" : "")}
                              />
                            }
                            label="Legg til pumpeavfall (0,5m³)"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.extra_volume === "checked"}
                                onChange={(e) => handleChange(index, "extra_volume", e.target.checked ? "checked" : "")}
                              />
                            }
                            label="Vask på mikser"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.add_hose === "checked"}
                                onChange={(e) => handleChange(index, "add_hose", e.target.checked ? "checked" : "")}
                              />
                            }
                            label="Slange"
                          />
                        </Grid>
                        {item.add_hose === "checked" && (
                          <Grid item xs={3}>
                            <TextField
                              label="Hvor mange meter?"
                              type="text"
                              value={
                                item.hose_length !== undefined && item.hose_length !== null
                                  ? String(item.hose_length).replace(",", ".") // Ensures decimal format is always `.` instead of `,`
                                  : "0"
                              }
                              onChange={(e) => {
                                let newValue = e.target.value.replace(",", "."); // Replace `,` with `.`
                                
                                if (!isNaN(newValue) || newValue === "") {
                                  handleChange(index, "hose_length", newValue);
                                }
                              }}
                              variant="outlined"
                              InputLabelProps={{
                                style: { color: "#344767" }, // Always black color for the label
                              }}
                              InputProps={{
                                style: { color: "#344767" }, // Ensures the input text is always black
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {/* Optionally add an icon or text here if needed */}
                                  </InputAdornment>
                                ),
                              }}
                              onFocus={(e) => (e.target.style.color = "#344767")}
                              onBlur={(e) => (e.target.style.color = "#344767")}
                            />
                          </Grid>

                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

            
              <SoftBox mt={2}>
                <SoftButton variant="gradient" color="info" disabled={!isFormValid()} onClick={() => handleSave(index)} sx={{ mt: 2 }}>
                  Lagre
                </SoftButton>

                {item.isNew ? (
                  <SoftButton
                    variant="outlined"
                    color="error"
                    onClick={() => handleRemoveNewDelivery(index)}
                    sx={{ mt: 2, ml: 2 }}
                  >
                    Fjern
                  </SoftButton>
                ) : (
                  <SoftButton
                    variant="outlined"
                    color="error"
                    onClick={() => handleCancelClick(index)}
                    sx={{ mt: 2, ml: 2 }}
                  >
                    Slett
                  </SoftButton>
                )}
              </SoftBox>


            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <SoftTypography>Ingen leveringer tilgjengelige</SoftTypography>  // Fallback when there are no deliveries
      )}
      <SoftButton variant="gradient" color="info" onClick={handleAddDelivery} sx={{ mt: 2 }}>
        Legg til levering
      </SoftButton>
    </SoftBox>
  );
};

EditProjectAccordion.propTypes = {
  data: PropTypes.array.isRequired,
  orderNumber: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditProjectAccordion;
