import React, { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import { fetchWeatherData } from "../../../../components/DashboardSearchAddress/weatherUtils";

const DashboardWeatherCard = () => {
  const [weather, setWeather] = useState({
    city: "Fetching Location...",
    temperature: "--",
    condition: "Loading...",
    iconUrl: "",
  });

  const fetchLocationFromGeolocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          console.log("Geolocation - Latitude:", lat, "Longitude:", lon);

          // Fetch city name using OpenStreetMap (Nominatim)
          fetchLocationFromOpenStreetMap(lat, lon);
        },
        (error) => {
          console.error("Geolocation error:", error);
          console.log("Falling back to IP location...");
          fetchLocationFromIP(); // Fallback if geolocation fails
        }
      );
    } else {
      console.log("Geolocation not supported, using IP-based location...");
      fetchLocationFromIP();
    }
  };

  const fetchLocationFromOpenStreetMap = async (lat, lon) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`
      );
      const data = await response.json();
      console.log("OpenStreetMap Geolocation:", data);

      if (data.address) {
        const city =
          data.address.city ||
          data.address.town ||
          data.address.subdistrict ||
          data.address.village ||
          data.address.hamlet ||
          data.address.district ||  // ✅ Use district if city is missing
          data.address.municipality ||  // ✅ Use municipality if district is missing
          data.address.suburb ||  // ✅ Use suburb if municipality is missing
          "Unknown Location";
        console.log("Detected City (OpenStreetMap):", city);
        fetchWeather(city, lat, lon);
      } else {
        console.log("OpenStreetMap failed, falling back to IP-based location...");
        fetchLocationFromIP();
      }
    } catch (error) {
      console.error("OpenStreetMap error:", error);
      console.log("Falling back to IP location...");
      fetchLocationFromIP();
    }
  };

  const fetchLocationFromIP = async () => {
    try {
      const ipToken = "69e575ef18ae1f"; // Store in .env
      const ipResponse = await fetch(`https://ipinfo.io/json?token=${ipToken}`);
      const ipData = await ipResponse.json();
      console.log("IP-based Location:", ipData);

      if (ipData.city) {
        console.log("Detected City (IP-based):", ipData.city);

        // Extract Latitude and Longitude from "loc" field
        const [lat, lon] = ipData.loc.split(",");

        // Fetch Weather Data
        fetchWeather(ipData.city, lat, lon);
      } else {
        console.error("IP-based location failed:", ipData);
        setWeather({
          city: "Location Unavailable",
          condition: "Weather Unavailable",
          temperature: "--",
        });
      }
    } catch (error) {
      console.error("IP-based location error:", error);
      setWeather({
        city: "Location Error",
        condition: "Weather Unavailable",
        temperature: "--",
      });
    }
  };

  const fetchWeather = async (city, lat, lon) => {
    const data = await fetchWeatherData(lat, lon);
    console.log("Fetched Weather Data:", data);

    if (data && data.length > 0) {
      const latestWeather = data[0]; // Adjust according to API response
      const temp = Math.round(latestWeather.data.instant.details.air_temperature);
      const conditionCode = latestWeather.data.next_1_hours.summary.symbol_code;

      // Construct the Met.no weather icon URL dynamically
      const iconUrl = `https://api.met.no/images/weathericons/svg/${conditionCode}.svg`;

      setWeather({
        city: city, // Use detected city name
        temperature: `${temp}°C`,
        condition: conditionCode.replace("_", " "),
        iconUrl,
      });
    } else {
      setWeather({
        city: city,
        condition: "Weather Data Unavailable",
        temperature: "--",
      });
    }
  };

  useEffect(() => {
    fetchLocationFromGeolocation(); // Fetch location & weather initially

    const interval = setInterval(() => {
      console.log(`🔄 Refreshing location & weather... [Time: ${new Date().toLocaleTimeString()}]`);
      fetchLocationFromGeolocation(); // Refresh location & weather every 5 minutes
    }, 300000); // 300,000 ms = 5 minutes
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <Card
      sx={{
        p: { xs: 3, sm: 3, md: "10px", xxl: 2 },
        borderRadius: "12px",
        background: "linear-gradient(to right, #6ec3f4, #378fe6, #1e64e2)",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <SoftBox width={{ xs: "480px", sm: "480px", md: "480px", xxl:"600px" }} display="flex" alignItems="center" justifyContent="space-between">
        <SoftBox>
          <SoftTypography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.9)", paddingLeft:"5px"}}>
            Vær i dag
          </SoftTypography>
          <SoftTypography variant="h4" fontWeight="bold" color="white" sx={{paddingLeft:"5px", fontSize: { xs: "14px", sm: "16px", md: "18px", xxl:"28px" } }}>
            {weather.city} - {weather.temperature}
          </SoftTypography>
        </SoftBox>

        <SoftBox>
          {weather.iconUrl && (
            <Avatar
              src={weather.iconUrl}
              alt="Weather Icon"
              sx={{
                width: { xs: 50, sm: 42, md: 40, xxl:50 }, // ✅ Smaller icons for small screens
                height: { xs: 50, sm: 42, md: 40, xxl:50 },
                position: "absolute",
                right: { xs: 14, sm: 14, md: 18, xxl:22 },
                top: { xs: 14, sm: 14, md: 8, xxl:16 },
              }}
            />
          )}
          <SoftTypography variant="body1" sx={{paddingRight:{ md:"10px", xxl:"3px" }, color: "rgba(255, 255, 255, 0.95)", fontSize: { xs: "14px", sm: "16px", md: "16px", xxl:"28px" } }} mt={4.5} >
            {weather.condition
              .split(" ")
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </Card>
  );
};

export default DashboardWeatherCard;
