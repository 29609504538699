/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React examples
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavCard from "examples/Sidenav/SidenavCard";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import { profile } from 'api/apiService';  // Import the API function

// Soft UI Dashboard React context
import { useSoftUIController, setMiniSidenav } from "context";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  // 🔵 State to Store User Profile
  const [user, setUser] = useState({ name: "", email: "" });

  // ✅ Fetch User Profile on Mount
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user")); // Retrieve user details
        const userId = user?.id; // Safely access user ID
        console.log("userId:", userId);

        if (!userId) {
          console.warn("No user ID found in localStorage.");
          return; // Exit if no user ID
        }

        console.log("Fetching profile for userId:", userId);

        const response = await profile("profile", {}, userId);
        console.log("User profile response:", response);

        // 🔹 Extract user data from `data_info`
        const profileData = response?.data_info;

        if (profileData) {
          const {
            first_name = "Unknown",
            last_name = "",
            email = "No email provided",
          } = profileData;

          setUser({
            name: `${first_name} ${last_name}`.trim(), // ✅ Fix: Using first & last name
            email,
          });

          console.log("Updated User Data:", { name: `${first_name} ${last_name}`.trim(), email });
        } else {
          console.warn("Invalid user profile response:", response);
        }
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
      }
    };

    fetchUserProfile();
  }, []); // ✅ Runs only once when component mounts




  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href }) => {
    let returnValue;

    // if(localStorage.getItem("role") == "vendor"){
    //   const routesToShow = ["/profile","/login","/projects","/dashboard","/tables","/logout" ]
    //   if(!routesToShow.includes(route)){
        
    //     return;
    //   }
      
    // }
    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            color={color}
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink to={route} key={key}>
          <SidenavCollapse
            color={color}
            key={key}
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <SoftTypography
          key={key}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          opacity={0.6}
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </SoftTypography>
      );
    } else if (type === "divider") {
      returnValue = <Divider key={key} />;
    }

    return returnValue;
  });

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <SoftBox pt={3} pb={1} px={4} textAlign="center" >
        <SoftBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SoftTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SoftTypography>
        </SoftBox>
        <SoftBox 
          component={NavLink} 
          to="/" 
          display="flex" 
          alignItems="center"
          justifyContent="center" // Ensure proper alignment
          sx={{
            width: "100%", 
            height: "60px", 
            padding: 0,  // ✅ Remove padding
            margin: "-12px 0px",    // ✅ Remove margin if any extra space exists
          }}
        >
          {brand && (
            <SoftBox 
              component="img" 
              src={brand} 
              alt="Soft UI Logo" 
              width="100%" 
              height="100%" 
              sx={{
                objectFit: "contain",  // ✅ Prevent unwanted stretching
                padding: 0,            // ✅ Ensure no padding inside the image container
                margin: 0
              }}
            />
          )}
        </SoftBox>

      </SoftBox>
      <Divider />
      <List>{renderRoutes}</List>
      <SoftBox pt={2} my={2} mx={2} mt="auto">
        <SidenavCard />
        <SoftBox mt={1}>
        </SoftBox>
      </SoftBox>
      {/* ✅ User Name & Email (Above Vendor Logo) */}
      
      <SoftBox mt="auto" textAlign="center" pt={-3}>
        <SoftTypography variant="caption" fontWeight="bold" color="white" display="block">
          {user.name || "Bruker"}
        </SoftTypography>
        <SoftTypography variant="caption" color="white" display="block" >
          {user.email || "Ingen e-post"}
        </SoftTypography>
        <SoftBox component="img" src="/betongsentrum.png" alt="Betongsentrum Logo" width="70%" height="40px"/>
      </SoftBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
