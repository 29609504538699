import React from "react";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SoftTypography from "components/SoftTypography";
import { Table, TableBody, TableCell, TableRow, Checkbox, FormControlLabel } from "@mui/material";
import SoftBox from "components/SoftBox";
import typography from "assets/theme/base/typography";


 
const ProjectAccordion = ({ data, statusText }) => {
  const role = localStorage.getItem("role");
  console.log("role", role);
  console.log("statusText", statusText);

  console.log("projectAccordion;", data);
  return (
    <SoftBox mt={5}>
      <SoftTypography variant="h2" mb={2} px={1}>
        Leveringsdetaljer
      </SoftTypography>
      {data.map((item, index) => (
        <Accordion
          mb={2}
          key={index}
          sx={{
            borderRadius: "16px !important",
            "&::before": {
              content: "none",
            },
            mb: 2,
            py: 4,
            px: 1,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${item?.projectname?.replace(/\s+/g, "")}-content`}
            // id={`${item.projectname.replace(/\s+/g, '')}-header`}
            sx={{ content: "none" }}
          >
            <SoftTypography variant="h6">Levering # {index+1}</SoftTypography>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableRow>
                <TableCell>
                  <SoftTypography
                    variant="subtitle"
                    fontSize=".7rem"
                    color="secondary"
                    fontWeight="bold"
                    textTransform={"uppercase"}
                  >
                    Ankomst
                  </SoftTypography>
                </TableCell>
                <TableCell>
                  <SoftTypography
                    variant="subtitle"
                    fontSize=".7rem"
                    color="secondary"
                    fontWeight="bold"
                    textTransform={"uppercase"}
                  >
                    Tid
                  </SoftTypography>
                </TableCell>
                <TableCell>
                  <SoftTypography
                    variant="subtitle"
                    fontSize=".7rem"
                    color="secondary"
                    fontWeight="bold"
                    textTransform={"uppercase"}
                  >
                    Kjøretøytype
                  </SoftTypography>
                </TableCell>
                <TableCell>
                  <SoftTypography
                    variant="subtitle"
                    fontSize=".7rem"
                    color="secondary"
                    fontWeight="bold"
                    textTransform={"uppercase"}
                  >
                    Volum (m³)
                  </SoftTypography>
                </TableCell>
               
                <TableCell>
                  <SoftTypography
                    variant="subtitle"
                    fontSize=".7rem"
                    color="secondary"
                    fontWeight="bold"
                    textTransform={"uppercase"}
                  >
                    Betongkvalitet
                  </SoftTypography>
                </TableCell>
                <TableCell>
                  <SoftTypography
                    variant="subtitle"
                    fontSize=".7rem"
                    color="secondary"
                    fontWeight="bold"
                    textTransform={"uppercase"}
                  >
                    {" "}
                    Totalt
                  </SoftTypography>
                </TableCell>
              </TableRow>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <SoftTypography
                      fontSize=".8rem"
                      variant="subtitle"
                      fontWeight="bold"
                      color="secondary"
                    > 
                      {role === "customer" &&
                        (statusText === "Kvalitetssikring" || 
                         statusText === "Venter på betaling" || 
                         statusText === "Behandler" ||
                         statusText === "Venter-leverandør" ||
                         statusText === "På vent")
                          ? "Dato som planlegges"
                          : item.date
                            ? new Date(item.date * 1000).toLocaleDateString("nb-NO", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric"
                              })
                            : "Dato som planlegges"
                        }
                    </SoftTypography>
                  </TableCell>
                  <TableCell> 
                    <SoftTypography
                      fontSize=".8rem"
                      variant="subtitle"
                      fontWeight="bold"
                      color="secondary"
                    >
                      {role === "customer" && 
                        (statusText === "Kvalitetssikring" || 
                         statusText === "Venter på betaling" || 
                         statusText === "Behandler" ||
                         statusText === "Venter-leverandør" ||
                         statusText === "På vent")
                      ? "Tid som planlegges"
                      : item.time || "Tid som planlegges"}
                    </SoftTypography>
                  </TableCell>
                  
                  <TableCell>
                    <SoftTypography
                      fontSize=".8rem"
                      variant="subtitle"
                      fontWeight="bold"
                      color="secondary"
                    >
                      {item?.vehicle_type}
                    </SoftTypography>
                  </TableCell>
                  <TableCell>
                    <SoftTypography
                      fontSize=".8rem"
                      variant="subtitle"
                      fontWeight="bold"
                      color="secondary"
                    >
                      {item?.display_volume
                      ? `${Number.isInteger(parseFloat(item.display_volume))
                          ? item.display_volume
                          : parseFloat(item.display_volume).toFixed(2)}m³`
                      : "0m³"}
                    </SoftTypography>
                  </TableCell>
                  <TableCell>
                    <SoftTypography
                      fontSize=".8rem"
                      variant="subtitle"
                      fontWeight="bold"
                      color="secondary"
                    >
                      {item?.concrete_type}
                    </SoftTypography>
                  </TableCell>
                  <TableCell>
                    <SoftTypography
                      fontSize=".8rem"
                      variant="subtitle"
                      fontWeight="bold"
                      color="secondary"
                    >
                      {item?.total_price}
                    </SoftTypography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5}>
                    <SoftTypography fontSize=".8rem" variant="subtitle" color="secondary">
                      Modellalternativer
                    </SoftTypography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="castWithDrop"
                          checked={item?.decline === "checked" ? true : false}
                        />
                      }
                      label={
                        <SoftTypography
                          fontSize=".8rem"
                          variant="subtitle"
                          sx={{ color: "secondary.main" }}
                        >
                          Støp med fall
                        </SoftTypography>
                      }
                      sx={{ color: "secondary" }}
                    />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="addSteelFiber"
                          checked={item?.steel_fiber === "checked" ? true : false}
                        />
                      }
                      label={
                        <SoftTypography
                          fontSize=".8rem"
                          variant="subtitle"
                          sx={{ color: "secondary.main" }}
                        >
                          Legg til stålfiber
                        </SoftTypography>
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="delay"
                          checked={item?.retardation === "checked" ? true : false}
                        />
                      }
                      label={
                        <SoftTypography
                          fontSize=".8rem"
                          variant="subtitle"
                          sx={{ color: "secondary.main" }}
                        >
                          Forsinkelse
                        </SoftTypography>
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="accelerator"
                          checked={item?.accelerator === "checked" ? true : false}
                        />
                      }
                      label={
                        <SoftTypography
                          fontSize=".8rem"
                          variant="subtitle"
                          color="secondary"
                          sx={{ color: "secondary.main" }}
                        >
                          Akselerator
                        </SoftTypography>
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow sx={{ border: "none !important" }}>
                  <TableCell colSpan={5}>
                    <SoftTypography fontSize=".8rem" variant="subtitle" color="secondary">
                      Hvis pumpeblander
                    </SoftTypography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="addPumpWaste"
                          checked={item?.pump_wash === "checked" ? true : false}
                        />
                      }
                      label={
                        <SoftTypography
                          fontSize=".8rem"
                          variant="subtitle"
                          color="secondary"
                          sx={{ color: "secondary.main" }}
                        >
                          Legg til pumpeavfall (0,5m³)
                        </SoftTypography>
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="washOnMixer"
                          checked={item?.extra_volume === "checked" ? true : false}
                        />
                      }
                      label={
                        <SoftTypography
                          fontSize=".8rem"
                          variant="subtitle"
                          color="secondary"
                          sx={{ color: "secondary.main" }}
                        >
                          Vask på mikser
                        </SoftTypography>
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="snakes"
                          checked={item?.add_hose === "checked" ? true : false}
                        />
                      }
                      label={
                        <SoftTypography
                          fontSize=".8rem"
                          variant="subtitle"
                          color="secondary"
                          sx={{ color: "secondary.main" }}
                        >
                          Slange : {item.hose_length ? `${item.hose_length}m` : "0m"}
                        </SoftTypography>
                      }
                    />
                  </TableCell>
                  
                </TableRow>
              </TableBody>
            </Table>

            {/* <SoftTypography variant="body2" mt={2} mb={1}><strong>Order No:</strong> {item.orderNo}</SoftTypography>
            {item.mobile && <SoftTypography mb={1} variant="body2"><strong>Mobile:</strong> {item.mobile}</SoftTypography>}
            {item.email && <SoftTypography mb={1} variant="body2"><strong>Email:</strong> {item.email}</SoftTypography>} */}
          </AccordionDetails>
        </Accordion>
      ))}
    </SoftBox>
  );
};

ProjectAccordion.propTypes = {
  data: PropTypes.array.isRequired,
  statusText: PropTypes.string.isRequired,
};

export default ProjectAccordion;
