import React, { useState,useRef } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  OutlinedInput,
} from "@mui/material";

import SoftButton from "components/SoftButton";

const CustomizedConcrete = ({ onAreaChange, onClose, data }) => {
  const { concrete_quality, concrete_stone, concrete_reduction, concrete_consistency } = data;
  const [concreteType, setConcreteType] = useState("");
  const [quality, setQuality] = useState("");
  const [stoneType, setStoneType] = useState("");
  const [stoneReduction, setStoneReduction] = useState("");
  const [consistency, setConsistency] = useState("");
  const selectRef = useRef(null);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [additionalChoices, setAdditionalChoices] = useState({
    lowCarbon: false,
    soilMoist: false,
    frostproof: false,
    livestockFarming: false,
  });
  const [errors, setErrors] = useState({});

  const handleAdditionalChoiceChange = (event) => {
    setAdditionalChoices({
      ...additionalChoices,
      [event.target.name]: event.target.checked,
    });
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!concreteType) tempErrors.concreteType = "Betongtype er påkrevd";
    if (!quality) tempErrors.quality = "Kvalitet er påkrevd";
    if (!stoneType) tempErrors.stoneType = "Steintype er påkrevd";
    if (!stoneReduction) tempErrors.stoneReduction = "Steinreduksjon er påkrevd";
    if (!consistency) tempErrors.consistency = "Konsistens er påkrevd";

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const handleInputClick = () => {
    selectRef.current && selectRef.current.click();
  };

  const handleInputClick1 = () => {
    setOpen1((prevOpen) => !prevOpen);
  };

  const handleClose1 = () => {
    setOpen1(true);
  };
  const handleInputClick2 = () => {
    setOpen2((prevOpen) => !prevOpen);
  };

  const handleClose2 = () => {
    setOpen2(true);
  };

   const handleInputClick3 = () => {
    setOpen3((prevOpen) => !prevOpen);
  };

  const handleClose3 = () => {
    setOpen3(true);
  };

   const handleInputClick4 = () => {
    setOpen4((prevOpen) => !prevOpen);
  };

  const handleClose4 = () => {
    setOpen4(true);
  };

  const handleSaveClick = () => {
    if (validateForm()) {
      // Assuming you have some mapping of names to IDs
      const qualityId = concrete_quality.find(q => q.name === quality)?.id || 0;
      const stoneId = concrete_stone.find(s => s.name === stoneType)?.id || 0;
      const reductionId = concrete_reduction.find(r => r.name === stoneReduction)?.id || 0;
      const consistencyId = concrete_consistency.find(c => c.name === consistency)?.id || 0;

      const selectedValues = [
        {
          id: 0,  // ID is 0 as an example, you would generate or fetch this appropriately
          name: concreteType,  // Example name, you might want this to be dynamic or user-input
          quality: qualityId.toString(),
          stone: stoneId.toString(),
          consistency: consistencyId.toString(),
          reduction: reductionId.toString(),
          lowCarbon: additionalChoices.lowCarbon,
          curbstone: additionalChoices.soilMoist,  // Assuming 'curbstone' is meant by 'soilMoist'
          frostProof: additionalChoices.frostproof,
          farming: additionalChoices.livestockFarming
        }
      ];

      console.log('selectedValues',selectedValues);
      onAreaChange(selectedValues);
      onClose();
    }
  };

  return (
    <div style={{
      overflowY: "scroll",
      maxHeight: "100vh",
      padding: "16px",
      scrollbarWidth: "none", /* Firefox */
      msOverflowStyle: "none"  /* IE and Edge */
    }}>
      <style>
        {`
          div::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
          }
        `}
      </style>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "#000",
              textAlign: "center",
              marginTop: "16px",
            }}
          >
            <span style={{ color: "#006bb7" }}>Tilpasset <span style={{ color: "#000" }}>betong</span>!</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ color: "#000", fontWeight: "bold" }}>
            Betongtype :
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Tilpasset betong"
            fullWidth
            sx={{ marginTop: "8px" }}
            value={concreteType}
            onChange={(e) => setConcreteType(e.target.value)}
            error={!!errors.concreteType}
            helperText={errors.concreteType}
            InputProps={{
              sx: {
                padding: '10px 14px !important', // Adjust padding to ensure text visibility
                '& input': {
                  width: 'calc(100% - 0px) !important', // Adjust input width to prevent text cutoff
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" sx={{ color: "#000", fontWeight: "bold" }}>
            KVALITET :
          </Typography>
          <FormControl sx={{ width: "100%" }}>
            <Select
              displayEmpty
              value={quality}
              onChange={(e) => setQuality(e.target.value)}
              input={<OutlinedInput onClick={handleInputClick1} />}
              sx={{
                height: "40px !important",
                cursor: "pointer",
              }}
              
              ref={selectRef}
              open={open1}
              onClose={handleClose1}
              onClick={(e) => {
                e.stopPropagation();
                setOpen1(!open1);
              }}
            >
              <MenuItem disabled value="">
                <Typography variant="body1" style={{ fontSize: "14px" }}>
                  <em>Velg Kvalitet </em>
                </Typography>
              </MenuItem>
              {concrete_quality.map((q) => (
                <MenuItem key={q.id} value={q.name}>{q.name}</MenuItem>
              ))}
            </Select>
            {!!errors.quality && <FormHelperText sx={{ color: 'error.main' }}>{errors.quality}</FormHelperText>}
          </FormControl>

        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" sx={{ color: "#000", fontWeight: "bold" }}>
            STEINTYPE :
          </Typography>
          <FormControl sx={{ width: "100%" }}>
            <Select
              displayEmpty
              value={stoneType}
              onChange={(e) => setStoneType(e.target.value)}
              input={<OutlinedInput onClick={handleInputClick2} />}
              sx={{
                height: "40px !important",
                cursor: "pointer",
              }}
              
              
              
              ref={selectRef}
              open={open2}
              onClose={handleClose2}
              onClick={(e) => {
                e.stopPropagation();
                setOpen2(!open2);
              }}
            >
              <MenuItem disabled value="">
                <Typography variant="body1" style={{ fontSize: "14px" }}>
                  <em>Velg Steintype </em>
                </Typography>
              </MenuItem>
              {concrete_stone.map((s) => (
                <MenuItem key={s.id} value={s.name}>{s.name}</MenuItem>
              ))}
            </Select>
            {!!errors.stoneType && <FormHelperText sx={{ color: 'error.main' }}>{errors.stoneType}</FormHelperText>}
          </FormControl>

          
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6" sx={{ color: "#000", fontWeight: "bold" }}>
            STEINREDUKSJON :
          </Typography>

          <FormControl sx={{ width: "100%" }}>
            <Select
              displayEmpty
              value={stoneReduction}
              onChange={(e) => setStoneReduction(e.target.value)}
              input={<OutlinedInput onClick={handleInputClick3} />}
              sx={{
                height: "40px !important",
                cursor: "pointer",
              }}
              
              
              
              ref={selectRef}
              open={open3}
              onClose={handleClose3}
              onClick={(e) => {
                e.stopPropagation();
                setOpen3(!open3);
              }}
            >
              <MenuItem disabled value="">
                <Typography variant="body1" style={{ fontSize: "14px" }}>
                  <em>Velg Reduksjon </em>
                </Typography>
              </MenuItem>
              {concrete_reduction.map((r) => (
                <MenuItem key={r.id} value={r.name}>{r.name}</MenuItem>
              ))}
            </Select>
            {!!errors.stoneReduction && <FormHelperText sx={{ color: 'error.main' }}>{errors.stoneReduction}</FormHelperText>}
          </FormControl>

        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" sx={{ color: "#000", fontWeight: "bold" }}>
            KONSISTENS :
          </Typography>

          <FormControl sx={{ width: "100%" }}>
            <Select
              displayEmpty
              value={consistency}
              onChange={(e) => setConsistency(e.target.value)}
              input={<OutlinedInput onClick={handleInputClick4} />}
              sx={{
                height: "40px !important",
                cursor: "pointer",
              }}
              
              
              
              ref={selectRef}
              open={open4}
              onClose={handleClose4}
              onClick={(e) => {
                e.stopPropagation();
                setOpen4(!open4);
              }}
            >
              <MenuItem disabled value="">
                <Typography variant="body1" style={{ fontSize: "14px" }}>
                  <em>Velg Konsistens </em>
                </Typography>
              </MenuItem>
              {concrete_consistency.map((c) => (
                <MenuItem key={c.id} value={c.name}>{c.name}</MenuItem>
              ))}
            </Select>
            {!!errors.consistency && <FormHelperText sx={{ color: 'error.main' }}>{errors.consistency}</FormHelperText>}
          </FormControl>

          
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ color: "#000", fontWeight: "bold" }}>
            Tilleggsvalg:
          </Typography>
          <Grid container mx={2}>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox checked={additionalChoices.lowCarbon} onChange={handleAdditionalChoiceChange} name="lowCarbon" />}
                label="Lavkarbon"
              />
              <FormControlLabel
                control={<Checkbox checked={additionalChoices.soilMoist} onChange={handleAdditionalChoiceChange} name="soilMoist" />}
                label="Jordfuktighet"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox checked={additionalChoices.frostproof} onChange={handleAdditionalChoiceChange} name="frostproof" />}
                label="Frostsikker"
              />
              <FormControlLabel
                control={<Checkbox checked={additionalChoices.livestockFarming} onChange={handleAdditionalChoiceChange} name="livestockFarming" />}
                label="Husdyrbruk"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={2} mx={1}>
          <Grid item xs={6}>
            <SoftButton
              variant="outlined"
              color="info"
              fullWidth
              sx={{
                height: "45px",
                borderRadius: "7px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={onClose}
            >
              LUKK
            </SoftButton>
          </Grid>
          <Grid item xs={6}>
            <SoftButton
              variant="contained"
              color="info"
              fullWidth
              sx={{
                height: "45px",
                borderRadius: "7px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleSaveClick}
            >
              LAGRE
            </SoftButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

CustomizedConcrete.propTypes = {
  onAreaChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default CustomizedConcrete;
