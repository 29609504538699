import React, { useState, useEffect } from "react";
import { Box, Typography, Button, ButtonGroup, Tooltip } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { getOrders } from "api/apiService";
import moment from "moment-timezone";
import "moment/locale/nb"; // Import Norwegian locale
moment.locale("nb");


// 🎨 Status Colors
const statusColors = {
  "Venter på betaling": "#FFC107", // Yellow
  "Venter-leverandør": "#6c757d", // dark-grey
  Behandler: "#4CAF50", // Green
  Levert: "#2196F3", // Blue
  "Avventer leveranse": "#FF9800", // Orange
  Kvalitetssikring: "#1976D2", // Indigo
  Kansellert: "#F44336", // Red
  Mislyktes: "#F44336", // Red
  Utkast: "#607D8B", // Gray-Blue
  "På vent": "#009688", // Teal
};

// 📅 Function to Generate Weekly Dates
const getWeekDays = (startDate) => {
  const days = [];
  for (let i = 0; i < 7; i++) {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + i);

    // Convert to two-letter Norwegian day abbreviation
    const dayName = date.toLocaleDateString("nb-NO", { weekday: "short" }).slice(0, 2);
    const capitalizedDay = dayName.charAt(0).toUpperCase() + dayName.slice(1);

    // Ensure two-digit day number
    const dayNumber = date.getDate().toString().padStart(2, "0");

    days.push({
      date: date.toISOString().split("T")[0],
      label: `${capitalizedDay}. ${dayNumber}.`,
    });
  }
  return days;
};

// 📅 Format week range
const formatWeekRange = (startDate) => {
  const start = new Date(startDate);
  const end = new Date(startDate);
  end.setDate(start.getDate() + 6);

  const month = start.toLocaleDateString("nb-NO", { month: "long" });
  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);

  const startDay = start.getDate().toString().padStart(2, "0") + "."; // Add a period after the day
  const endDay = end.getDate().toString().padStart(2, "0") + "."; // Add a period after the day
  const year = start.getFullYear(); // Get the year from the start date

  return `${startDay} - ${endDay} ${capitalizedMonth} ${year}`;
};

const DashboardCalendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [calendarOrders, setCalendarOrders] = useState([]);
  const [activeButton, setActiveButton] = useState(null);
  console.log("currentDate:", currentDate);
  console.log("calendarOrders:", calendarOrders);

  const getCalendarOrders = async () => {
    try {
      const data = await getOrders({
        limit: 79,
        page_number: 1,
        additionalData: {
          filterStatus: ["Avventer leveranse"], // 🚀 Only fetch these statuses
        },
      });
      console.log("fetching getCalendarOrders:", data);
      if (data?.data && Array.isArray(data.data)) {
        const formattedOrders = data.data.map((order) => ({
          id: order.order_id,
          title: `Ordre #${order.order_id}`,
          date: moment(order.date, "DD.MM.YYYY").format("YYYY-MM-DD"),
          status: order.status,
          color: statusColors[order.status] || "#607D8B", // Default gray-blue if status not found
          details: {
            order_id: order.order_id,
            date: order.date,
            time: order.time,
            status: order.status,
            currency: order.currency,
            total: order.total,
            number_of_items: order.number_of_items,
            deliveries: `${order.number_of_items} Deliveries`,
          },
        }));
        setCalendarOrders(formattedOrders);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    getCalendarOrders(); // ✅ Fetch orders initially

    const interval = setInterval(() => {
      console.log(`🔄 Refreshing getCalendarOrders... [Time: ${new Date().toLocaleTimeString()}]`);
      getCalendarOrders(); // ✅ Fetch orders every 1 minute
    }, 60000); // 60,000 ms = 1 minute

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  // ✅ Set start of the week (Sunday)
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Always Sunday
  // Define `weekDays` using the corrected `startOfWeek`
  const weekDays = getWeekDays(startOfWeek);

  // ✅ Function to reset to current week (starting Sunday)
  const resetToCurrentWeek = () => {
    const today = new Date();
    today.setDate(today.getDate() - today.getDay()); // Start from Sunday
    setCurrentDate(today);
  };

  // ✅ Function to navigate weeks (ensures start from Sunday)
  const changeWeek = (direction) => {
    setActiveButton(direction);
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + direction * 7); // Move 1 week

    // Ensure the start of the week is Sunday
    newDate.setDate(newDate.getDate() - newDate.getDay());

    setCurrentDate(newDate);
    setTimeout(() => setActiveButton(null), 200);
  };


  return (
    <Box sx={{ background: "#fff", borderRadius: "8px", padding: "5px", height: { xs: "450px", sm: "440px", md: "370px", xxl:"460px" }, boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)"}}>
      {/* 🏷️ Header with Week Navigation */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={2} px={1}>
        <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "left", fontWeight: "bold"}}>
          {formatWeekRange(startOfWeek)}
        </Typography>
        <Box display="flex" gap={1}>
          <Button
            variant="contained"
            size="small"
            onClick={resetToCurrentWeek} // ✅ FIXED
            
            sx={{
                backgroundColor: activeButton === 1 ? "#1976D2" : "#6c757d",
                color: "#fff",
                fontSize: "12px",
                padding: "5px",
                borderRadius: "5px",
                transition: "background-color 0.2s ease-in-out",

                /* ✅ REMOVE Focus, Active, and Box Shadow Effects */
                outline: "none",
                boxShadow: "none",
                border: "none",

                "&:focus": {
                  outline: "none !important",
                  boxShadow: "none !important",
                  border: "none !important",
                  backgroundColor: "#6c757d !important", // Ensure no unwanted color change
                },
                "&:active": {
                  outline: "none !important",
                  boxShadow: "none !important",
                  border: "none !important",
                },
                "&:hover": {
                  backgroundColor: "#0056b3 !important", // Darker hover effect
                },
                "&:focus-visible": {
                  outline: "none !important",
                  boxShadow: "none !important",
                  border: "none !important",
                },
                "&:not(:hover)": {
                  backgroundColor: activeButton === 1 ? "#1976D2 !important" : "#6c757d !important",
                },
              }}
          >
            I dag
          </Button>
          <ButtonGroup variant="contained" size="small">
            <Button
              onClick={() => changeWeek(-1)}
              sx={{
                backgroundColor: activeButton === 1 ? "#1976D2" : "#6c757d",
                color: "#fff",
                padding: "5px",
                borderRadius: "5px 0px 0px 5px",
                transition: "background-color 0.2s ease-in-out",

                /* ✅ REMOVE Focus, Active, and Box Shadow Effects */
                outline: "none",
                boxShadow: "none",
                border: "none",

                "&:focus": {
                  outline: "none !important",
                  boxShadow: "none !important",
                  border: "none !important",
                  backgroundColor: "#6c757d !important", // Ensure no unwanted color change
                },
                "&:active": {
                  outline: "none !important",
                  boxShadow: "none !important",
                  border: "none !important",
                },
                "&:hover": {
                  backgroundColor: "#0056b3 !important", // Darker hover effect
                },
                "&:focus-visible": {
                  outline: "none !important",
                  boxShadow: "none !important",
                  border: "none !important",
                },
                "&:not(:hover)": {
                  backgroundColor: activeButton === 1 ? "#1976D2 !important" : "#6c757d !important",
                },
              }}
            >
              <ArrowBack fontSize="small" />
            </Button>
            <Button
              onClick={() => changeWeek(1)}
              sx={{
                backgroundColor: activeButton === 1 ? "#1976D2" : "#6c757d",
                color: "#fff",
                padding: "5px",
                borderRadius: "0px 5px 5px 0px",
                transition: "background-color 0.2s ease-in-out",

                /* ✅ REMOVE Focus, Active, and Box Shadow Effects */
                outline: "none",
                boxShadow: "none",
                border: "none",

                "&:focus": {
                  outline: "none !important",
                  boxShadow: "none !important",
                  border: "none !important",
                  backgroundColor: "#6c757d !important", // Ensure no unwanted color change
                },
                "&:active": {
                  outline: "none !important",
                  boxShadow: "none !important",
                  border: "none !important",
                },
                "&:hover": {
                  backgroundColor: "#0056b3 !important", // Darker hover effect
                },
                "&:focus-visible": {
                  outline: "none !important",
                  boxShadow: "none !important",
                  border: "none !important",
                },
                "&:not(:hover)": {
                  backgroundColor: activeButton === 1 ? "#1976D2 !important" : "#6c757d !important",
                },
              }}
            >
              <ArrowForward fontSize="small" />
            </Button>



          </ButtonGroup>
        </Box>
      </Box>

      {/* 📅 Calendar Grid */}
      <Box display="grid" gridTemplateColumns="repeat(7, 1fr)" gap={0} sx={{ height: { xs: "300px", sm: "340px", md: "300px", xxl:"385px" }, padding: "5px", overflow: "hidden" }}>
        {weekDays.map((day) => (
          <Box
            key={day.date}
            sx={{
              border: "1px solid #ddd",
              borderRadius: "2px",
              minHeight: "100px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              background: day.date === new Date().toISOString().split("T")[0] ? "#e0faff" : "transparent", // Highlight today
              border: day.date === new Date().toISOString().split("T")[0] ? "1px solid #87ddef" : "1px solid #ddd", // Thicker border for today
            }}
          >
            {/* 📅 Day Label */}
            <Box
              sx={{
                borderBottom: day.date === new Date().toISOString().split("T")[0] ? "1px solid #87ddef" : "2px solid #ddd",
                padding: "8px",
                textAlign: "center",
                fontWeight: "bold",

                background: day.date === new Date().toISOString().split("T")[0] ? "#e0faff" : "#f9f9f9", // Highlight today
              }}
            >
              <Typography variant="subtitle2" fontSize= {{ xs: "6px", sm: "10px", md: "12px", xxl:"14px" }}>{day.label}</Typography>
            </Box>

            {/* 📌 Events Section with Tooltip */}
            <Box 
              sx={{ 
                flexGrow: 1, 
                padding: "5px", 
                overflowY: "auto", 
                /* ✅ Ultra-Thin & Smooth Scrollbar */

                "&::-webkit-scrollbar": {
                  width: "5px", // **Super Thin Scrollbar**
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#aaa",
                  borderRadius: "8px", // Rounded Scrollbar
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1", // Light Track
                },
              }}
            >
              {calendarOrders
                .filter((event) => event.date === day.date)
                .map((event) => (
                  <Tooltip
                    key={event.id}
                    
                    title={
                      <Box sx={{ background: "#E3F2FD", padding: "8px", borderRadius: "5px", color: "#000", textAlign: "left"}}>
                        <Typography variant="subtitle2" sx={{ fontWeight: "bold", marginBottom: "5px", fontSize: "14px", color: "#374151"}}>
                          Bestillings info:
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: "200", fontSize: "14px", marginBottom: "2px" }}>
                          Bestillings-ID: {event.details.order_id}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: "200", fontSize: "14px", marginBottom: "2px" }}>
                          Dato: {event.details.date}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: "200", fontSize: "14px", marginBottom: "2px" }}>
                          Tid: {event.details.time}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: "200", fontSize: "14px", marginBottom: "2px" }}>
                          Status: {event.details.status}
                        </Typography>

                        <Typography variant="subtitle2" sx={{ fontWeight: "bold", marginTop: "5px", marginBottom: "5px", fontSize: "14px", color: "#374151"}}>
                          Antall varer:
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: "300", fontSize: "14px" }}>
                          {event.details.number_of_items} Leveranser
                        </Typography>
                      </Box>

                    }
                     arrow
                    componentsProps={{
                      tooltip: {
                        sx: { background: "#E3F2FD", boxShadow: "none", borderRadius: "6px", padding: "8px", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", }
                      }
                    }}
                  >
                    <Box
                      sx={{
                        background: event.color,
                        color: "#fff",
                        borderRadius: "4px",
                        padding: { xs: "3px", sm: "4px", md: "4px", xxl:"5px" },
                        fontSize: { xs: "6px", sm: "6px", md: "8px", xxl:"10px" },
                        textAlign: "center",
                        marginTop: "8px",
                      }}
                    >
                      {event.title}
                    </Box>
                  </Tooltip>
                ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default DashboardCalendar;
