import React from "react";
import PropTypes from 'prop-types';  // Import PropTypes
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Box from "@mui/material/Box";
import Badge from '@mui/material/Badge';  // Replace SoftBadge with MUI Badge
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SoftButton from "components/SoftButton";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SoftBadge from "components/SoftBadge";

function BasicMenu({ role, orderId, createdBy }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

// Checking if the user is a vendor and if they created the order themselves
const isVendorOwnOrder = role === 'vendor' && parseInt(createdBy) === parseInt(JSON.parse(localStorage.getItem("user")).id);

  const handleView = () => {
    navigate(`/projects/order-details/${orderId}`);
  };

  const handleEdit = () => {
    if (isVendorOwnOrder) {
      navigate(`/projects/edit-details/${orderId}`); // Navigate to the edit page
    }
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon fontSize="large" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* Vendor or Admin can view the order */}
        {["admin", "vendor", "customer"].includes(role) && (
          <MenuItem onClick={handleView}>Vis</MenuItem>
        )}
        {/* If it's the vendor's own order, they can edit */}
        {isVendorOwnOrder && (
          <MenuItem onClick={handleEdit}>Rediger</MenuItem>
        )}
        {["admin"].includes(role) && (
          <MenuItem onClick={handleEdit}>Rediger</MenuItem>
        )}

        {/* Only Vendor and Admin can request changes or cancel requests */}
        
        {/*
          {["admin", "vendor"].includes(role) && (
            <MenuItem onClick={handleClose}>Endre forespørsel</MenuItem>
          )}
        */}

        {/* Cancel Request for everyone */}
          
        {/*
          {["admin", "vendor", "customer"].includes(role) && (
            <MenuItem onClick={handleClose}>Avbryt forespørsel</MenuItem>
          )}
        */}

      </Menu>
    </div>
  );
}

BasicMenu.propTypes = {
  role: PropTypes.string.isRequired,
  orderId: PropTypes.number.isRequired,
  createdBy: PropTypes.number.isRequired,
};

const ProTableData = (data) => {
  const role = localStorage.getItem("role");
  const userId = parseInt(JSON.parse(localStorage.getItem("user")).id); // Assuming userId is stored in localStorage
  const rows = data.map((item) => ({
    bestillingsId: (
      <SoftTypography variant="caption" color="secondary">
        {item.order_id}
      </SoftTypography>
    ),
    bestillingsDato: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {item.date}
      </SoftTypography>
    ),
    status: (
      <SoftBadge
        variant="gradient"
        badgeContent={item.status}
        color="success"
        size="xs"
        container
      />
    ),
    total: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {item.currency} {item.total}
      </SoftTypography>
    ),
    totaleVarer: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {item.number_of_items}
      </SoftTypography>
    ),
    " ": (
      <SoftBox>
        <Box sx={{ display: "flex", gap: "10px", paddingRight: "50px" }}>
          <Box component={NavLink} to={`/projects/order-details/${item.order_id}`}>
            <SoftButton variant="outlined" color="info" size="small">
              Vis
            </SoftButton>
          </Box>
          {/* Check if the user is a vendor and the order is their own */}
          {role !== "customer" && ( // Vendor should not edit customer orders
              item.created_by === parseInt(userId) || role === "admin" ? (
                <Box component={NavLink} to={`/projects/edit-details/${item.order_id}`}>
                  <SoftButton variant="outlined" color="info" size="small">
                    Rediger
                  </SoftButton>
                </Box>
              ) : null
            )}
          <BasicMenu
            role={role}
            orderId={item.order_id}
            createdBy={item.created_by}  // Pass createdBy to check if it's the vendor's order
          />
        </Box>
      </SoftBox>
    ),
  }));

  const columns = [
    { name: "bestillingsId", align: "center" },
    { name: "bestillingsDato", align: "left" },
    { name: "status", align: "center" },
    { name: "total", align: "right" },
    { name: "totaleVarer", align: "right" },
    { name: " ", align: "right" },
  ];

  return { columns, rows };
};

ProTableData.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProTableData;
