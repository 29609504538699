import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { GoogleMap, Autocomplete, Marker, useJsApiLoader } from "@react-google-maps/api";
import { FormControlLabel, Checkbox, Grid, Box, Avatar, Typography, MenuItem, Select,OutlinedInput } from "@mui/material";
import SoftInput from "components/SoftInput";
import DefaultLayout from "layouts/authentication/components/DashboardDefaultLayout/DefaultLayout";
import SoftBox from "components/SoftBox";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ClearIcon from "@mui/icons-material/Clear";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";
import TodayIcon from "@mui/icons-material/Today";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import EventIcon from "@mui/icons-material/Event";
import { useGlobalState } from "globalState/globalState";
import { fetchWeatherData } from "./weatherUtils";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import AirIcon from "@mui/icons-material/Air";
import { GiWindsock } from "react-icons/gi";
import WeatherConditionsCard from "./components/WeatherConditionsCard";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import SoftTypography from "components/SoftTypography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import App_logo from "assets/images/BMAx_orderSystem.png";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";



const containerStyle = {
  width: "100%",
  height: "300px",
  borderRadius: "12px",
  overflow: "hidden",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
};

const initialCenter = {
  lat: -3.745,
  lng: -38.523,
};



const SearchAddress = () => {
  const location = useLocation();
  const { state } = useGlobalState();
  const selectRef = useRef(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState(location.state?.address || "");
  const [center, setCenter] = useState(initialCenter);
  const [markerPosition, setMarkerPosition] = useState(initialCenter);
  const [showMap, setShowMap] = useState(location.state?.showMap ?? false);
  const [locationSelected, setLocationSelected] = useState(false);
  const [showDateTime, setShowDateTime] = useState(location.state?.showDateTime ?? false);
  const [weatherData, setWeatherData] = useState(location.state?.weatherData || []);
  const [isLoading, setIsLoading] = useState(false);
  const [dateErrors, setDateErrors] = useState({}); // Store errors for each index
  const [timeErrors, setTimeErrors] = useState({}); // Store errors for each index
  const [open, setOpen] = useState(false);



  // Get today's date
  const getNextAvailableDate = () => {
    let date = new Date();
    let day = date.getDay(); // 0 = Sunday, 6 = Saturday

    // If today is Saturday (6) → Move to Monday (8th - 6 = 2)
    // If today is Sunday (0) → Move to Monday (1st day)
    if (day === 6) {
        date.setDate(date.getDate() + 2); // Move to Monday
    } else if (day === 0) {
        date.setDate(date.getDate() + 1); // Move to Monday
    }

    return date.toISOString().split("T")[0]; // Format YYYY-MM-DD
  };
  
  const todayDate = getNextAvailableDate(); // Automatically set a valid date
  const [dateTimeFields, setDateTimeFields] = useState(
      location.state?.dateTimeFields || [{ date: todayDate, time: "" }]
  );

  const navigate = useNavigate();
  const { dispatch } = useGlobalState();
  const vendorPassword = process.env.REACT_APP_VENDOR_PASSWORD;

  console.log("dateTimeFields:", dateTimeFields); // Debugging
  console.log("weatherData:", weatherData); // Debugging

  const debouncedUpdateWeather = useCallback(
    debounce((lat, lng, dateTimeKey) => {
        updateWeatherData(lat, lng, dateTimeKey);
    }, 1000),
    []
  );

  const libraries = useMemo(() => ["places", "geometry", "drawing"], []);
  const loaderOptions = useMemo(
    () => ({
      googleMapsApiKey: "AIzaSyC-hVQKjNv0wcLN-e1F1rGrseh7KduRVdU",
      libraries,
    }),
    [libraries]
  );

  const { isLoaded } = useJsApiLoader(loaderOptions);
  useEffect(() => {
      if (isLoaded) {
        setIsLoading(false);  // Hide loader when Google Maps API is loaded
      }
    }, [isLoaded]);

  

  


  // Ensure when an address is selected, showMap is updated
 
  useEffect(() => {
    if (
      isLoaded && 
      address &&
      dateTimeFields.length > 0 &&
      dateTimeFields.every(field => field.date && field.time) 
    ) {
      console.log("Valid dateTimeFields:", dateTimeFields);

      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, async (results, status) => {
        if (status === "OK" && results[0]) {
          const location = results[0].geometry.location;
          const newLat = location.lat();
          const newLng = location.lng();

          setCenter({ lat: newLat, lng: newLng });
          setMarkerPosition({ lat: newLat, lng: newLng });
          setShowMap(true);
          setLocationSelected(true);

          // Ensure that weather is updated for each date-time field separately
          dateTimeFields.forEach(field => {
              if (field.date && field.time) {
                  const dateTimeKey = `${field.date}_${field.time}`;
                  debouncedUpdateWeather(newLat, newLng, dateTimeKey);
              }
          });
        }
      });
    }
  }, [isLoaded, address, dateTimeFields]);


  const updateWeatherData = async (lat, lng, dateTimeKey) => {
    if (!lat || !lng || !dateTimeKey) return; // Prevent unnecessary calls

    setIsLoading(true);
    try {
      const weatherTimeseries = await fetchWeatherData(lat, lng);
      console.log("Weather API Response:", weatherTimeseries);

      const timeInterval = [0, 6, 18]; // Define allowed time intervals

      // Find the field matching the dateTimeKey
      const fieldToUpdate = dateTimeFields.find(field => `${field.date}_${field.time}` === dateTimeKey);
      if (!fieldToUpdate) {
          console.warn("No matching field found for dateTimeKey:", dateTimeKey);
          setIsLoading(false);
          return;
      }

      // Correctly determine the closest valid interval
      const timeFormat = getCloseInterval(fieldToUpdate.time, timeInterval);
      
      // Ensure expected format: YYYY-MM-DDTHH:00:00Z
      const selectedDateTime = `${fieldToUpdate.date}T${timeFormat}`;
     
      // Find matching weather entry
      const weather = weatherTimeseries.find((item) => {
          return item.time.startsWith(selectedDateTime); // Handle potential seconds difference
      });

      if (!weather) {
          console.warn("No weather data found for:", selectedDateTime);
          setIsLoading(false);
          return;
      }

      // Construct updated weather data entry
      const updatedWeatherEntry = {
        date: fieldToUpdate.date,
        time: fieldToUpdate.time,
        weather: {
          temperature: weather.data.instant.details.air_temperature,
          air_pressure: weather.data.instant.details.air_pressure_at_sea_level,
          cloud_area_fraction: weather.data.instant.details.cloud_area_fraction,
          relative_humidity: weather.data.instant.details.relative_humidity,
          wind_direction: weather.data.instant.details.wind_from_direction,
          wind_speed: weather.data.instant.details.wind_speed,
          icon:
            weather.data.next_1_hours?.summary.symbol_code ||
            weather.data.next_6_hours?.summary.symbol_code ||
            weather.data.next_12_hours?.summary.symbol_code ||
            "default_icon",
        },
      };

      // Update weatherData state
      setWeatherData((prevWeatherData) => {
        const existingIndex = prevWeatherData.findIndex(
          (item) => item.date === fieldToUpdate.date && item.time === fieldToUpdate.time
        );

        if (existingIndex !== -1) {
          console.log("Updating existing weather entry...");
          const updatedWeatherData = [...prevWeatherData];
          updatedWeatherData[existingIndex] = updatedWeatherEntry;
          return updatedWeatherData;
        } else {
          console.log("Adding new weather entry...");
          return [...prevWeatherData, updatedWeatherEntry];
        }
      });

    } catch (error) {
        console.error("Error fetching or processing weather data:", error);
    } finally {
        setIsLoading(false);
    }
  };


  // ✅ FIXED FUNCTION TO FIND THE CLOSEST INTERVAL
  const getCloseInterval = function (time, intervalArray) {

    const hour = parseInt(time.split(":")[0], 10);
    
    let closestInterval = intervalArray[0];
    let minDifference = Math.abs(hour - closestInterval);

    for (let i = 1; i < intervalArray.length; i++) {
      let difference = Math.abs(hour - intervalArray[i]);
      if (difference < minDifference) {
        minDifference = difference;
        closestInterval = intervalArray[i];
      }
    }
    return closestInterval.toString().padStart(2, "0") + ":00:00Z";
  };


  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };


  const handleNextClick = () => {
    const step1Data = {
      address,
      dateTimeFields,
      weatherData,
      showMap,
      showDateTime,
      totalArea: location.state?.totalArea || 0, // Preserve data if exists
      personName: location.state?.personName || "",
      castWithDrop: location.state?.castWithDrop || false,
      delay: location.state?.delay || false,
      steelFiber: location.state?.steelFiber || false,
      accelerator: location.state?.accelerator || false,
      uploadingTypeChecked: location.state?.uploadingTypeChecked || false,
      selectedItem: location.state?.selectedItem || null,
      vehicleType: location.state?.vehicleType || "40",
      selectedProjectType: location.state?.selectedProjectType || "",
      showAdditionalOptions: location.state?.showAdditionalOptions || false,
      addAmountChecked: location.state?.addAmountChecked || false,
      pumpMixerWashChecked: location.state?.pumpMixerWashChecked || false,
      addHoseChecked: location.state?.addHoseChecked || false,
      hoseAmount: location.state?.hoseAmount || 0,
      concreteConfig: location.state?.concreteConfig || null,
      concreteConfigData: location.state?.concreteConfigData || null,
      displayValue: location.state?.displayValue || "",
      offerOnCasting: location.state?.offerOnCasting || false,
      offerOnFloorSanding: location.state?.offerOnFloorSanding || false,
      responsibility: location.state?.responsibility || "selfBuilder",
      acceptedTerms: location.state?.acceptedTerms || false,
      uploadedFiles: location.state?.uploadedFiles || [],
      fileIDs: location.state?.fileIDs || [],
      selectedFile: location.state?.selectedFile || null,
      vendorData: location.state?.vendorData || null,
      projectDescription: location.state?.projectDescription || "",
    };

    console.log("Navigating to area-calculate with:", step1Data);
    
    navigate("/area-calculate", { state: step1Data });
  }; 

  

  const handlePlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        const newLat = location.lat();
        const newLng = location.lng();

        setAddress(place.formatted_address);
        setCenter({ lat: newLat, lng: newLng });
        setMarkerPosition({ lat: newLat, lng: newLng });
        setShowMap(true);
        setLocationSelected(true);
      }
    }
  };

  const handleClear = () => {
    setAddress("");
    setCenter(initialCenter);
    setMarkerPosition(initialCenter);
    setShowMap(false);
    setLocationSelected(false);
    setShowDateTime(false);
    setDateTimeFields([{ date: "", time: "" }]);
    setWeatherData([]);
  };

  const timeOptions = Array.from({ length: 10 }, (_, i) => {
    const hour = (i + 6).toString().padStart(2, "0"); // Generates "06" to "15"
    return `${hour}:00`;
  });

  const handleInputClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(true);
  };

  const handleCheckboxChange = (event) => {
    setShowDateTime(event.target.checked);
  };

  const handleAddDateTimeField = () => {
    setDateTimeFields([...dateTimeFields, { date: todayDate, time: "" }]);
  };

  // const getCloseInterval = function (time, intervalArray) {
  //   let index = 0;
  //   console.log("getCloseInterval time:", time);
  //   console.log("getCloseInterval intervalArray:", intervalArray);
  //   const hour = +time.split(":")[0];

  //   console.log("h:", hour);

  //   //02:23 [0,6,18]                       0<2 && 6>2

  //   index = intervalArray.find((interval, index, array) => {
  //     console.log("last:", array.length === index + 1 ? 24 : index + 1);

  //     if (array.length === index + 1) {
  //       //last index
  //       return index;
  //     }

  //     return interval <= hour && array[index + 1] > hour;
  //   });

  //   return index.toString().padStart(2, "0") + ":00:00Z";
  // };

  const handleDateTimeChange = async (index, field, value) => {
    console.log("handleDateTimeChange:", index, field, value, [...dateTimeFields]);
    const newDateTimeFields = [...dateTimeFields]; // Copy old datetimeFields
    newDateTimeFields[index][field] = value; // Push date or time
    setDateTimeFields(newDateTimeFields); // Update state

    if (field === "date") {
        handleDateValidation(value, index);
    }
    if (field === "time") {
        handleTimeValidation(value, index);
    }

    if (newDateTimeFields[index].date && newDateTimeFields[index].time) {
        const dateTimeKey = `${newDateTimeFields[index].date}_${newDateTimeFields[index].time}`;
        updateWeatherData(center.lat, center.lng, dateTimeKey);
    }
  };



  

  const handleDateValidation = (selectedDate, index) => {
    if (!selectedDate) return;

    const selectedDay = new Date(selectedDate).getDay(); // 0 = Sunday, 6 = Saturday
    let errorMessage = "";

    if (selectedDate < currentDate || selectedDate > formattedMaxDate) {
        errorMessage = `Vennligst velg en dato mellom ${currentDate} og ${formattedMaxDate}.`;
    }

    if (selectedDay === 0 || selectedDay === 6) {
        errorMessage = "Helger (lørdag og søndag) er ikke tillatt.";
    }

    // Store the error for the specific index
    setDateErrors((prevErrors) => ({ ...prevErrors, [index]: errorMessage }));

    // If there's an error, clear the time field
    const newDateTimeFields = [...dateTimeFields];
    if (errorMessage) {
        newDateTimeFields[index].time = "";
    }
    setDateTimeFields(newDateTimeFields);
  };

  const handleTimeValidation = (selectedTime, index) => {
    if (!selectedTime) return;

    const [hour, minutes] = selectedTime.split(":").map(Number);
    let errorMessage = "";

    // Get current date and time
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinutes = now.getMinutes();

    // Get selected date from dateTimeFields
    const selectedDate = dateTimeFields[index]?.date;

    // Convert current time to "HH:MM" format for comparison
    const currentTimeString = `${String(currentHour).padStart(2, "0")}:${String(currentMinutes).padStart(2, "0")}`;

    // Check if the selected time is within the allowed range (06:00 - 15:00)
    if (hour < 6 || hour > 15) {
        errorMessage = "Tid må være mellom 06:00 og 15:00";
    }

    // Check if the minutes are NOT exactly 00 (must be on the hour)
    if (minutes !== 0) {
        errorMessage = "Tid må være i hele timer (f.eks. 08:00, 09:00, osv.).";
    }

    // Check if the selected date is today and the selected time is in the past
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
    if (selectedDate === today) {
        const selectedTimeString = `${String(hour).padStart(2, "0")}:00`;
        if (selectedTimeString <= currentTimeString) {
            errorMessage = "Du kan ikke velge en tidligere tid for i dag.";
        }
    }

    // Update state with error message
    setTimeErrors((prevErrors) => ({ ...prevErrors, [index]: errorMessage }));

    // If there's an error, clear the selected time
    if (errorMessage) {
        const newDateTimeFields = [...dateTimeFields];
        newDateTimeFields[index].time = "";
        setDateTimeFields(newDateTimeFields);
    }
};


  const handleClearDateTimeField = (index) => {
    const newDateTimeFields = dateTimeFields.filter((_, i) => i !== index);
    setDateTimeFields(newDateTimeFields);
    const newWeatherData = weatherData.filter((_, i) => i !== index);
    setWeatherData(newWeatherData);
  };

  const onMapLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds(initialCenter);
    map.fitBounds(bounds);
  }, []);

  useEffect(() => {
    if (autocomplete && address) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK" && results[0]) {
          const location = results[0].geometry.location;
          setCenter({ lat: location.lat(), lng: location.lng() });
          setMarkerPosition({ lat: location.lat(), lng: location.lng() });
          setShowMap(true);
          setLocationSelected(true);
        }
      });
    }
  }, [autocomplete, address]);

  const handleBoxClick = () => {
    handleCheckboxChange({ target: { name: "addPumpWaste", checked: !showDateTime } });
  };

  const handleCheckboxClick = (event) => {
    event.stopPropagation();
    handleCheckboxChange(event);
  };

  

 const extraContent = (
    <Box
      sx={{
        // backgroundColor: "#006bb7",
        padding: "20px",
        marginTop: "90px",
      }}
    >
    
      
      <Grid container direction="column" gap={2}>
        <Typography color={"#fff"} variant="body2">
          <span style={{ fontWeight: "900", fontSize: "20px" }}>Steg 1</span> Velg adresse
        </Typography>

        <Box
          p={2}
          sx={{
            border: "1px solid #fff",
            width: "100%%",
            // backgroundColor: "#0b97ef",
            borderRadius: "10px",
          }}
        >
          <Typography color="#fff" variant="body2">
            <span style={{ fontWeight: "800" }}>Lever til:</span> {address}
          </Typography>
          {weatherData &&
            dateTimeFields &&
            dateTimeFields.map((field, index) => {
              if (!field.date || !field.time) {
                return null;
              }

              const weatherForDate = weatherData[index]?.weather || {};
             

              if (!weatherForDate || Object.keys(weatherForDate).length === 0) {
                return (
                  <Typography key={index} color="#fff" variant="body2" mt={2}>
                    Værdata er ikke tilgjengelig for {field.date}, {field.time}
                  </Typography>
                );
              }

              const customDateFormat = `${field.date.split("-")[2]}.${field.date.split("-")[1]}.${field.date.split("-")[0]}`;
              
 
              return (
                <>
                  <WeatherConditionsCard
                    index={index}
                    date={customDateFormat}
                    time={field.time}
                    weatherForDate={weatherForDate}
                  />
                </>
              );
            })}
        </Box>
      </Grid>
      

    </Box>
  );


  // Get the current date
  const currentDate = new Date().toISOString().split("T")[0];

  // Calculate the max date (6 month from the current date)
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 180);
  const formattedMaxDate = maxDate.toISOString().split("T")[0];

  return isLoaded ? (
      
    <DefaultLayout extraContent={extraContent}>
      <Grid container >
        <Grid item xs={12} md={11} xl={11} mx="auto" mt={4} mb={1}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: locationSelected ? "flex-start" : "center",
              flexDirection: "column",
              width: "100%",
            }}
            mb={3}
          >
          {isLoading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <CircularProgress size={60} color="info" />
            </Box>
          )}
            <Grid container justifyContent="center">
              
              
              <Grid container justifyContent="center">
                <Grid item xs={12} mt={1}>
                  <SoftBox textAlign="center">
                    <SoftTypography variant="h2" sx={{ fontWeight: 'bolder', lineHeight: '1.2', color: '#006bb7' }}>
                      HVOR
                    </SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12}>
                  <SoftBox textAlign="center">
                    <SoftTypography variant="h2" sx={{ fontWeight: 'bolder', lineHeight: '1.2', color: '#000', display: 'inline' }}>
                      skal betong leveres
                    </SoftTypography>
                    <SoftTypography variant="h2" sx={{ fontWeight: 'bolder', lineHeight: '1.2', color: '#006bb7', display: 'inline' }}>
                      ?
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={3}>
                <Autocomplete onLoad={handleLoad} onPlaceChanged={handlePlaceChanged}>
                  <SoftInput
                    placeholder="Søk etter sted.."
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    size="large"
                    icon={{
                      component: !address ? (
                        "search"
                      ) : (
                        <Box sx={{ cursor: "pointer" }}>
                          {address && <ClearIcon onClick={() => setAddress("")} />}
                        </Box>
                      ),
                      direction: "right",
                    }}
                    sx={{ width: "100%" }}
                  />
                </Autocomplete>
              </Grid>
            </Grid>
          </Box>

          {showMap && (
            <Box mt={3}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={18}
                onLoad={onMapLoad}
              >
                <Marker position={markerPosition} />
              </GoogleMap>
              <SoftBox py={2} sx={{ color: "info.main" }} mb={-2}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    border: "1px solid #dee0e2",
                    padding: "8px",
                    borderRadius: "4px",
                    gap: "8px",
                    paddingLeft: 1,
                    "&:hover": {
                      border: "1px solid #006bb7",
                    },
                  }}
                  className="calculate_box"
                  onClick={handleBoxClick}
                >
                  <Avatar sx={{ color: "#fff", bgcolor: "#006bb7", width: "30px", height: "30px" }}>
                    <EventIcon />
                  </Avatar>
                  <Typography
                    fontSize="1rem"
                    sx={{ textTransform: "capitalize", color: "#000" }}
                    variant="h5"
                  >
                    Velg dato og tid
                  </Typography>
                  <FormControlLabel
                    sx={{ marginLeft: "auto" }}
                    control={
                      <Checkbox
                        checked={showDateTime}
                        onChange={handleCheckboxClick}
                        name="addPumpWaste"
                        sx={{
                          "&:hover": {
                            border: "1px solid black",
                          },
                          "&:before": {
                            content: '""',
                            display: "inline-block",
                            width: "16px",
                            height: "16px",
                            border: "1px solid #dee0e2",
                            borderRadius: "4px",
                            backgroundColor: "#fff",
                            marginRight: "8px",
                            WebkitAppearance: "none", // WebKit-specific styling
                            MozAppearance: "none", // Firefox-specific styling
                            appearance: "none", // General styling
                          },
                          "&:checked:before": {
                            borderColor: "#21B4FD",
                          },
                        }}
                      />
                    }
                  />
                </Box>
              </SoftBox>

              {showDateTime && (
                <Box>
                  {dateTimeFields.map((field, index) => {
                  console.log('field', field);
                    const customDateFormat = `${field.date.split("-")[2]}.${field.date.split("-")[1]}.${field.date.split("-")[0]}`;

                    console.log('customDateFormat', customDateFormat);

                    return (
                      <>
                        <Grid container spacing={2} alignItems={"center"} key={index} mt={1}>
                          <Grid item xs={12} key={index}>
                            <Grid container spacing={2} key={index}>
                              <Grid item xs={12} sm={6} md={6} key={index}>
                                <SoftInput
                                   
                                  label="Velg Dato"
                                  size="large"
                                  type="date"
                                  value={field.date}
                                  onChange={(e) => handleDateTimeChange(index, "date", e.target.value)}
                                  InputLabelProps={{ shrink: true }}
                                  icon={{
                                      component: (
                                          <Box sx={{ cursor: "pointer" }}>
                                              <TodayIcon sx={{ color: "#006bb7" }} />
                                          </Box>
                                      ),
                                      direction: "left",
                                  }}
                                  inputProps={{
                                      min: currentDate,
                                      max: formattedMaxDate,
                                  }}
                                  error={!!dateErrors[index]} // Highlight field red if there's an error
                                />
                                {/* Show error message directly below the date input */}
                                {dateErrors[index] && (
                                  <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
                                    {dateErrors[index]}
                                  </Typography>
                                )}

                              </Grid>
                              {/*
                              <Grid item xs={12} sm={6} md={5} key={index}>
                                <SoftInput
                                  label="Velg Tid"
                                  type="time"
                                  size="large"
                                  value={field.time}
                                  onChange={(e) => handleDateTimeChange(index, "time", e.target.value)}
                                  InputLabelProps={{ shrink: true }}
                                  icon={{
                                    component: (
                                      <Box sx={{ cursor: "pointer" }}>
                                        <AccessTimeFilledIcon sx={{ color: "#006bb7" }} />
                                      </Box>
                                    ),
                                    direction: "left",
                                  }}
                                  inputProps={{
                                    min: "06:00", // Use current time if today, otherwise start from 06:00
                                    max: "15:00",
                                    step: 3600, // Enforce selection every hour
                                    pattern: "[0-9]{2}:[0-9]{2}", // Enforces 24-hour format
                                    disabled: field.date === "" || [0, 6].includes(new Date(field.date).getDay()), // Disable if weekend
                                  }}
                                  error={!!timeErrors[index]}
                                />
                                {timeErrors[index] && (
                                  <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
                                    {timeErrors[index]}
                                  </Typography>
                                )}
                                
                              </Grid>
                              */} 

                              <Grid item xs={12} sm={6} md={5} key={index}>
                                <Select
                                  value={field.time}
                                  onChange={(e) => handleDateTimeChange(index, "time", e.target.value)}
                                  displayEmpty
                                  input={<OutlinedInput />}
                                  ref={selectRef}
                                  open={open}
                                  onOpen={() => {
                                    if (!field.date || [0, 6].includes(new Date(field.date).getDay())) return; // Prevent opening if date is invalid
                                    setOpen(true);
                                  }}
                                  onClose={() => setOpen(false)}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (!field.date || [0, 6].includes(new Date(field.date).getDay())) return; // Prevent click toggle
                                    setOpen(!open);
                                  }}
                                  disabled={!field.date || [0, 6].includes(new Date(field.date).getDay())} // Fully disable Select
                                  sx={{
                                    width: "100%",
                                    cursor: "pointer",
                                    borderRadius: "6px",
                                    bgcolor: "#fff",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    "& .MuiSelect-select": {
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "12px 16px",
                                      fontSize: "16px",
                                    },
                                  }}
                                  renderValue={(selected) =>
                                    selected ? (
                                        <Typography sx={{ color: "#495057", fontSize: "16px", display: "flex", alignItems: "center", padding: "2px 0px" }}>
                                          {selected}
                                        </Typography>
                                      
                                    ) : (
                                      <Typography sx={{ color: dateErrors[index] ? "#495057" : "#495057", fontSize: "16px", display: "flex", alignItems: "center", padding: "2px 0px" }}>
                                        Velg Tid
                                      </Typography>
                                    )
                                  }
                                  IconComponent={() => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        position: "absolute",
                                        right: 10,
                                        width: 28,
                                        height: 28,
                                        fontSize: "21px",
                                      }}
                                    >
                                      <AccessTimeFilledIcon
                                        sx={{ color: dateErrors[index] ? "#9E9E9E" : "#006bb7", cursor: field.date && ![0, 6].includes(new Date(field.date).getDay()) ? "pointer" : "not-allowed" }}
                                        onClick={() => {
                                          if (!field.date || [0, 6].includes(new Date(field.date).getDay())) return;
                                          setOpen(!open);
                                        }}
                                      />
                                    </Box>
                                  )}
                                >
                                  {timeOptions.map((time) => (
                                    <MenuItem
                                      key={time}
                                      value={time}
                                      sx={{
                                        fontSize: "16px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                      }}
                                    >
                                      {time}
                                    </MenuItem>
                                  ))}
                                </Select>


                                {timeErrors[index] && (
                                  <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
                                    {timeErrors[index]}
                                  </Typography>
                                )}
                              </Grid>



                              <Grid item xs={12} sm={12} md={1}
                                sx={{
                                  display: "inline-block",
                                  marginLeft: "-8px",
                                  marginTop: "1px",
                                  padding: 0,
                                }}
                                key={index}
                              >
                                {index === 0 ? (
                                  <SoftButton
                                    variant="gradient"
                                    size="small"
                                    onClick={handleAddDateTimeField}
                                    color="info"
                                    sx={{
                                      gap: "3px",
                                      minWidth: 50,
                                      padding: "16px",
                                    }}
                                  >
                                    <AddIcon fontSize="small" />
                                  </SoftButton>
                                ) : (
                                  <SoftButton
                                    variant="gradient"
                                    size="small"
                                    color="info"
                                    onClick={() => handleClearDateTimeField(index)}
                                    sx={{
                                      gap: "3px",
                                      minWidth: 50,
                                      padding: "16px",
                                    }}
                                  >
                                    <RemoveIcon fontSize="small" />
                                  </SoftButton>
                                )}
                              </Grid>
                              
                            </Grid>
                          </Grid>
                          {/* {weatherData[index] && (
                          <Grid item xs={12}>
                            <Typography
                              key={index}
                              variant="body2"
                              fontSize={".9rem"}
                              sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                            >
                              {weatherData[index]?.weather?.icon && (
                                <Avatar
                                  src={`https://api.met.no/images/weathericons/svg/${weatherData[index].weather.icon}.svg`}
                                  alt="Weather Icon"
                                  sx={{ width: 24, height: 24 }}
                                />
                              )}
                              <span>Temperature: {weatherData[index].weather.temperature}°C</span>
                              <span style={{ marginLeft: "10px" }}>
                                Air Pressure: {weatherData[index].weather.air_pressure} hPa
                              </span>
                              <span style={{ marginLeft: "10px" }}>
                                Cloud Area Fraction:{" "}
                                {weatherData[index].weather.cloud_area_fraction}%
                              </span>
                              <span style={{ marginLeft: "10px" }}>
                                Relative Humidity: {weatherData[index].weather.relative_humidity}%
                              </span>
                              <span style={{ marginLeft: "10px" }}>
                                Wind Direction: {weatherData[index].weather.wind_direction}°
                              </span>
                              <span style={{ marginLeft: "10px" }}>
                                Wind Speed: {weatherData[index].weather.wind_speed} m/s
                              </span>
                            </Typography>
                          </Grid>
                          //    <WeatherConditionsCard
                          //    index={index}
                          //    date={customDateFormat}
                          //    time={field.selectedDateTime}
                          //    weatherForDate={weatherData[index].weather}
                          //  />
                        )} */}
                        </Grid>
                        {weatherData[index] && (
                          <WeatherConditionsCard
                            key={index}
                            index={index}
                            date={customDateFormat}
                            time={field.time}
                            weatherForDate={weatherData[index].weather}

                          />
                        )}
                      </>
                    );
                  })}
                </Box>
              )}
              <Grid container justifyContent={"flex-end"}>
                <Grid item xs={12} sm={4} md={3} py={4}>
                  <SoftButton variant="gradient" color="info" fullWidth onClick={handleNextClick} disabled={isLoading}>
                    {isLoading ? <CircularProgress size={24} color="secondary" /> : "Neste"}
                  </SoftButton>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    </DefaultLayout>
  ) : (
    
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress size={60} color="info" />
    </Box>
  );
 
};

export default SearchAddress;
