import React, { useState, useEffect, useRef } from "react";
import {
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
  Box,
  Badge,
  Popover,
  Avatar,
  Typography,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { Backdrop } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultLayout from "layouts/authentication/components/DashboardDefaultLayout/DefaultLayout";
import SoftBox from "components/SoftBox";
import { Calculate, Map as MapIcon, Edit } from "@mui/icons-material";
import Calculator from "./Calculator";
import Map from "./Map"; // Import your MapComponent
import SoftButton from "components/SoftButton";
import { useTheme } from "@mui/material/styles";
import CastIcon from "@mui/icons-material/Cast";
import SpeedIcon from "@mui/icons-material/Speed";
import ConstructionIcon from "@mui/icons-material/Construction";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoIcon from "@mui/icons-material/Info";
import CustomizedConcrete from "./Customizedconcretepopup";
import Trackcar from "./Trackcar";
import DirectTip from "./DirectTip";
import PumpMixer from "./PumpMixer";
import SlideCar from "./SlideCar"; // Import your popup components
import { useGlobalState } from "globalState/globalState";
import { fetchVehicleTypes, fetchTypeProject } from "./weatherUtils"; // Correct import statement
import { getCustomConcrete } from 'api/apiService';  // Import the API function
import WeatherConditionsCard from "./components/WeatherConditionsCard";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const nameTranslation = {
  Garasjegulv: "Garage floor",
  Kjellergulv: "Basement floor",
  Systemelementer: "System elements",
  Veggstøp: "Wall plaster",
  Terrasseplate: "Terrace board",
  Trapper: "Stairs",
  "Annen støp ute": "Another cast outside",
  "Annen støp inne": "Other cast inside",
  Støttemur: "Retaining wall",
};

const names = [
  "Garage floor",
  "Basement floor",
  "System elements",
  "Wall plaster",
  "Terrace board",
  "Stairs",
  "Another cast outside",
  "Other cast inside",
  "Retaining wall",
];

const getStyles = (name, selectedName, theme) => {
  return {
    fontWeight:
      selectedName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const vehicleTypeMapping = {
  trackcar: "40",      // 16m Track car
  directTip: "38",     // 1m Direct tip
  pumpMixer: "41",     // 24m Pump mixer
  slideCar: "39",      // 9m Slide car
};

// Main component
const CalculateArea = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { dispatch } = useGlobalState();
  const selectRef = useRef(null);

  // ✅ Extract values from `location.state` or set default values
  const {
    address = "",
    dateTimeFields = [],
    weatherData = [],
    showMap = false,
    totalArea: initialTotalArea = 0,
    personName: initialPersonName = "",
    castWithDrop: initialCastWithDrop = false,
    delay: initialDelay = false,
    steelFiber: initialSteelFiber = false,
    accelerator: initialAccelerator = false,
    uploadingTypeChecked: initialUploadingTypeChecked = false,
    selectedItem: initialSelectedItem = null,
    vehicleType: initialVehicleType = "40",
    selectedProjectType: initialSelectedProjectType = "",
    showAdditionalOptions: initialShowAdditionalOptions = false,
    addAmountChecked: initialAddAmountChecked = false,
    pumpMixerWashChecked: initialPumpMixerWashChecked = false,
    addHoseChecked: initialAddHoseChecked = false,
    hoseAmount: initialHoseAmount = "",
    concreteConfig: initialConcreteConfig = null,
    concreteConfigData: initialConcreteConfigData = null,
    displayValue: initialDisplayValue = "",
  } = location.state || {};

  // ✅ Set state with existing values
  const [showDateTime, setShowDateTime] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showMapPopup, setShowMapPopup] = useState(false);
  const [totalArea, setTotalArea] = useState(initialTotalArea);
  const [calculatorArea, setCalculatorArea] = useState(0);
  const [mapArea, setMapArea] = useState(0);
  const [personName, setPersonName] = useState(initialPersonName);
  const [castWithDrop, setCastWithDrop] = useState(initialCastWithDrop);
  const [delay, setDelay] = useState(initialDelay);
  const [steelFiber, setSteelFiber] = useState(initialSteelFiber);
  const [accelerator, setAccelerator] = useState(initialAccelerator);
  const [uploadingTypeChecked, setUploadingTypeChecked] = useState(initialUploadingTypeChecked);
  const [selectedItem, setSelectedItem] = useState(initialSelectedItem);
  const [vehicleType, setVehicleType] = useState(initialVehicleType);
  const [selectedProjectType, setSelectedProjectType] = useState(initialSelectedProjectType);
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(initialShowAdditionalOptions);
  const [addAmountChecked, setAddAmountChecked] = useState(initialAddAmountChecked);
  const [pumpMixerWashChecked, setPumpMixerWashChecked] = useState(initialPumpMixerWashChecked);
  const [addHoseChecked, setAddHoseChecked] = useState(initialAddHoseChecked);
  const [hoseAmount, setHoseAmount] = useState(initialHoseAmount);
  const [concreteConfig, setConcreteConfig] = useState(initialConcreteConfig);
  const [concreteConfigData, setConcreteConfigData] = useState(initialConcreteConfigData);
  const [displayValue, setDisplayValue] = useState(initialDisplayValue);

  const [projectTypes, setProjectTypes] = useState([]); // Store fetched project types
  const [showProPopup, setShowProPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(null);
  const [open, setOpen] = useState(false);


  useEffect(() => {
    console.log(`Address: ${address}`);
  }, [address]);

  console.log('vehicleType:', vehicleType);
  console.log('selectedProjectType:', selectedProjectType);
  console.log('personName:', personName);
  console.log('selectedItem:', selectedItem);
  console.log('pumpMixerWashChecked:', pumpMixerWashChecked);
  console.log('addAmountChecked:', addAmountChecked);
  console.log('addHoseChecked:', addHoseChecked);
  console.log('hoseAmount:', hoseAmount);
  const { concrete_quality, concrete_stone, concrete_reduction, concrete_consistency } = concreteConfigData || {
    concrete_quality: [],
    concrete_stone: [],
    concrete_reduction: [],
    concrete_consistency: []
  };
  console.log('concreteConfig:', concreteConfig);
  console.log('concreteConfigData:', concreteConfigData);
  console.log('concrete_quality:', concrete_quality);
  console.log('displayValue:', displayValue);
  console.log('totalArea:', totalArea);
  const handleCheckboxChange = (event) => {
    setShowDateTime(event.target.checked);
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleOpenMapPopup = () => {
    setShowMapPopup(true);
  };

  const handleMapAreaChange = (area) => {
    console.log('setMapArea',area);
    setDisplayValue('');
    setCalculatorArea(0);
    setTotalArea(0)
    setMapArea(area);
    
  };

  const handleCalculatorAreaChange = (area) => {
    console.log('setCalculatorArea',area);
    setDisplayValue('');
    setMapArea(0);
    setTotalArea(0)
    setCalculatorArea(area);
    
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setShowMapPopup(false);
  };

  const handleOpenSpecificPopup = (popupName) => {
    setOpenPopup(popupName);
  };

  const handleCloseSpecificPopup = () => {
    setOpenPopup(null);
  };

  useEffect(() => {
    const combinedTotalArea = (parseFloat(calculatorArea) + parseFloat(mapArea) + parseFloat(totalArea));
    updateDisplayValue(combinedTotalArea);
  }, [calculatorArea, mapArea]);

  const updateDisplayValue = (area) => {
    const formattedValue = area % 1 === 0 ? area.toString() : area.toLocaleString("nb-NO", { minimumFractionDigits: 1, maximumFractionDigits: 2 });
    setTotalArea(area || 0);
    if (area > 0) {
      setDisplayValue(formattedValue + 'm³');
    }else{
      setDisplayValue('');
    }
    
  };

  const handleChange = (event) => {
    setCalculatorArea(0);
    setMapArea(0);
    setTotalArea(0)
    const inputValue = event.target.value.replace('m³', '').replace(',', '.');
    if (!isNaN(inputValue) && inputValue.trim() !== '') {
      setDisplayValue(inputValue);
    } else {
      setDisplayValue('');
    }
  };

  const handleFocus = () => {
    // Remove 'm³' when focused
    const numericValue = parseFloat(displayValue);
    if (numericValue > 0) {
      setDisplayValue(numericValue.toString());
    }else{
      setDisplayValue('');
    }
  };

  const handleBlur = () => {
    // Add 'm³' when focus is lost
    updateDisplayValue(parseFloat(displayValue));
  };


  
  const handleBoxClick = () => {
    handleCheckboxChange({ target: { name: "addPumpWaste", checked: !showDateTime } });
  };

  const handleCheckboxClick = (event) => {
    event.stopPropagation();
    handleCheckboxChange(event);
  };

  

  useEffect(() => {
  const fetchProjectTypes = async () => {
    try {
      const response = await fetchTypeProject(); // Fetch project types
      console.log('fetchTypeProject', response);
      if (response?.results) {
        let modifyData = response.results;

        // Check if concreteConfig is defined
        if (concreteConfig && concreteConfig.length > 0) {
          // Find names from concreteConfigData using the IDs
          const qualityName = concreteConfigData.concrete_quality.find(q => q.id.toString() === concreteConfig[0].quality)?.name || '';
          const stoneName = concreteConfigData.concrete_stone.find(s => s.id.toString() === concreteConfig[0].stone)?.name || '';
          const reductionName = concreteConfigData.concrete_reduction.find(r => r.id.toString() === concreteConfig[0].reduction)?.name || '';
          const consistencyName = concreteConfigData.concrete_consistency.find(c => c.id.toString() === concreteConfig[0].consistency)?.name || '';

          // Create full_name using the found names
          const full_name = `${concreteConfig[0].name} - ${qualityName} - ${stoneName} - ${reductionName} - ${consistencyName}`;

          const foundIndex = modifyData.findIndex(pt => pt.id === '10');
          if (foundIndex !== -1) {
            // Update the existing entry
            modifyData[foundIndex] = {
              ...modifyData[foundIndex],
              name: concreteConfig[0].name,
              full_name: full_name
            };
          } else {
            // Add a new entry if not found
            modifyData.push({
              id: '10',
              name: concreteConfig[0].name,
              full_name: full_name
            });
          }

          setSelectedProjectType('10');
          setPersonName(full_name);
        }
        setProjectTypes(modifyData); // Update the state with the modified data
      }
    } catch (error) {
      console.error("Error fetching project types:", error);
    }
  };

  fetchProjectTypes();
}, [selectedProjectType, concreteConfig, concreteConfigData]); // Include dependencies to react to their changes


  useEffect(() => {
    const fetchCustomConcrete = async () => {
      try {
        const response = await getCustomConcrete(); // Make the API call
        if (response) {
          setConcreteConfigData(response); // Assuming 'data' holds the concrete config
        } else {
          throw new Error('Failed to fetch concrete configuration');
        }
      } catch (error) {
        console.error("Error fetchCustomConcrete:", error);
        setConcreteConfigData(null); // Handle error, maybe set to null or keep the old data
      }
    };

    fetchCustomConcrete();
  }, []);

  // Handle selection change
  const handleProjectTypeChange = (event) => {
    const selectedId = event.target.value; // Get the selected ID
    if (selectedId !== 10 ){
      setConcreteConfig(null);
    }
    setSelectedProjectType(selectedId);
    
    const matchedProject = projectTypes.find((type) => type.id === selectedId);

    // Update personName with the matched name
    setPersonName(matchedProject ? matchedProject.full_name : "");
    

  };

  const handleInputClick = () => {
    selectRef.current && selectRef.current.click();
  };

  const handleUploadingTypeCheckboxChange = () => {
    if (uploadingTypeChecked) {
      // If already checked, uncheck and reset all sub-options
      setUploadingTypeChecked(false);
      setCastWithDrop(false);
      setDelay(false);
      setSteelFiber(false);
      setAccelerator(false);
    } else {
      // If unchecked, check it
      setUploadingTypeChecked(true);
    }
  };

  const handleProClick = () => {
    setShowProPopup(true);
  };

  const handleCloseProPopup = () => {
    setShowProPopup(false);
  };

  const handleProSave = (data) => {
    console.log(data);
    setConcreteConfig(data);
    setShowProPopup(false);
  };

  

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (item === "pumpMixer") {
      setShowAdditionalOptions(true);
      setSelectedItem(item);  // Set the selected item
      setVehicleType(vehicleTypeMapping[item]);  // Update vehicle type based on selection
      setAddAmountChecked(true);
      setPumpMixerWashChecked(true);
    } else if (selectedItem === item) {
      setSelectedItem(null);  // Deselect if already selected
      setVehicleType("40");   // Default to 40 if no item selected
    } else {
      setShowAdditionalOptions(false);
      resetAdditionalOptions(); // Reset checkboxes and input when switching
      setSelectedItem(item);  // Set the selected item
      setVehicleType(vehicleTypeMapping[item]);  // Update vehicle type based on selection
    }
  };

  const resetAdditionalOptions = () => {
    setAddAmountChecked(false);
    setPumpMixerWashChecked(false);
    setAddHoseChecked(false);
    setHoseAmount("");
  };

  const handleInputClick2 = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(true);
  };

  const step1Data = {
    address,
    dateTimeFields,
    weatherData,
    showMap,
    showDateTime,
    totalArea,
    personName,
    castWithDrop,
    delay,
    steelFiber,
    accelerator,
    uploadingTypeChecked,
    selectedItem,
    vehicleType,
    selectedProjectType,
    showAdditionalOptions,
    addAmountChecked,
    pumpMixerWashChecked,
    addHoseChecked,
    hoseAmount,
    concreteConfig,
    concreteConfigData,
    displayValue,
    offerOnCasting: location.state?.offerOnCasting || false,
    offerOnFloorSanding: location.state?.offerOnFloorSanding || false,
    responsibility: location.state?.responsibility || "selfBuilder",
    acceptedTerms: location.state?.acceptedTerms || false,
    uploadedFiles: location.state?.uploadedFiles || [],
    fileIDs: location.state?.fileIDs || [],
    selectedFile: location.state?.selectedFile || null,
    vendorData: location.state?.vendorData || null,
    projectDescription: location.state?.projectDescription || "",
  };
  console.log('step2Data',step1Data);

  const handleNextClick = () => {
    console.log('step1Data',step1Data);
    navigate("/summary-info", { state: step1Data });
  };



  const handleBackClick = () => {
    navigate("/add-address", { state: step1Data });
  };




  const isDayTime = (time) => {
    const hour = parseInt(time.split(":")[0], 10);
    return hour >= 6 && hour < 18;
  };

  const extraContent = (
    <Box
      sx={{
        // height: "100%",
        // minHeight: "600px !important",
        // backgroundColor: "#006bb7",
        padding: "20px",
        marginTop: "90px",
      }}
    >
      <Grid container direction="column" gap={2}>
        <Typography color={"#fff"} variant="body2">
          <span style={{ fontWeight: "900", fontSize: "20px" }}>Steg 1</span> Velg adresse
        </Typography>
        <Box
          p={2}
          sx={{
            border: "1px solid #fff",
            width: "100%%",
            // backgroundColor: "#0b97ef",
            borderRadius: "10px",
          }}
        >
          <Typography color={"#fff"} variant="body2">
            <span style={{ fontWeight: "800" }}>Lever til:</span> {address}
          </Typography>
          {dateTimeFields &&
            dateTimeFields.map((field, index) => {
             const customDateFormat = `${field.date.split("-")[2]}.${field.date.split("-")[1]}.${field.date.split("-")[0]}`;
              return (
                <>
                  {field.date && field.time ? (
                    // <Box key={index} color="#fff" mt={2} p={2} bgcolor="#333" borderRadius={4}>
                    //   <Typography variant="body2">
                    //     On {field.date}, {field.time}
                    //   </Typography>
                    //   <Grid container spacing={2} alignItems="center">
                    //     <Grid item>
                    //       {weatherData[index]?.weather?.icon && (
                    //         <Avatar
                    //           src={`https://api.met.no/images/weathericons/svg/${weatherData[index].weather.icon}.svg`}
                    //           alt="Weather Icon"
                    //           sx={{ width: 24, height: 24 }}
                    //         />
                    //       )}
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Temperature: {weatherData[index]?.weather?.temperature}°C
                    //       </Typography>
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Air Pressure: {weatherData[index]?.weather?.air_pressure} hPa
                    //       </Typography>
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Cloud Area Fraction: {weatherData[index]?.weather?.cloud_area_fraction}%
                    //       </Typography>
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Relative Humidity: {weatherData[index]?.weather?.relative_humidity}%
                    //       </Typography>
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Wind Direction: {weatherData[index]?.weather?.wind_from_direction}°
                    //       </Typography>
                    //     </Grid>
                    //     <Grid item>
                    //       <Typography variant="body2">
                    //         Wind Speed: {weatherData[index]?.weather?.wind_speed} m/s
                    //       </Typography>
                    //     </Grid>
                    //   </Grid>
                    // </Box>
                    <WeatherConditionsCard
                      key={index}
                      index={index}
                      date={customDateFormat}
                      time={field.time}
                      weatherForDate={weatherData[index].weather}
                    />
                  ) : null}
                </>
              );
            })}
        </Box>
        <Typography color={"#fff"} variant="body2">
          <span style={{ fontWeight: "900", fontSize: "20px" }}>Steg 2</span> Leverings konfigurasjoner
        </Typography>
        <Box
          p={2}
          sx={{
            border: "1px solid #fff",
            width: "100%%",
            // backgroundColor: "#0b97ef",
            borderRadius: "10px",
          }}
        >
          <Typography color={"#fff"} variant="body2">
            <span style={{ fontWeight: "800" }}>Totalt Areal:</span> { displayValue }
          </Typography>
          <Typography color={"#fff"} variant="body2">
            <span style={{ fontWeight: "800" }}>Prosjekt type:</span> {personName}
          </Typography>
          <Typography color={"#fff"} variant="body2">
            <span style={{ fontWeight: "800" }}>Tilpasset betong:</span> {concreteConfig?.[0]?.name || "N/A"}
          </Typography>
          {concreteConfig?.[0] && (
            <Box ml={2}>
              <Typography color={"#fff"} variant="body2">
                <span style={{ fontWeight: "800" }}>Kvalitet:</span> {concrete_quality.find(q => q.id === parseInt(concreteConfig[0].quality))?.name || 'N/A'}
              </Typography>
              <Typography color={"#fff"} variant="body2">
                <span style={{ fontWeight: "800" }}>Stentype:</span> {concrete_stone.find(s => s.id === parseInt(concreteConfig[0].stone))?.name || 'N/A'}
              </Typography>
              <Typography color={"#fff"} variant="body2">
                <span style={{ fontWeight: "800" }}>Steinreduksjon:</span> {concrete_reduction.find(r => r.id === parseInt(concreteConfig[0].reduction))?.name || 'N/A'}
              </Typography>
              <Typography color={"#fff"} variant="body2">
                <span style={{ fontWeight: "800" }}>Konsistens:</span> {concrete_consistency.find(c => c.id === parseInt(concreteConfig[0].consistency))?.name || 'N/A'}
              </Typography>
              <Typography color={"#fff"} variant="body2">
                <span style={{ fontWeight: "800" }}>Ekstra valg:</span>
              </Typography>
              <Box ml={2}>
                {concreteConfig[0].lowCarbon && (
                  <Typography color={"#fff"} variant="body2">
                    <span style={{ fontWeight: "800" }}>Lavkarbon:</span> Yes
                  </Typography>
                )}
                {concreteConfig[0].curbstone && (
                  <Typography color={"#fff"} variant="body2">
                    <span style={{ fontWeight: "800" }}>Jordfuktig:</span> Yes
                  </Typography>
                )}
                {concreteConfig[0].frostProof && (
                  <Typography color={"#fff"} variant="body2">
                    <span style={{ fontWeight: "800" }}>Frostsikker:</span> Yes
                  </Typography>
                )}
                {concreteConfig[0].farming && (
                  <Typography color={"#fff"} variant="body2">
                    <span style={{ fontWeight: "800" }}>Husdyrhold:</span> Yes
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          {selectedItem === "trackcar" && (
            <Typography color={"#fff"} variant="body2">
              <span style={{ fontWeight: "800" }}>16m Båndbil:</span> Yes
            </Typography>
          )}
          {selectedItem === "directTip" && (
            <Typography color={"#fff"} variant="body2">
              <span style={{ fontWeight: "800" }}>1m Direkte tipp:</span> Yes
            </Typography>
          )}
          {selectedItem === "pumpMixer" && (
            <Box>
              <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>24m Pumpemixer:</span> Yes</Typography>
              {addAmountChecked && (
                <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>Pumpemikser lagt til: </span> Yes</Typography>
              )}
              {pumpMixerWashChecked && (
                <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>Pumpevask: </span> Yes</Typography>
              )}
              {addHoseChecked && (
                <Typography color={"#fff"} variant="body2"><span style={{ fontWeight: "800" }}>Slangelengde i meter: </span> {hoseAmount ? hoseAmount : "0"} meters</Typography>
              )}
            </Box>
          )}
          {selectedItem === "slideCar" && (
            <Typography color={"#fff"} variant="body2">
              <span style={{ fontWeight: "800" }}>9m Rennebil:</span> Yes
            </Typography>
          )}
          <Typography color={"#fff"} variant="body2">
            <span style={{ fontWeight: "800" }}>Velg lastetype:</span> {uploadingTypeChecked ? "Yes" : "No"}
          </Typography>
          {uploadingTypeChecked && (
            <> 
              {castWithDrop && (
                <Typography color={"#fff"} variant="body2">
                  <span style={{ fontWeight: "800" }}>Støp med fall:</span> Yes
                </Typography>
              )}
              {delay && (
                <Typography color={"#fff"} variant="body2">
                  <span style={{ fontWeight: "800" }}>Forsinkelse:</span> Yes
                </Typography>
              )}
              {steelFiber && (
                <Typography color={"#fff"} variant="body2">
                  <span style={{ fontWeight: "800" }}>Stålfiber:</span> Yes
                </Typography>
              )}
              {accelerator && (
                <Typography color={"#fff"} variant="body2">
                  <span style={{ fontWeight: "800" }}>Akselerator:</span> Yes
                </Typography>
              )}
            </>
          )}
        </Box>
      </Grid>
    </Box>
  );

  return (
    <DefaultLayout extraContent={extraContent}>
      <Grid item xs={12} md={11} xl={11} mx="auto" mt={5} mb={2}>
        <Grid item xs={12}>
          <SoftBox p={1} sx={{ color: "info.main" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{display: "flex", justifyItems: "start" }}>
                <Typography
                  variant="h3"
                  sx={{ display: "flex", justifyContent: "center", color: "#000000",fontWeight:"bold" }}
                >
                  <span style={{ color: "#006bb7", marginRight: "8px" }}>HVOR MYE </span> trenger du<span style={{ color: "#006bb7"}}>?</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <SoftBox sx={{ padding: "0px" }}>
                  <Box
                    sx={{
                      width: "100%",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      border: "1px solid #dee0e2",
                      padding: "8px",
                      borderRadius: "4px",
                      gap: "8px",
                      paddingLeft: 1,
                      "&:hover": {
                        border: "1px solid #006bb7",
                      },
                    }}
                    className="calculate_box"
                    onClick={handleBoxClick}
                  >
                    <Avatar
                      sx={{ color: "#fff", bgcolor: "#006bb7", width: "30px", height: "30px" }}
                    >
                      <Calculate />
                    </Avatar>
                    <Typography
                      fontSize="1rem"
                      sx={{ textTransform: "capitalize", color: "#000000" }}
                      variant="h5"
                    >
                      Beregn område
                    </Typography>
                    <FormControlLabel
                      sx={{ marginLeft: "auto" }}
                      control={
                        <Checkbox
                          checked={showDateTime}
                          onChange={handleCheckboxClick}
                          name="addPumpWaste"
                          sx={{
                            "&:hover": {
                              border: "1px solid #000000",
                            },
                            "&:before": {
                              content: '""',
                              display: "inline-block",
                              width: "16px",
                              height: "16px",
                              border: "1px solid #dee0e2",
                              borderRadius: "4px",
                              backgroundColor: "#fff",
                              marginRight: "8px",
                              WebkitAppearance: "none",
                              MozAppearance: "none",
                              appearance: "none",
                            },
                            "&:checked:before": {
                              borderColor: "#21B4FD",
                            },
                          }}
                        />
                      }
                    />
                  </Box>
                </SoftBox>
              </Grid>
            </Grid>

            {showDateTime && (
              <>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  

                  <Grid item xs={12} sm={10} md={8}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="0,0m³"
                      value={displayValue}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      InputProps={{
                        sx: {
                          height: "47px !important",
                          textAlign: "right",
                          color: totalArea ? "blue" : "grey",
                        },
                        endAdornment: <InputAdornment position="end"></InputAdornment>,
                      }}
                      className="customInput"
                    />

                  </Grid>



                  <Grid item xs={6} sm={1} md={2}>
                    <SoftButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleOpenPopup}
                      title="Calculator"
                      sx={{
                        width: "100%",
                        height: "45px",
                        borderRadius: "7px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "box-shadow 0.3s ease",
                        cursor: "pointer",
                      }}
                    >
                      <Badge
                        sx={{ color: "#fff" }}
                        badgeContent={<Calculate sx={{ width: "30px", height: "30px" }} />}
                      />
                    </SoftButton>
                  </Grid>

                  <Grid item xs={6} sm={1} md={2}>
                    <SoftButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      title="Map"
                      onClick={handleOpenMapPopup}
                      sx={{
                        width: "100%",
                        height: "45px",
                        borderRadius: "7px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "box-shadow 0.3s ease",
                        cursor: "pointer",
                      }}
                    >
                      <Badge
                        sx={{ color: "#fff" }}
                        badgeContent={<MapIcon sx={{ width: "30px", height: "30px" }} />}
                      />
                    </SoftButton>
                  </Grid>
                </Grid>
              </>
            )}
          </SoftBox>

          {/* Calculator Popup */}
          <Popover
            open={showPopup}
            onClose={handleClosePopup}
            anchorEl={document.body}
            anchorOrigin={{ vertical: "center", horizontal: "center" }}
            transformOrigin={{ vertical: "center", horizontal: "center" }}
            PaperProps={{
              sx: {
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                border: "none",
                outline: "none",
                width: {
                  xs: "100%", // full width on mobile
                  md: "650px", // fixed width on medium and larger
                },
                height: {
                  xs: "100vh", // full height on mobile
                  md: "auto", // auto height on medium and larger
                },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflowY: "auto",
              },
            }}
            BackdropProps={{
              sx: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflowY: "auto",
              }}
            >
              <Calculator onAreaChange={handleCalculatorAreaChange} onClose={handleClosePopup} />
            </Box>
          </Popover>

          {/* Map Popup */}
          <Popover
            open={showMapPopup}
            onClose={handleClosePopup}
            anchorEl={null}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            PaperProps={{
              sx: {
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                border: "none",
                outline: "none",
                width: "800px",
                height: "730px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                overflow: "hidden",
              },
            }}
            BackdropProps={{
              sx: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Map address={address} onAreaChange={handleMapAreaChange} onSave={handleClosePopup} />
          </Popover>

          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} sx={{ m: 1, display: "flex", justifyItems: "start", marginBottom: "-12px" }}>
              <Typography
                variant="h3"

                sx={{ display: "flex", justifyContent: "center", color: "#000000", fontWeight:"bold" }}
              >
                <span style={{ color: "#006bb7", marginRight: "8px"}}>HVA </span> skal du støpe <span style={{ color: "#006bb7"}}>?</span>
              </Typography>
            </Grid>
            <Grid item xs={9} md={10}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <Select
                  displayEmpty
                  value={selectedProjectType}
                  onChange={handleProjectTypeChange}
                  input={<OutlinedInput onClick={handleInputClick2} />}
                  sx={{
                    height: "45px !important",
                    cursor: "pointer",
                  }}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <Typography variant="body1" style={{ fontSize: "14px",fontWeight: 'bold' }}>
                          <em>Velg prosjekttype</em>
                        </Typography>
                      );
                    }
                    const selectedItem = projectTypes.find((type) => type.id === selected);
                    return (
                      <Typography variant="body1" style={{ fontSize: "14px",fontWeight: 'bold' }}>
                        {selectedItem ? selectedItem.full_name : ""}
                      </Typography>
                    );
                  }}
                  
                  inputProps={{ "aria-label": "Without label" }}
                  ref={selectRef}
                  open={open}
                  onClose={handleClose}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(!open);
                  }}
                >
                  <MenuItem disabled value="">
                    <Typography variant="body1" style={{ fontSize: "14px",fontWeight: 'bold' }}>
                      <em>Velg prosjekttype</em>
                    </Typography>
                  </MenuItem>
                  {projectTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                        <Typography variant="body1" style={{ fontSize: "14px",fontWeight: 'bold' }}>
                          {type.full_name}
                        </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3} md={2} mt={1}>
              <SoftButton
                variant="gradient"
                color="info"
                fullWidth
                sx={{
                  width: "100%",
                  height: "43px !important",
                  borderRadius: "7px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleProClick}
              >
                PROFF
              </SoftButton>
            </Grid>
            <Backdrop
              open={showProPopup}
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            />
            <Popover
              open={showProPopup}
              onClose={handleCloseProPopup}
              anchorEl={null}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              PaperProps={{
                sx: {
                  backgroundColor: "#ffffff",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                  border: "none",
                  outline: "none",
                  width: {
                    xs: "100%", // full width on mobile
                    md: "800px", // fixed width on medium and larger
                  },
                  height: {
                    xs: "100vh", // full height on mobile
                    md: "600px", // fixed height on medium and larger
                  },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
            >
              <CustomizedConcrete onAreaChange={handleProSave} onClose={handleCloseProPopup} data={concreteConfigData} />
            </Popover>

            <Grid container spacing={2} m={1}>
              <Grid item xs={12} mb={0} sx={{display: "flex", justifyItems: "start" }}>
                <SoftTypography
                  variant="h3"
                  sx={{ display: "flex", justifyContent: "center", color: "#000000", fontWeight:"bold"}}
                >
                  <span style={{ color: "#006bb7", marginRight: "8px" }}>HVORDAN</span> kan vi levere{" "}
                  <span style={{ color: "#006bb7"}}>?</span>
                </SoftTypography>
              </Grid>
              <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                <Box
                  sx={{
                    cursor: "pointer",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    border: "1px solid #dee0e2",
                    padding: "8px",
                    borderRadius: "4px",
                    gap: "8px",
                    paddingLeft: 1,
                    "&:hover": {
                      border: "1px solid #006bb7",
                    },
                  }}
                  className="calculate_box"
                  onClick={() => handleItemClick("trackcar")}
                >
                  <Typography
                    fontSize="1rem"
                    sx={{
                      textTransform: "capitalize",
                      color: "#000",
                      marginLeft: 1,
                      "&:hover": { cursor: "hand !important" },
                    }}
                    variant="h5"
                  >
                    16m Båndbil
                  </Typography>
                  <FormControlLabel
                    sx={{ marginLeft: "auto" }}
                    control={
                      <Checkbox
                        checked={selectedItem === "trackcar"}
                        onChange={() => handleItemClick("trackcar")}
                      />
                    }
                  />
                </Box>
                <Typography
                  sx={{ cursor: "pointer" }}
                  fontSize={"20px"}
                  fontWeight={800}
                  onClick={() => handleOpenSpecificPopup("trackcar")}
                >
                  ?
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                <Box
                  sx={{
                    cursor: "pointer",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    border: "1px solid #dee0e2",
                    padding: "8px",
                    borderRadius: "4px",
                    gap: "8px",
                    paddingLeft: 1,
                    "&:hover": {
                      border: "1px solid #006bb7",
                    },
                  }}
                  className="calculate_box"
                  onClick={() => handleItemClick("directTip")}
                >
                  <Typography
                    fontSize="1rem"
                    sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                    variant="h5"
                  >
                    1m Direkte tipp
                  </Typography>
                  <FormControlLabel
                    sx={{ marginLeft: "auto" }}
                    control={
                      <Checkbox
                        checked={selectedItem === "directTip"}
                        onChange={() => handleItemClick("directTip")}
                      />
                    }
                  />
                </Box>
                <Typography
                  sx={{ cursor: "pointer" }}
                  fontSize={"20px"}
                  fontWeight={800}
                  onClick={() => handleOpenSpecificPopup("directTip")}
                >
                  ?
                </Typography>
              </Grid> 
              <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                <Box
                  sx={{
                    cursor: "pointer",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    border: "1px solid #dee0e2",
                    padding: "8px",
                    borderRadius: "4px",
                    gap: "8px",
                    paddingLeft: 1,
                    "&:hover": {
                      border: "1px solid #006bb7",
                    },
                  }}
                  className="calculate_box"
                  onClick={() => handleItemClick("pumpMixer")}
                >
                  <Typography
                    fontSize="1rem"
                    sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                    variant="h5"
                  >
                    24m Pumpemikser
                  </Typography>
                  <FormControlLabel
                    sx={{ marginLeft: "auto" }}
                    control={
                      <Checkbox
                        checked={selectedItem === "pumpMixer"}
                        onChange={() => handleItemClick("pumpMixer")}
                      />
                    }
                  />
                </Box>
                <Typography
                  sx={{ cursor: "pointer" }}
                  fontSize={"20px"}
                  fontWeight={800}
                  onClick={() => handleOpenSpecificPopup("pumpMixer")}
                >
                  ?
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                <Box
                  sx={{
                    cursor: "pointer",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    border: "1px solid #dee0e2",
                    padding: "8px",
                    borderRadius: "4px",
                    gap: "8px",
                    paddingLeft: 1,
                    "&:hover": {
                      border: "1px solid #006bb7",
                    },
                  }}
                  className="calculate_box"
                  onClick={() => handleItemClick("slideCar")}
                >
                  <Typography
                    fontSize="1rem"
                    sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                    variant="h5"
                  >
                    9m Rennebil
                  </Typography>
                  <FormControlLabel
                    sx={{ marginLeft: "auto" }}
                    control={
                      <Checkbox
                        checked={selectedItem === "slideCar"}
                        onChange={() => handleItemClick("slideCar")}
                      />
                    }
                  />
                </Box>
                <Typography
                  sx={{ cursor: "pointer" }}
                  fontSize={"20px"}
                  fontWeight={800}
                  onClick={() => handleOpenSpecificPopup("slideCar")}
                >
                  ?
                </Typography>
              </Grid>

              {/* Show additional options if 24m Pump Mixer is selected */}
              {showAdditionalOptions && (
                <>

                <Grid item xs={12} sx={{display: "flex", justifyItems: "start" }}>
                  <SoftTypography
                    variant="h3"
                    sx={{ display: "flex", justifyContent: "center", color: "#000000", fontWeight:"bold" }}
                  >
                    <span style={{ color: "#006bb7", marginRight: "8px" }}>Ytterligere</span> informasjon nødvendig{" "}
                    <span style={{ color: "#006bb7"}}>?</span>
                  </SoftTypography>
                </Grid>

                <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                  <Box
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      border: "1px solid #dee0e2",
                      padding: "8px",
                      borderRadius: "4px",
                      gap: "8px",
                      paddingLeft: 1,
                      "&:hover": {
                        border: "1px solid #006bb7",
                      },
                    }}
                    className="calculate_box"
                    
                  >
                    <Typography
                      fontSize="1rem"
                      sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                      variant="h5"
                    >
                      pumpemikser (0,5m³)
                    </Typography>
                    <FormControlLabel
                      sx={{ marginLeft: "auto" }}
                      control={
                        <Checkbox
                          checked={addAmountChecked}
                          onChange={(e) => setAddAmountChecked(e.target.checked)}
                        />
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                  <Box
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      border: "1px solid #dee0e2",
                      padding: "8px",
                      borderRadius: "4px",
                      gap: "8px",
                      paddingLeft: 1,
                      "&:hover": {
                        border: "1px solid #006bb7",
                      },
                    }}
                    className="calculate_box"
                    
                  >
                    <Typography
                      fontSize="1rem"
                      sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                      variant="h5"
                    >
                      Pumpevask
                    </Typography>
                    <FormControlLabel
                      sx={{ marginLeft: "auto" }}
                      control={
                        <Checkbox
                          checked={pumpMixerWashChecked}
                        onChange={(e) => setPumpMixerWashChecked(e.target.checked)}
                        />
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                  <Box
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      border: "1px solid #dee0e2",
                      padding: "8px",
                      borderRadius: "4px",
                      gap: "8px",
                      paddingLeft: 1,
                      "&:hover": {
                        border: "1px solid #006bb7",
                      },
                    }}
                    className="calculate_box"
                    
                  >
                    <Typography
                      fontSize="1rem"
                      sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                      variant="h5"
                    >
                      Legg til slange
                    </Typography>
                    <FormControlLabel
                      sx={{ marginLeft: "auto" }}
                      control={
                        <Checkbox
                          checked={addHoseChecked}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setAddHoseChecked(isChecked);
                            if (!isChecked) {
                              setHoseAmount(""); // Reset hoseAmount when unchecked
                            }
                          }}
                        />
                      }
                    />
                  </Box>
                </Grid>

                  

                  {/* Show hose amount input if Add Hose is checked */}
                  {addHoseChecked && (

                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} gap={2}>
                      <Box
                        sx={{
                          cursor: "pointer",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#fff",
                          border: "1px solid #dee0e2",
                          padding: "6px",
                          borderRadius: "4px",
                          gap: "8px",
                          paddingLeft: 1,
                          "&:hover": {
                            border: "1px solid #006bb7",
                          },
                        }}
                        className="calculate_box"
                        
                      >
                        
                        <TextField
                          placeholder="Hvor mange meter?"
                          value={hoseAmount || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setHoseAmount(value < 1 ? 1 : value); // Ensuring the minimum value is 1
                          }}
                          fullWidth
                          className="customInput"
                          type="number"
                          inputProps={{ min: 1, required: addHoseChecked }} // Setting min value and required attribute
                        />
                      </Box>
                    </Grid>
                    
                  )}
                </>
              )}

            </Grid>

            <Backdrop
              open={Boolean(openPopup)}
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            />
            <Popover
              open={openPopup === "trackcar"}
              onClose={handleCloseSpecificPopup}
              anchorEl={null}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              PaperProps={{
                sx: {
                  backgroundColor: "#ffffff",
                  padding: "20px",
                  paddingTop: "0px",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                  border: "none",
                  outline: "none",
                  width: "700px",
                  height: "730px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
            >
              <Trackcar onClose={handleCloseSpecificPopup} />
            </Popover>
            <Popover
              open={openPopup === "directTip"}
              onClose={handleCloseSpecificPopup}
              anchorEl={null}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              PaperProps={{
                sx: {
                  backgroundColor: "#ffffff",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                  border: "none",
                  outline: "none",
                  width: "700px",
                  height: "680px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
            >
              <DirectTip onClose={handleCloseSpecificPopup} />
            </Popover>
            <Popover
              open={openPopup === "pumpMixer"}
              onClose={handleCloseSpecificPopup}
              anchorEl={null}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              PaperProps={{
                sx: {
                  backgroundColor: "#ffffff",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                  border: "none",
                  outline: "none",
                  width: "700px",
                  height: "100% !important",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
            >
              <PumpMixer onClose={handleCloseSpecificPopup} />
            </Popover>
            <Popover
              open={openPopup === "slideCar"}
              onClose={handleCloseSpecificPopup}
              anchorEl={null}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              PaperProps={{
                sx: {
                  backgroundColor: "#ffffff",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                  border: "none",
                  outline: "none",
                  width: "700px",
                  height: "750x",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
            >
              <SlideCar onClose={handleCloseSpecificPopup} />
            </Popover>

            
          </Grid>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} mb={0} sx={{ m: 1, display: "flex", justifyItems: "start" }}>
              <SoftTypography
                variant="h3"
                sx={{ display: "flex", justifyContent: "center", color: "#000000", fontWeight:"bold"}}
              >
                <span style={{ color: "#006bb7", marginRight: "8px" }}>Ytterligere</span> informasjon{" "}
                <span style={{ color: "#006bb7"}}>?</span>
              </SoftTypography>
            </Grid>
            <Grid item xs={12} md={6} mx={1}>
              <Box
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  border: "1px solid #dee0e2",
                  padding: "8px",
                  borderRadius: "4px",
                  gap: "8px",
                  paddingLeft: 1,
                  "&:hover": {
                    border: "1px solid #006bb7",
                  },
                }}
                className="calculate_box"
                onClick={() => handleUploadingTypeCheckboxChange()}
              >
                <Avatar sx={{ color: "#fff", bgcolor: "#006bb7", width: "30px",height: "30px",}}>
                  <InfoIcon />
                </Avatar>
                <Typography
                  fontSize="1rem"
                  sx={{ textTransform: "capitalize", color: "#000" }}
                  variant="h5"
                >
                  tilleggsinformasjon
                </Typography>
                <FormControlLabel
                  sx={{ marginLeft: "auto" }}
                  control={
                    <Checkbox
                      checked={uploadingTypeChecked}
                      onChange={(e) => handleUploadingTypeCheckboxChange(e)}
                      name="uploadingType"
                      sx={{
                        "&:hover": {
                          border: "1px solid #006bb7",
                        },
                        "&:before": {
                          content: '""',
                          display: "inline-block",
                          width: "16px",
                          height: "16px",
                          border: "1px solid #dee0e2",
                          borderRadius: "4px",
                          backgroundColor: "#fff",
                          marginRight: "8px",
                          WebkitAppearance: "none",
                          MozAppearance: "none",
                          appearance: "none",
                        },
                        "&:checked:before": {
                          borderColor: "#21B4FD",
                        },
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
            {uploadingTypeChecked && (
              <>
                <Grid container spacing={2} m={1}>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        width: "100%",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        border: "1px solid #dee0e2",
                        padding: "8px",
                        borderRadius: "4px",
                        gap: "8px",
                        paddingLeft: 1,
                        "&:hover": {
                          border: "1px solid #006bb7",
                        },
                      }}
                      className="calculate_box"
                      onClick={() => setCastWithDrop(!castWithDrop)}
                    >
                      <Avatar sx={{ color: "#fff", bgcolor: "#006bb7", width: "30px",height: "30px",}}>
                        <CastIcon />
                      </Avatar>
                      <Typography
                        fontSize="1rem"
                        sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                        variant="h5"
                      >
                        Støp med fall
                      </Typography>
                      <FormControlLabel
                        sx={{ marginLeft: "auto" }}
                        control={
                          <Checkbox
                            checked={castWithDrop}
                            onChange={() => setCastWithDrop(!castWithDrop)}
                          />
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        cursor: "pointer",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        border: "1px solid #dee0e2",
                        padding: "8px",
                        borderRadius: "4px",
                        gap: "8px",
                        paddingLeft: 1,
                        "&:hover": {
                          border: "1px solid #006bb7",
                        },
                      }}
                      className="calculate_box"
                      onClick={() => setDelay(!delay)}
                    >
                      <Avatar sx={{ color: "#fff", bgcolor: "#006bb7", width: "30px",height: "30px", }}>
                        <AccessTimeIcon />
                      </Avatar>
                      <Typography
                        fontSize="1rem"
                        sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                        variant="h5"
                      >
                        Forsinkelse
                      </Typography>
                      <FormControlLabel
                        sx={{ marginLeft: "auto" }}
                        control={<Checkbox checked={delay} onChange={() => setDelay(!delay)} />}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        cursor: "pointer",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        border: "1px solid #dee0e2",
                        padding: "8px",
                        borderRadius: "4px",
                        gap: "8px",
                        paddingLeft: 1,
                        "&:hover": {
                          border: "1px solid #006bb7",
                        },
                      }}
                      className="calculate_box"
                      onClick={() => setSteelFiber(!steelFiber)}
                    >
                      <Avatar sx={{ color: "#fff", bgcolor: "#006bb7", width: "30px",height: "30px",}}>
                        <ConstructionIcon />
                      </Avatar>
                      <Typography
                        fontSize="1rem"
                        sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                        variant="h5"
                      >
                        Stålfiber
                      </Typography>
                      <FormControlLabel
                        sx={{ marginLeft: "auto" }}
                        control={
                          <Checkbox checked={steelFiber} onChange={() => setSteelFiber(!steelFiber)} />
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        cursor: "pointer",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        border: "1px solid #dee0e2",
                        padding: "8px",
                        borderRadius: "4px",
                        gap: "8px",
                        paddingLeft: 1,
                        "&:hover": {
                          border: "1px solid #006bb7",
                        },
                      }}
                      className="calculate_box"
                      onClick={() => setAccelerator(!accelerator)}
                    >
                      <Avatar sx={{ color: "#fff", bgcolor: "#006bb7", width: "30px",height: "30px",}}>
                        <SpeedIcon />
                      </Avatar>
                      <Typography
                        fontSize="1rem"
                        sx={{ textTransform: "capitalize", color: "#000", marginLeft: 1 }}
                        variant="h5"
                      >
                        Akselerator
                      </Typography>
                      <FormControlLabel
                        sx={{ marginLeft: "auto" }}
                        control={
                          <Checkbox
                            checked={accelerator}
                            onChange={() => setAccelerator(!accelerator)}
                          />
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container spacing={2} sx={{ mt: 8, justifyContent: "flex-end" }}>
            <Box gap={5} sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
              <Grid item xs={6} md={6}>
                <SoftButton
                  variant="contain"
                  color="info"
                  sx={{
                    height: "45px !important",
                    borderRadius: "7px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#006bb7",
                    border: "1px solid #006bb7",
                  }}
                  onClick={handleBackClick}
                >
                  Tilbake
                </SoftButton>
              </Grid>
              <Grid item xs={6} md={6}>
                <SoftButton
                  variant="gradient"
                  color="info"
                  sx={{
                    height: "45px !important",
                    borderRadius: "7px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={handleNextClick}
                >
                  Neste
                </SoftButton>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};

export default CalculateArea;
